import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as lang from "../../utils/strings";
import { LOGIN_QUERY } from "../../requests";
import { LinearProgress, useTheme, useMediaQuery } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100vh",
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	margin: {
		margin: theme.spacing(1),
	},
}));

const SignIn = (props) => {
	const { language, auth } = props;
	const classes = useStyles();
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [credentials, setCredentials] = useState({
		username: "",
		password: "",
	});

	const [
		login,
		{ loading: mutationLoading, error: mutationError },
	] = useMutation(LOGIN_QUERY);

	useEffect(() => {
		document.title = `Pronto - ${lang.SIGN_IN[language]}`;
	}, [language]);

	useEffect(() => {
		if (auth.isAuthenticated) auth.login();
	}, [auth]);

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid
				item
				xs={false}
				sm={4}
				md={7}
				// className={classes.lightImage}
			/>
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				component={Paper}
				elevation={6}
				square
				style={{ borderRadius: tiny ? "0px" : "25px 0px 0px 25px" }}
			>
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						{lang.SIGN_IN[language]}
					</Typography>
					<form
						className={classes.form}
						onSubmit={(event) => {
							event.preventDefault();
							login({
								variables: { data: credentials },
							})
								.then((response) => {
									localStorage.setItem(
										"accessTokenKey",
										response.data.login.token
									);
									auth.login();
									enqueueSnackbar(
										lang.LOGIN_SUCCESS[language],
										{
											variant: "success",
										}
									);
								})
								.catch((error) => {
									enqueueSnackbar(
										lang[
											error.message.split(":")[1].trim()
										][language],
										{
											variant: "error",
										}
									);
								});
						}}
					>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="username"
							label={lang.USERNAME[language]}
							value={credentials.username}
							onChange={(e) => {
								setCredentials({
									...credentials,
									[e.target.name]: e.target.value,
								});
							}}
							name="username"
							autoComplete="username"
							autoFocus
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label={lang.PASSWORD[language]}
							value={credentials.password}
							onChange={(e) => {
								setCredentials({
									...credentials,
									[e.target.name]: e.target.value,
								});
							}}
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<FormControlLabel
							control={
								<Checkbox value="remember" color="primary" />
							}
							label={"Remember me"}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
						>
							{lang.SIGN_IN[language]}
						</Button>
						{mutationLoading && (
							<LinearProgress color="secondary" />
						)}
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	);
};

export default SignIn;

const Copyright = () => {
	return (
		<React.Fragment>
			<Typography variant="body2" color="textPrimary" align="center">
				{"Copyright © "}
				<Link color="inherit" href="https://www.raisissoftware.ro/">
					Raisis Software
				</Link>{" "}
				{new Date().getFullYear()}
				{"."}
			</Typography>
			<Typography variant="body2" color="textSecondary" align="center">
				{"Powered by "}{" "}
				<Link color="inherit" href="https://www.raisissoftware.ro">
					Raisis Software
				</Link>
			</Typography>
		</React.Fragment>
	);
};
