import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, IconButton, Fab } from "@material-ui/core";
import { Add, NoteAdd, Description, DriveEta } from "@material-ui/icons";
import * as lang from "../../../../utils/strings";
import { CustomModal, CustomMenu } from "../../../../components";
import {
	CREATE_CUSTOMER_COMPANY_QUERY,
	DOCUMENT_CREATE_QUERY,
} from "../../../../requests";
import { useMutation } from "@apollo/react-hooks";
import { CustomerForm } from "../../../../Forms";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { CompanySearch } from "../../../../Forms/CustomerForm/components";
import { AddNewDoc } from "..";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
	},
	button: {
		margin: theme.spacing(1),
	},
	icon: {
		height: 32,
		width: 32,
	},
	iconLeft: {
		marginRight: theme.spacing(1),
	},
	difference: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "baseline",
		justifyItems: "flex-start",
	},
}));

const CustomerActions = (props) => {
	const {
		className,
		language,
		customerId,
		refetch,
		addModal,
		setAddModal,
		jobs,
		customer,
		refetchDocs,
	} = props;
	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		errors,
		setValue,
		getValues,
		reset,
		clearError,
		unregister,
	} = useForm({
		defaultValues: {
			customer: { gender: "MALE" },
		},
	});

	const [docModal, setDocModal] = useState(false);
	const [job, setJob] = useState(null);
	const [exists, setExists] = React.useState(false);

	const [createCustomer] = useMutation(CREATE_CUSTOMER_COMPANY_QUERY);

	const [createDocument] = useMutation(DOCUMENT_CREATE_QUERY);

	const handleCustomerCreateSubmit = (data) => {
		return {
			job: data.job,
			employee: {
				id: customerId,
			},
			company: {
				...data.company,
			},
		};
	};

	const constructData = (type) => {
		let registries = [];
		let fisaApt = null;
		let fisaAut = null;
		let fisaDM = null;
		let result = {
			type,
		};
		if (type === "FAPT") {
			registries.push({ type: "MED_MUN" });
			fisaApt = {
				name: job.company.name,
				phone: job.company.phone,
				job: job.job,
				address: job.company.address.addressString,
				firstName: customer.firstName,
				lastName: customer.lastName,
				cnp: customer.cnp,
			};
			result.registries = registries;
			result.fisaApt = { ...fisaApt };
		}
		if (type === "FAUT") {
			registries.push(
				{ type: "MED_INT" },
				{ type: "PNEUM" },
				{ type: "CHIR" },
				{ type: "NEURO" },
				{ type: "PSHT" },
				{ type: "ORL" },
				{ type: "OFT" }
			);
			fisaAut = {
				firstName: customer.firstName,
				lastName: customer.lastName,
				gender: customer.gender,
				birthDate: customer.birthDate,
			};
			result.registries = registries;
			result.fisaAut = { ...fisaAut };
		}
		return result;
	};

	useEffect(() => {
		reset({ customer: { gender: "MALE" } });
	}, [addModal, reset]);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<CustomModal
				language={language}
				title={lang.ADD_JOB[language]}
				open={addModal}
				onClose={() => setAddModal(false)}
				onExecute={(data) => {
					createCustomer({
						variables: { data: handleCustomerCreateSubmit(data) },
					})
						.then((response) => {
							enqueueSnackbar(
								lang.CUSTOMER_CREATE_SUCCESS[language],
								{
									variant: "success",
								}
							);
							refetch();
							setAddModal(false);
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					addModal && (
						<Grid container spacing={2}>
							<CompanySearch
								language={language}
								register={register}
								unregister={unregister}
								setValue={setValue}
								getValues={getValues}
								clearError={clearError}
								errors={errors}
								exists={exists}
								setExists={setExists}
								includeCompany
							/>
						</Grid>
					)
				}
				handleSubmit={handleSubmit}
				nonDismissible
				form
				fullWidth
			/>
			<CustomModal
				language={language}
				title={lang.ADD_DOCUMENT[language]}
				open={docModal !== false}
				onClose={() => setDocModal(false)}
				onExecute={() => {
					let jID = null;
					if (job) jID = job.id;
					createDocument({
						variables: {
							data: constructData(docModal),
							eID: customerId,
							jID,
						},
					})
						.then((response) => {
							enqueueSnackbar(lang.DOC_CREATE_SUCCESS[language], {
								variant: "success",
							});
							refetchDocs();
							setDocModal(false);
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					!!docModal && (
						<Grid container spacing={2}>
							<AddNewDoc
								type={docModal}
								language={language}
								jobs={jobs}
								job={job}
								handleJobChange={(e) => setJob(e.target.value)}
							/>
						</Grid>
					)
				}
				disableExecute={job === null && docModal !== "FAUT"}
				fullWidth
			/>

			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: "FIȘĂ DE APTITUDINE",
						icon: <Description className={classes.iconLeft} />,
						action: () => setDocModal("FAPT"),
						disabled: jobs?.length === 0,
					},
					{
						title: "FIȘĂ AUTO",
						icon: <DriveEta className={classes.iconLeft} />,
						action: () => setDocModal("FAUT"),
					},
					{
						title: "DOSAR MEDICAL",
						icon: <DriveEta className={classes.iconLeft} />,
						action: () => setDocModal("DMED"),
						disabled: jobs?.length === 0,
					},
				]}
			/>
			<div className={classes.difference}>
				{/* <Fab
					variant="round"
					size="small"
					color="secondary"
					className={classes.button}
					onClick={(e) => setAnchorEl(e.target)}
				>
					<NoteAdd />
				</Fab> */}
				<Button
					variant="contained"
					color="primary"
					className={classes.button}
					onClick={() => setAddModal(true)}
				>
					<Add className={classes.iconLeft} />
					{lang.ADD_JOB[language]}
				</Button>
			</div>
		</div>
	);
};

CustomerActions.propTypes = {
	className: PropTypes.string,
};

export default CustomerActions;
