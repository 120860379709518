import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { NEW_ENTRY, DOC_CREATE_SUCCESS, SOMETHING_WRONG } from "../../../../utils/strings";
import { CustomModal } from "../../../../components";
import { DocTypeSelect } from "./components";
import { Add } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { DOCUMENT_CREATE_QUERY } from "../../../../requests";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
	},
	button: {
		margin: theme.spacing(1),
	},
	content: {
		alignItems: "center",
		display: "flex",
	},
	title: {
		fontWeight: 700,
	},
	avatar: {
		backgroundColor: theme.palette.success.main,
		height: 56,
		width: 56,
	},
	icon: {
		height: 32,
		width: 32,
	},
	difference: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-start",
		justifyItems: "flex-start",
		// justifyContent: "space-between",
	},
	differenceIcon: {
		color: theme.palette.success.dark,
	},
	differenceValue: {
		color: theme.palette.success.dark,
		marginRight: theme.spacing(1),
	},
	iconLeft: {
		marginRight: theme.spacing(1),
	},
}));

const QuickAccess = (props) => {
	const { className, language, refetchDocs } = props;

	const classes = useStyles();
	const [docModal, setDocModal] = React.useState(false);
	const [docType, setDocType] = React.useState("DMED");
	const [docAux, setDocAux] = React.useState(1);
	const [exists, setExists] = React.useState(false);
	const [existsCompany, setExistsCompany] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const today = new Date(new Date().setFullYear(new Date().getFullYear())).toISOString().split("T")[0];

	const { register, handleSubmit, errors, setValue, getValues, reset, clearError, unregister } = useForm({
		defaultValues: {
			docType: "DMED",
			docDate: today,
			customer: {
				gender: "MALE",
				birthDate: today,
			},
		},
	});

	const getRegistries = (type, aux) => {
		switch (type) {
			case "DMED":
				return [
					// { type: "PSHL" },
					{ type: "PSHT" },
					{ type: "OFT" },
					{ type: "ORL" },
					// { type: "GINECO" },
					{ type: "NEURO" },
					// { type: "DERM" },
				];
			case "FAPT":
				return [{ type: "MED_MUN" }];
			case "FAUT":
				return aux === 1
					? [
							{ type: "MED_INT" },
							{ type: "CHIR" },
							{ type: "NEURO" },
							{ type: "PSHT" },
							{ type: "ORL" },
							{ type: "OFT" },
					  ]
					: [
							{ type: "MED_INT" },
							{ type: "PNEUM" },
							{ type: "CHIR" },
							{ type: "NEURO" },
							{ type: "PSHT" },
							{ type: "ORL" },
							{ type: "OFT" },
					  ];
			default:
				return [];
		}
	};
	const [createDocument] = useMutation(DOCUMENT_CREATE_QUERY);

	React.useEffect(() => {
		register({ name: "docType" }, { required: true });
	}, []);

	return (
		<div className={clsx(classes.root, className)}>
			<CustomModal
				fullWidth
				language={language}
				title={NEW_ENTRY[language]}
				open={docModal}
				onClose={() => setDocModal(false)}
				onExecute={(data) => {
					const { docType: type, docDate, docAux: aux, nextDate, customer: employee, company, job } = data;
					createDocument({
						variables: {
							type,
							aux,
							data: {
								employee,
								company,
								job,
								docs: {
									FAPT: { nextDate },
									registries: getRegistries(type, aux),
									createDate: docDate,
								},
							},
						},
					})
						.then((response) => {
							enqueueSnackbar(DOC_CREATE_SUCCESS[language], {
								variant: "success",
							});
							refetchDocs();
							setDocModal(false);
						})
						.catch((error) => {
							console.log(error);
							enqueueSnackbar(SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					docModal && (
						<DocTypeSelect
							today={today}
							language={language}
							docType={docType}
							setDocType={setDocType}
							docAux={docAux}
							setDocAux={setDocAux}
							exists={exists}
							setExists={setExists}
							existsCompany={existsCompany}
							setExistsCompany={setExistsCompany}
							register={register}
							errors={errors}
							setValue={setValue}
							unregister={unregister}
							clearError={clearError}
						/>
					)
				}
				handleSubmit={handleSubmit}
				form
			/>
			<div className={classes.difference}>
				<Button variant="contained" color="primary" className={classes.button} onClick={(e) => setDocModal(true)}>
					<Add className={classes.iconLeft} />
					{NEW_ENTRY[language]}
				</Button>
			</div>
		</div>
	);
};

QuickAccess.propTypes = {
	className: PropTypes.string,
};

export default QuickAccess;
