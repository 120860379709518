import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CssBaseline, Grow } from "@material-ui/core";
import { CompaniesTable } from "./components";
import { COMPANY_SEARCH_QUERY, CREATE_COMPANY_QUERY } from "../../requests";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import * as lang from "../../utils/strings";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { CustomModal } from "../../components";
import { CompanyForm } from "../../Forms";
import redirectTo from "../../utils/redirect";
import { exportData } from "../../utils/fetchFunctions";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
}));

const Companies = (props) => {
	const classes = useStyles();
	const { language, me, auth } = props;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [addModal, setAddModal] = useState(false);

	const { register, handleSubmit, errors, setValue, getValues, reset, unregister } = useForm();

	const [
		createCompany,
		{ loading: createCompanyLoading, networkStatus: createCompanyNetworkStatus, called: createCompanyCalled },
	] = useMutation(CREATE_COMPANY_QUERY);

	const [getCompanies, { data, loading, called, networkStatus }] = useLazyQuery(COMPANY_SEARCH_QUERY, {
		fetchPolicy: "no-cache",
	});

	const handleRowClick = (company) => redirectTo(`/company/${company.id}`);

	const handleExport = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("COMP")
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};

	useEffect(() => {
		document.title = `Pronto - ${lang.COMPANIES[language]}`;
	}, [language]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<CustomModal
				language={language}
				title={lang.ADD_COMPANY[language]}
				open={addModal}
				onClose={() => setAddModal(false)}
				onExecute={(data) => {
					createCompany({
						variables: { data: data.company },
					})
						.then((response) => {
							enqueueSnackbar(lang.COMPANY_CREATE_SUCCESS[language], {
								variant: "success",
							});
							setAddModal(false);
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					addModal && (
						<CompanyForm
							standalone
							language={language}
							register={register}
							unregister={unregister}
							setValue={setValue}
							getValues={getValues}
							errors={errors}
						/>
					)
				}
				handleSubmit={handleSubmit}
				nonDismissible
				form
				fullWidth
			/>
			<Grow in={true}>
				<CompaniesTable
					custom
					language={language}
					data={data?.companies?.companies !== undefined ? data.companies.companies : []}
					onOpen={() => setAddModal(true)}
					onExport={handleExport}
					loading={(loading && !networkStatus === 4) || (createCompanyLoading && !createCompanyNetworkStatus === 4)}
					called={createCompanyCalled}
					count={data?.companies?.count !== undefined ? data.companies.count : 0}
					onRowClick={handleRowClick}
					fetchData={(rows, page) =>
						getCompanies({
							variables: {
								where: {},
								pagination: {
									first: rows,
									skip: Math.max(page, 0) * rows,
								},
								orderBy: "name_ASC",
							},
						})
					}
					className={classes.display}
				/>
			</Grow>
		</div>
	);
};

export { Companies as default };
