import React, { useState, useEffect } from "react";
import { TextField, Grid, MenuItem, Typography, Hidden } from "@material-ui/core";
import * as lang from "../../utils/strings";
import getError from "../../utils/getError";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { REGIONS_QUERY, CITIES_QUERY } from "../../requests";
import { makeStyles } from "@material-ui/styles";
import VirtualizedSelect from "../../components/VirtualizedSelect/VirtualizedSelect";

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		// justifyContent: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const AddressForm = (props) => {
	const classes = useStyles();
	const defaultCountry = "România";
	const {
		language,
		register,
		unregister,
		setValue,
		existing,
		data,
		errors,
		edit,
		jobExists,
		companyExists,
		includeCountry,
	} = props;

	const [country, setCountry] = useState("România");
	const [region, setRegion] = useState(null);
	const [city, setCity] = useState(null);
	// const [region, setRegion] = useState(data?.address?.region ? data.address.region : null);
	// const [city, setCity] = useState(data?.address?.city ? data.address.region : null);

	const handleCountrySelect = (event, value, reason) => {
		setValue("company.address.country", value, true);
		setValue("company.address.region", "");
		setValue("company.address.city", "");
		setCountry(value);
		setRegion("");
		setCity("");
		console.log({
			country: value,
			region,
			city,
		});
	};

	const handleRegionSelect = (event, value, reason) => {
		setValue("company.address.region", value, true);
		setValue("company.address.city", "");
		setRegion(value);
		setCity("");
	};

	const handleCitySelect = (event, value, reason) => {
		setValue("company.address.city", value, true);
		setCity(value);
	};

	const { data: regions } = useQuery(REGIONS_QUERY, {
		variables: { query: null },
	});

	const [getCities, { data: cities, loading, called, networkStatus }] = useLazyQuery(CITIES_QUERY);

	useEffect(() => {
		if (includeCountry) register({ name: "company.address.country" }, { required: true });
		register({ name: "company.address.region" }, { required: true });
		register({ name: "company.address.city" }, { required: true });
	}, [register, existing, includeCountry]);

	useEffect(() => {
		if (data?.address) {
			console.log("HAS ADDRESS");
			console.log(data.address);
			setCountry(data.address.country);
			setRegion(data.address.region);
			setCity(data.address.city);
		}
	}, [data]);

	useEffect(() => {
		getCities({
			variables: {
				query: region,
			},
		});
	}, [region]);

	useEffect(() => {
		if (jobExists || companyExists) {
			unregister("company.address.region");
			unregister("company.address.city");
		}
	}, [jobExists, companyExists]);

	useEffect(() => {
		if (window.location.pathname.split("/")[2] === undefined) {
			getCities({
				variables: {
					query: region,
				},
			});
		}
	}, [region]);

	return (
		<React.Fragment>
			{includeCountry && (
				<>
					<Grid item xs={12} md={6}>
						<VirtualizedSelect
							freeSolo={true}
							language={language}
							options={[defaultCountry]}
							onChange={handleCountrySelect}
							value={country}
							textFieldProps={{
								required: true,
								name: "company.address.country",
								margin: edit ? "dense" : "none",
								error: !!errors?.company?.address?.country,
								helperText: !!errors?.company?.address?.country
									? getError(errors.company.address.country, language)
									: null,
								label: lang.COUNTRY[language],
							}}
						/>
					</Grid>
					<Hidden smDown>
						<Grid item md={6}></Grid>
					</Hidden>
				</>
			)}
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					freeSolo={includeCountry && country !== defaultCountry}
					language={language}
					options={
						(regions && !includeCountry) || (regions && includeCountry && country === defaultCountry)
							? regions.regions?.map((item) => item.region)
							: []
					}
					onChange={handleRegionSelect}
					value={region}
					textFieldProps={{
						required: true,
						name: "company.address.region",
						margin: edit ? "dense" : "none",
						error: !!errors?.company?.address?.region,
						helperText: !!errors?.company?.address?.region ? getError(errors.company.address.region, language) : null,
						label: lang.REGION[language],
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					freeSolo={includeCountry && country !== defaultCountry}
					disabled={(!region && !includeCountry) || (!region && includeCountry && country === defaultCountry)}
					language={language}
					options={
						(cities && !includeCountry) || (cities && includeCountry && country === defaultCountry)
							? cities.cities?.map((item) => item.city)
							: []
					}
					onChange={handleCitySelect}
					value={city}
					textFieldProps={{
						required: true,
						name: "company.address.city",
						margin: edit ? "dense" : "none",
						error: !!errors?.company?.address?.city,
						helperText: !!errors?.company?.address?.city ? getError(errors.company.address.city, language) : null,
						label: lang.CITY[language],
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.STREET[language]}
					name="company.address.street"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.STREET_NO[language]}
					name="company.address.streetNo"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.BUILDING[language]}
					name="company.address.building"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.ENTRANCE[language]}
					name="company.address.entrance"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.FLOOR[language]}
					name="company.address.floor"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.APARTMENT[language]}
					name="company.address.apartment"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.ZIPCODE[language]}
					name="company.address.zip"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			{/* </Grid> */}
		</React.Fragment>
	);
};

export { AddressForm as default };
