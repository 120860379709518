import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as lang from "../../../../utils/strings";

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const AddNewDocFAPT = (props) => {
	const classes = useStyles();
	const { job, handleJobChange, jobs, language } = props;

	return (
		<Grid item xs={12}>
			<TextField
				fullWidth
				required
				select
				label={lang.JOB[language]}
				name="job"
				onChange={handleJobChange}
				variant="outlined"
				value={job ? job : ""}
			>
				<li className={classes.listItem} value={null}>
					<em>niciunul</em>
				</li>
				{jobs.map((item, index) => (
					<li className={classes.listItem} key={index} value={item}>
						<Typography variant="body1">{`${item.job} @ ${item.company.name}`}</Typography>
					</li>
				))}
			</TextField>
		</Grid>
	);
};
const AddNewDocFAUT = (props) => {
	return (
		<Grid item xs={12}>
			<Typography variant="body1" gutterBottom>
				Ești sigur că vrei să generezi această fișă?
			</Typography>
		</Grid>
	);
};

const AddNewDoc = (props) => {
	const { type } = props;

	switch (type) {
		case "FAPT":
			return <AddNewDocFAPT {...props} />;
		case "FAUT":
			return <AddNewDocFAUT {...props} />;
		default:
			return null;
	}
};

export { AddNewDoc as default };
