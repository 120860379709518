import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { RegistriesMenu } from "..";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableTitle: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	headerButton: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableButtons: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const RegistriesTable = (props) => {
	const {
		className,
		data,
		loading,
		fetchData,
		count,
		called,
		language,
		onOpen,
		custom,
		onRowClick,
		filter,
		...rest
	} = props;
	const classes = useStyles();

	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0);
		setRowsPerPage(value);
	};

	useEffect(() => {
		if (filter) fetchData(rowsPerPage, 0);
	}, [called, filter, rowsPerPage]);

	return (
		<Card className={clsx(classes.root, className)} elevation={custom ? 1 : 10}>
			<CardContent className={classes.content}>
				<RegistriesMenu
					{...props}
					classes={classes}
					refetch={() => fetchData(rowsPerPage, 0)}
				/>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{loading ? (
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
									</TableRow>
								</TableHead>
							) : (
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>Nr.</TableCell>
										<TableCell>{lang.FULL_NAME[language]}</TableCell>
										<TableCell>{lang.COMPANY[language]}</TableCell>
										<TableCell>{lang.JOB[language]}</TableCell>
										<TableCell>{lang.RESULT[language]}</TableCell>
										<TableCell>{lang.CHECKUP_DATE[language]}</TableCell>
										<TableCell>{lang.CREATE_DATE[language]}</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.map((item) => (
										<TableRow onClick={() => onRowClick(item)} className={classes.tableRow} hover key={item.id}>
											<TableCell>{item.id}</TableCell>
											<TableCell>{item.regNo}</TableCell>
											<TableCell>
												<Typography variant="body1">
													{item.doc.employee.lastName} {item.doc.employee.firstName}
												</Typography>
											</TableCell>
											<TableCell>{item.doc.job?.company?.name ? item.doc.job.company.name : "-"}</TableCell>
											<TableCell>{item.doc.job?.job ? item.doc.job.job : "-"}</TableCell>
											<TableCell>{item.note}</TableCell>
											{/* <TableCell>
												{`${item.address?.street ? item.address.street : "-"} ${
													item.address?.streetNo ? item.address.streetNo : ""
												}`}
											</TableCell> */}
											<TableCell>{item.consultDate && moment(item.consultDate).format("DD/MM/YYYY")}</TableCell>
											<TableCell>{moment(item.createdAt).format("DD/MM/YYYY")}</TableCell>
										</TableRow>
									))}

									{rowsPerPage - data.length > 0 && (
										<TableRow
											style={{
												height: 53 * (rowsPerPage - data.length),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	return <TablePagination {...props} component="div" rowsPerPageOptions={[5, 10, 25]} />;
}

RegistriesTable.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default RegistriesTable;
