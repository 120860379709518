import React from "react";
import PropTypes from "prop-types";
import { Drawer, ListItem, List, ListItemText, ListItemIcon } from "@material-ui/core";

const CustomDrawer = (props) => {
	const { handleClose, anchorEl, menuList, tiny } = props;

	const fullList = (side) => (
		<div
			// className={classes.fullList}
			role="presentation"
			onKeyDown={handleClose}
		>
			<List>
				{menuList
					?.filter((item) => item.render !== false)
					.map((item, index) => (
						<ListItem
							key={index}
							button
							disabled={!!item.disabled}
							onClick={() => {
								item.action();
								handleClose();
							}}
						>
							<ListItemIcon>{item.icon}</ListItemIcon>
							<ListItemText
								primary={item.title}
								// secondary={e.secondary}
							/>
						</ListItem>
					))}
			</List>
		</div>
	);

	return (
		<div>
			<Drawer
				anchor="bottom"
				open={Boolean(anchorEl) && tiny}
				onClose={handleClose}
			>
				{fullList("bottom")}
			</Drawer>
		</div>
	);
};

CustomDrawer.propTypes = {
	handleClose: PropTypes.func.isRequired,
	menuList: PropTypes.array,
	anchorEl: PropTypes.object,
};

export default CustomDrawer;
