import { createMuiTheme } from "@material-ui/core";

import { paletteDark } from "./palette-dark";
import { paletteLight } from "./palette-light";
import typography from './typography';
import overrides from './overrides';

const getTheme = (mode) => {
    const palette = mode === "light" ? paletteLight : paletteDark;
	return createMuiTheme({
        palette,
        typography,
        overrides,
		shape: {
			borderRadius: 15,
		},
		zIndex: {
			appBar: 1200,
			drawer: 1100,
		},
	});
};

export default getTheme;
