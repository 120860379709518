import { gql } from "apollo-boost";

const users = gql`
	query SearchUsersQuery(
		$where: UserWhereInput
		$pagination: PaginationInput
	) {
		users(where: $where, pagination: $pagination, orderBy: createdAt_DESC) {
			count
			users(
				where: $where
				pagination: $pagination
				orderBy: createdAt_DESC
			) {
				id
				username
				avatar
				role
				active
				createdAt
				profile {
					lastName
					firstName
					email
				}
			}
		}
	}
`;
const usersCount = gql`
	query SearchUsersQuery(
		$where: UserWhereInput
		$pagination: PaginationInput
	) {
		users(where: $where, pagination: $pagination, orderBy: createdAt_DESC) {
			count
		}
	}
`;
const createUser = gql`
	mutation AddUserQuery($data: CreateUserInput!) {
		createUser(data: $data) {
			id
		}
	}
`;
const user = gql`
	query SearchUser($id: ID!) {
		user(id: $id) {
			id
			username
			password
			avatar
			role
			active
			profile {
				firstName
				lastName
				email
			}
			createdAt
		}
	}
`;
const updateUser = gql`
	mutation UpdateUser($id: ID!, $data: UpdateUserInput!) {
		updateUser(id: $id, data: $data) {
			id
			role
			profile {
				firstName
				lastName
				email
			}
		}
	}
`;
const userPasswordReset = gql`
	mutation ResetUserPassword($id: ID!) {
		userPasswordReset(id: $id) {
			id
		}
	}
`;
const userPasswordUpdate = gql`
	mutation UpdateUserPassword($data: UserPasswordUpdateInput!) {
		userPasswordUpdate(data: $data) {
			id
			password
		}
	}
`;
const userAccessUpdate = gql`
	mutation UpdateUserAccess($id: ID!, $active: Boolean!) {
		userAccessUpdate(id: $id, active: $active) {
			id
			active
		}
	}
`;
export const USER_AVATAR_UPDATE = gql`
	mutation UpdateUserAvatar($id: ID!, $avatar: Int!) {
		userAvatarUpdate(id: $id, avatar: $avatar) {
			id
			avatar
		}
	}
`;

export { createUser as CREATE_USER_QUERY };
export { updateUser as USER_UPDATE_QUERY };
export { user as USER_QUERY };
export { users as USER_SEARCH_QUERY };
export { usersCount as USERS_COUNT_QUERY };
export { userPasswordReset as USER_RESET_QUERY };
export { userPasswordUpdate as USER_PASSWORD_UPDATE_QUERY };
export { userAccessUpdate as USER_ACCESS_UPDATE };
