import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CompaniesMenu } from "..";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	nonCustom: {
		borderRadius: 0,
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 1050,
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const CompaniesTable = (props) => {
	const {
		className,
		data,
		loading,
		fetchData,
		count,
		called,
		language,
		onOpen,
		onExport,
		custom,
		onRowClick,
		...rest
	} = props;

	const classes = useStyles();

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0);
		setRowsPerPage(value);
	};

	useEffect(() => {
		fetchData(rowsPerPage, 0);
	}, [called]);

	const cardClassname = clsx({
		[classes.root]: true,
		[classes.nonCustom]: !custom,
		// [classes.buttonError]: buttonStatus === false,
	});

	return (
		<Card {...rest} className={cardClassname} elevation={custom ? 1 : 0}>
			{custom && (
				<CompaniesMenu
					language={language}
					classes={classes}
					onOpen={onOpen}
					onExport={onExport}
					refetch={() => fetchData(rowsPerPage, 0)}
				/>
			)}
			<Divider />
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{loading ? (
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
									</TableRow>
								</TableHead>
							) : (
								<TableHead>
									<TableRow>
										<TableCell>
											{lang.COMPANY_NAME[language]}
										</TableCell>
										<TableCell>
											{lang.CIF[language]}
										</TableCell>
										<TableCell>
											{lang.REGION[language]}
										</TableCell>
										<TableCell>
											{lang.CITY[language]}
										</TableCell>
										<TableCell>
											{lang.ADDRESS[language]}
										</TableCell>
										<TableCell>
											{lang.CREATE_DATE[language]}
										</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.map((item) => (
										<TableRow
											onClick={() => onRowClick(item)}
											className={classes.tableRow}
											hover
											key={item.id}
											selected={
												selectedUsers.indexOf(
													item.id
												) !== -1
											}
										>
											<TableCell>
												<Typography variant="body1">
													{item.name}
												</Typography>
											</TableCell>
											<TableCell>{item.cif}</TableCell>
											<TableCell>
												{item.address?.region}
											</TableCell>
											<TableCell>
												{item.address?.city}
											</TableCell>
											<TableCell>
												{`${
													item.address?.street
														? item.address.street
														: "-"
												} ${
													item.address?.streetNo
														? item.address.streetNo
														: ""
												}`}
											</TableCell>
											<TableCell>
												{moment(item.createdAt).format(
													"DD/MM/YYYY"
												)}
											</TableCell>
										</TableRow>
									))}

									{rowsPerPage - data.length > 0 && (
										<TableRow
											style={{
												height:
													53 *
													(rowsPerPage - data.length),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
		/>
	);
}

CompaniesTable.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default CompaniesTable;
