import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	Typography,
	Divider,
	Button,
	ButtonGroup,
} from "@material-ui/core";
import {
	VerifiedUser,
	WatchLater,
	Lock,
	Security,
	LockOpen,
	Cancel,
} from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import * as lang from "../../../../utils/strings";
import {
	USER_RESET_QUERY,
	USER_ACCESS_UPDATE,
	USER_AVATAR_UPDATE,
} from "../../../../requests";
import { CustomModal } from "../../../../components";
import { AvatarSelect } from "..";

const useStyles = makeStyles((theme) => ({
	root: {},
	details: {
		display: "flex",
	},
	avatar: {
		marginLeft: "auto",
		height: 90,
		width: 90,
		flexShrink: 0,
		flexGrow: 0,
	},
	ownAvatar: {
		transition: "0.3s",
		"&:hover": {
			opacity: 0.7,
			transition: "0.3s",
			border: `2px solid ${theme.palette.secondary.main}`,
		},
		cursor: "pointer",
	},
	uploadButton: {
		marginRight: theme.spacing(2),
	},
	role: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	blocked: {
		color: theme.palette.error.main,
	},
}));

const UserProfile = (props) => {
	const classes = useStyles();
	const { className, me, user, language, userActions } = props;
	const [confirmModal, setConfirmModal] = useState(false);
	const [avatarModal, setAvatarModal] = useState(false);
	const [selectedAvatar, setSelectedAvatar] = useState(1);

	const [resetPassword, { called }] = useMutation(USER_RESET_QUERY);
	const [updateUser] = useMutation(USER_ACCESS_UPDATE);
	const [updateAvatar] = useMutation(USER_AVATAR_UPDATE);

	const { enqueueSnackbar } = useSnackbar();

	const handlePasswordReset = () => {
		resetPassword({
			variables: {
				id: user.id,
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.PASSWORD_RESET_SUCCESS[language], {
					variant: "success",
				});
				setConfirmModal(false);
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};

	const handleUserBlock = () => {
		updateUser({
			variables: {
				id: user.id,
				active: !user.active,
			},
		})
			.then((response) => {
				enqueueSnackbar(
					lang[
						response.data.userAccessUpdate.active
							? "USER_UNBLOCK_SUCCESS"
							: "USER_BLOCK_SUCCESS"
					][language],
					{
						variant: "success",
					}
				);
				setConfirmModal(false);
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const handleAvatarChange = (avatar) => {
		setSelectedAvatar(avatar);
		updateAvatar({
			variables: {
				id: me.id,
				avatar,
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.USER_UPDATE_SUCCSESS[language], {
					variant: "success",
				});
				setAvatarModal(false);
				me.refetch();
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};

	useEffect(() => {
		if (me.avatar) setSelectedAvatar(me.avatar);
	}, [me?.avatar]);

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Typography variant="h3" style={{ display: "flex" }}>
							{user?.profile?.firstName}
						</Typography>
						<Typography
							gutterBottom
							noWrap
							variant="h3"
							style={{ display: "flex" }}
						>
							{user?.profile?.lastName}
						</Typography>
						{user?.active === false ? (
							<Typography
								variant="body1"
								className={classes.blocked}
								gutterBottom
							>
								<span className={classes.role}>
									<Cancel
										fontSize="small"
										className={classes.icon}
									/>
									{user?.username}
								</span>
							</Typography>
						) : user?.role === "ADMIN" ? (
							<Typography
								variant="body1"
								color="secondary"
								gutterBottom
							>
								<span className={classes.role}>
									<VerifiedUser
										fontSize="small"
										className={classes.icon}
									/>
									{user?.username}
								</span>
							</Typography>
						) : (
							<Typography
								variant="body1"
								// color="primary"
								gutterBottom
							>
								{user?.username}
							</Typography>
						)}
						<Typography color="textSecondary" variant="caption">
							<span className={classes.role}>
								<WatchLater
									fontSize="small"
									className={classes.icon}
								/>
								{moment(user?.createdAt).fromNow()}
							</span>
						</Typography>
					</div>
					<Avatar
						className={
							me?.id === user?.id
								? clsx(classes.avatar, classes.ownAvatar)
								: classes.avatar
						}
						src={
							user?.avatar
								? `/images/avatars/avatar-0${user?.avatar}.svg`
								: ""
						}
						onClick={() =>
							me.id === user.id ? setAvatarModal(true) : null
						}
					/>
				</div>
			</CardContent>
			<CustomModal
				{...props}
				fullWidth
				title={lang.CHANGE_AVATAR[language]}
				content={
					<AvatarSelect
						selected={selectedAvatar}
						handleSelect={handleAvatarChange}
					/>
				}
				open={avatarModal}
				onClose={() => setAvatarModal(false)}
				// onExecute={handlePasswordReset}
			/>
			{userActions && me?.role === "ADMIN" && (
				<>
					<CustomModal
						{...props}
						title={lang.CONFIRM_ACTION[language]}
						content={lang.ACTION_RESET[language]}
						open={confirmModal === "RESET"}
						onClose={() => setConfirmModal(false)}
						onExecute={handlePasswordReset}
					/>
					<CustomModal
						{...props}
						title={lang.CONFIRM_ACTION[language]}
						content={
							lang.ACTION_BLOCK(user?.active ? true : false)[
								language
							]
						}
						open={confirmModal === "BLOCK"}
						onClose={() => setConfirmModal(false)}
						onExecute={handleUserBlock}
					/>
					<Divider />
					<CardActions>
						<ButtonGroup size="small" variant="text" fullWidth>
							<Button
								color="secondary"
								fullWidth
								onClick={() => setConfirmModal("BLOCK")}
							>
								{user?.active ? (
									<Lock
										fontSize="small"
										className={classes.icon}
									/>
								) : (
									<LockOpen
										fontSize="small"
										className={classes.icon}
									/>
								)}
								{
									lang[user?.active ? "BLOCK" : "UNBLOCK"][
										language
									]
								}
							</Button>
							<Button
								fullWidth
								disabled={called || user?.active === false}
								onClick={() => setConfirmModal("RESET")}
							>
								<Security
									fontSize="small"
									className={classes.icon}
								/>{" "}
								{lang.RESET[language]}
							</Button>
						</ButtonGroup>
					</CardActions>
				</>
			)}
		</Card>
	);
};

UserProfile.propTypes = {
	className: PropTypes.string,
};

export default UserProfile;
