import React, { useState } from "react";
import { Card, Typography, CardContent, Avatar, ButtonGroup, CardActions, Button, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { Face, WatchLater, Delete } from "@material-ui/icons";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import redirectTo from "../../../../utils/redirect";
import * as lang from "../../../../utils/strings";
import { useMutation } from "@apollo/react-hooks";
import { CUSTOMER_DELETE_QUERY } from "../../../../requests";
import { useSnackbar } from "notistack";
import { CustomModal } from "../../../../components";

const useStyles = makeStyles((theme) => ({
	root: {},
	details: {
		display: "flex",
	},
	avatar: {
		marginLeft: "auto",
		height: 90,
		width: 90,
		flexShrink: 0,
		flexGrow: 0,
	},
	uploadButton: {
		marginRight: theme.spacing(2),
	},
	role: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	icon: {
		marginRight: theme.spacing(1),
	},
	linkText: {
		cursor: "pointer",
	},
}));

const CustomerProfile = (props) => {
	const classes = useStyles();
	const [confirmModal, setConfirmModal] = useState(false);
	const { language, data, className, me } = props;
	const { enqueueSnackbar } = useSnackbar();

	const [deleteEmployee] = useMutation(CUSTOMER_DELETE_QUERY);

	const handleEmployeeDelete = () => {
		deleteEmployee({
			variables: {
				id: data.id,
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.CUSTOMER_DELETE_SUCCESS[language], {
					variant: "success",
				});
				setConfirmModal(false);
				redirectTo("/customers");
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};

	return (
		<Card className={className}>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Typography gutterBottom variant="h3">
							{data?.firstName} {data?.lastName}
						</Typography>
						{data?.companies?.length > 0 && (
							<Typography
								variant="body1"
								color="secondary"
								className={classes.linkText}
								onClick={() => redirectTo("/company/" + data.companies[0].company.id)}
								gutterBottom
							>
								{`${data.companies[0].job} @ ${data.companies[0].company.name}`}
							</Typography>
						)}
						<Typography color="textSecondary" variant="caption">
							<span className={classes.role}>
								<WatchLater fontSize="small" className={classes.icon} />
								{moment(data?.createdAt).fromNow()}
							</span>
						</Typography>
					</div>
					<Avatar className={classes.avatar}>
						<Face fontSize="large" />
					</Avatar>
				</div>
			</CardContent>
			{me?.role === "ADMIN" && (
				<>
					<Divider />
					<CustomModal
						{...props}
						title={lang.DELETE_CUSTOMER[language]}
						content={lang.DELETE_CUSTOMER_CONFIRM[language]}
						open={confirmModal === "DELETE"}
						onClose={() => setConfirmModal(false)}
						onExecute={handleEmployeeDelete}
					/>
					<CardActions>
						<ButtonGroup size="small" variant="text" fullWidth>
							<Button color="primary" fullWidth onClick={() => setConfirmModal("DELETE")}>
								<Delete fontSize="small" className={classes.icon} /> {lang.DELETE_CUSTOMER[language]}
							</Button>
						</ButtonGroup>
					</CardActions>
				</>
			)}
		</Card>
	);
};

CustomerProfile.propTypes = {
	className: PropTypes.string,
	language: PropTypes.string.isRequired,
};

export { CustomerProfile as default };
