import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CssBaseline, Grow, Grid, LinearProgress } from "@material-ui/core";
import { CustomerProfile, CustomerDetails, CustomerJobs, CustomerActions, CustomerDocs } from "./components";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CUSTOMER_QUERY, CUSTOMER_UPDATE_QUERY } from "../../requests";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import * as lang from "../../utils/strings";
import { DOCUMENT_SEARCH_QUERY } from "../../requests/documentRequests";
import redirectTo from "../../utils/redirect";
import { exportData } from "../../utils/fetchFunctions";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
	space: {
		marginTop: theme.spacing(4),
	},
}));

const Customer = (props) => {
	const { language } = props;
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [buttonStatus, setButtonStatus] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [filterDocs, setFilterDocs] = useState(true);
	const [viewMore, setViewMore] = React.useState(false);

	const { register, handleSubmit, errors, setValue, getValues, unregister } = useForm();

	const [updateCustomer] = useMutation(CUSTOMER_UPDATE_QUERY);

	const handleCustomerEdit = (newData) => {
		setButtonStatus("loading");
		updateCustomer({
			variables: {
				id: data.customer.id,
				data: { ...newData.customer },
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.CUSTOMER_UPDATE_SUCCESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setViewMore(false);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
			})
			.then((response) => {
				refetch();
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const handleExportDocs = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("DOCS", null, data?.customer?.id)
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const [getCustomer, { data, loading, networkStatus, refetch }] = useLazyQuery(CUSTOMER_QUERY, {
		fetchPolicy: "no-cache",
	});

	const [
		getDocs,
		{ data: dataDocs, loading: loadingDocs, networkStatus: networkStatusDocs, refetch: refetchDocs },
	] = useLazyQuery(DOCUMENT_SEARCH_QUERY, {
		fetchPolicy: "no-cache",
	});

	const handleRowClick = (data) => data?.job?.company?.id && redirectTo(`/company/${data.job.company.id}`);

	useEffect(() => {
		if (data?.customer) {
			console.log(data.customer)
			setValue("customer", data.customer);
		}
		// if (data?.company) {
		// 	setValue("company.name", data.company.name);
		// 	setValue("company.cif", data.company.cif);
		// }
	}, [data, setValue]);

	useEffect(() => {
		const id = window.location.pathname.split("/")[2];
		console.log(id);
		getCustomer({
			variables: {
				id,
			},
		});
	}, [getCustomer]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{loading ? (
				<LinearProgress />
			) : (
				<Grid container spacing={4} direction="row">
					<Grid item xs={12}>
						<CustomerActions
							{...props}
							addModal={addModal}
							setAddModal={setAddModal}
							refetch={refetch}
							refetchDocs={refetchDocs}
							customerId={data?.customer?.id}
							className={classes.display}
							customer={data?.customer}
							jobs={data?.customer?.companies}
						/>
					</Grid>
					<Grid item lg={4} md={6} xl={4} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={true}>
									<CustomerProfile {...props} data={data?.customer} className={classes.display} />
								</Grow>
							</Grid>
							<Grid item className={classes.space}>
								<Grow in={true}>
									<CustomerDetails
										{...props}
										data={data?.customer}
										register={register}
										errors={errors}
										setValue={setValue}
										unregister={unregister}
										getValues={getValues}
										onExecute={handleCustomerEdit}
										handleSubmit={handleSubmit}
										buttonStatus={buttonStatus}
										viewMore={viewMore}
										setViewMore={setViewMore}
										className={classes.display}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={8} md={6} xl={8} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item xs={12}>
								<Grow in={true}>
									<CustomerJobs
										{...props}
										custom
										refetch={refetch}
										loading={loading && !networkStatus === 4}
										onOpen={() => setAddModal(true)}
										data={data?.customer?.companies !== undefined ? data.customer.companies : []}
										count={data?.customer?.companies !== undefined ? data.customer.companies.length : 0}
										onRowClick={handleRowClick}
										className={classes.display}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grow in={true}>
							<CustomerDocs
								{...props}
								custom
								customerId={data?.customer?.id}
								fetchData={(rows, page, filters) =>
									data?.customer?.id &&
									getDocs({
										variables: {
											where: {
												AND: filters,
											},
											pagination: {
												first: rows,
												skip: Math.max(page, 0) * rows,
											},
										},
									})
								}
								refetch={refetchDocs}
								onExport={handleExportDocs}
								loading={loadingDocs && !networkStatusDocs === 4}
								called={true}
								// onOpen={() => setAddModal(true)}
								data={dataDocs?.documents?.documents !== undefined ? dataDocs.documents.documents : []}
								count={dataDocs?.documents?.count !== undefined ? dataDocs.documents.count : 0}
								onRowClick={handleRowClick}
								className={classes.display}
							/>
						</Grow>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export { Customer as default };
