import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as lang from "../../../../utils/strings";
import getError from "../../../../utils/getError";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";

const reasons = [
	{
		value: 1,
		name: "Angajare",
	},
	{
		value: 2,
		name: "Reluarea muncii",
	},
	{
		value: 3,
		name: "Examen periodic",
	},
	{
		value: 4,
		name: "Supraveghere specială",
	},
	{
		value: 5,
		name: "Adaptare",
	},
	{
		value: 6,
		name: "Alte",
	},
];

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const FisaAptForm = (props) => {
	const classes = useStyles();
	const { language, register, setValue, unregister, data, errors, clearError, expanded } = props;

	const [reason, setReason] = useState(null);
	const [createDate, setCreateDate] = useState(null);
	const [nextDate, setNextDate] = useState(null);

	const handleReasonSelect = (event) => {
		setValue("doc.fisaApt.reason", event.target.value, true);
		setReason(event.target.value);
	};
	const handleCreateDateChange = (value) => {
		let date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("doc.fisaApt.createDate", date);
			clearError("doc.fisaApt.createDate");
			setCreateDate(date);
		}
	};
	const handleNextDateChange = (value) => {
		let date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("doc.fisaApt.nextDate", date);
			clearError("doc.fisaApt.nextDate");
			setNextDate(date);
		}
	};

	useEffect(() => {
		if (expanded === "OPEN") {
			clearError();
			register({ name: "doc.fisaApt.reason" }, { required: true });
			register({ name: "doc.fisaApt.createDate" }, { required: true });
			register({ name: "doc.fisaApt.nextDate" }, { required: true });
			register({ name: "doc.type" });
			register({ name: "doc.state" });
		}
		unregister("registries");
	}, [register, expanded]);

	useEffect(() => {
		if (data) setValue("doc", data);
		unregister("registries");
	}, [data, setValue]);

	return (
		<React.Fragment>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					select
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					label={lang.REASON[language]}
					name="doc.fisaApt.reason"
					onChange={handleReasonSelect}
					variant="outlined"
					value={reason ? reason : ""}
					error={!!errors?.doc?.fisaApt?.reason}
					helperText={!!errors?.doc?.fisaApt?.reason ? getError(errors.doc.fisaApt.reason, language) : null}
				>
					{reasons.map((item) => (
						<li className={classes.listItem} key={item.value} value={item.value}>
							<Typography variant="body1">{item.name}</Typography>
						</li>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<KeyboardDatePicker
						autoOk
						disabled={["DONE", "DELIVERED"].includes(data?.state)}
						variant="inline"
						required={true}
						inputVariant="outlined"
						fullWidth
						name="doc.fisaApt.createDate"
						label={lang.CREATE_DATE[language]}
						format="dd/MM/yyyy"
						onChange={handleCreateDateChange}
						value={createDate}
						error={!!errors?.doc?.fisaApt?.createDate}
						helperText={!!errors?.doc?.fisaApt?.createDate ? getError(errors.doc.fisaApt.createDate, language) : null}
					/>
				</MuiPickersUtilsProvider>
			</Grid>

			<Grid item xs={12} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<KeyboardDatePicker
						autoOk
						disabled={["DONE", "DELIVERED"].includes(data?.state)}
						variant="inline"
						inputVariant="outlined"
						fullWidth
						name="doc.fisaApt.nextDate"
						label={lang.NEXT_DATE[language]}
						format="dd/MM/yyyy"
						onChange={handleNextDateChange}
						value={nextDate}
						error={!!errors?.doc?.fisaApt?.nextDate}
						helperText={!!errors?.doc?.fisaApt?.nextDate ? getError(errors.doc.fisaApt.nextDate, language) : null}
						minDate={new Date()}
					/>
				</MuiPickersUtilsProvider>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					onClick={() => console.log(errors)}
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.COMPANY_NAME[language]}
					name="doc.fisaApt.name"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.name}
					helperText={!!errors?.doc?.fisaApt?.name ? getError(errors.doc.fisaApt.name, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.JOB[language]}
					name="doc.fisaApt.job"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.job}
					helperText={!!errors?.doc?.fisaApt?.job ? getError(errors.doc.fisaApt.job, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField
					fullWidth
					required
					multiline
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.ADDRESS[language]}
					name="doc.fisaApt.address"
					type="number"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.address}
					helperText={!!errors?.doc?.fisaApt?.address ? getError(errors.doc.fisaApt.address, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.PHONE[language]}
					name="doc.fisaApt.phone"
					type="number"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.phone}
					helperText={!!errors?.doc?.fisaApt?.phone ? getError(errors.doc.fisaApt.phone, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.FIRST_NAME[language]}
					name="doc.fisaApt.firstName"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.firstName}
					helperText={!!errors?.doc?.fisaApt?.firstName ? getError(errors.doc.fisaApt?.firstName, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.LAST_NAME[language]}
					name="doc.fisaApt.lastName"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.lastName}
					helperText={!!errors?.doc?.fisaApt?.lastName ? getError(errors.doc.fisaApt.lastName, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					disabled={["DONE", "DELIVERED"].includes(data?.state)}
					margin={"dense"}
					label={lang.CNP[language]}
					name="doc.fisaApt.cnp"
					type="number"
					inputRef={register({
						minLength: 13,
						required: true,
					})}
					onInput={(e) => {
						e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13);
					}}
					variant="outlined"
					error={!!errors?.doc?.fisaApt?.cnp}
					helperText={!!errors?.doc?.fisaApt?.cnp ? getError(errors.doc.fisaApt.cnp, language, 13) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
		</React.Fragment>
	);
};

export { FisaAptForm as default };
