import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { UsersTable } from "./components";
import { USER_SEARCH_QUERY, CREATE_USER_QUERY } from "../../requests";
import { CssBaseline, Grow } from "@material-ui/core";
import * as lang from "../../utils/strings";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { CustomModal } from "../../components";
import { UserForm } from "../../Forms";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import browserHistory from "../../config/history";
import redirectTo from "../../utils/redirect";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
}));

const UserList = (props) => {
	const classes = useStyles();
	const { language, me, auth } = props;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [addModal, setAddModal] = useState(false);
	const [userRole, setUserRole] = useState("USER");
	const { register, handleSubmit, errors, setValue, reset } = useForm({
		defaultValues: {
			role: "USER",
		},
	});

	const handleRoleChange = (role) => {
		setValue("role", role.target.value);
		setUserRole(role.target.value);
	};

	const [
		createUser,
		{
			loading: createUserLoading,
			networkStatus: createUserNetworkStatus,
			called: createUserCalled,
		},
	] = useMutation(CREATE_USER_QUERY);

	const [getUsers, { data, loading, refetch, networkStatus }] = useLazyQuery(
		USER_SEARCH_QUERY,
		{
			fetchPolicy: "cache-and-network",
		}
	);

	const handleRowClick = (user) => redirectTo(`/user/${user.id}`);

	useEffect(() => {
		document.title = `Pronto - ${lang.USERS[language]}`;
	}, [language]);

	useEffect(() => {
		if (!auth.isAuthenticated || me.role === "USER") {
			browserHistory.push("/");
		}
	});

	useEffect(() => {
		register({ name: "role" });
	}, [register]);

	useEffect(() => {
		reset({ role: "USER" });
	}, [addModal]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<CustomModal
				language={language}
				title={lang.ADD_USER[language]}
				open={addModal}
				onClose={() => setAddModal(false)}
				onExecute={(data) => {
					createUser({
						variables: { data },
					})
						.then((response) => {
							enqueueSnackbar(
								lang.USER_CREATE_SUCCSESS[language],
								{
									variant: "success",
								}
							);
							refetch();
							setAddModal(false);
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					addModal && (
						<UserForm
							language={language}
							register={register}
							errors={errors}
							handleRoleChange={handleRoleChange}
							userRole={userRole}
						/>
					)
				}
				handleSubmit={handleSubmit}
				form
				fullWidth
			/>
			<Grow in={true}>
				<UsersTable
					language={language}
					users={
						data?.users?.users !== undefined ? data.users.users : []
					}
					loading={
						(loading && !networkStatus === 4) ||
						(createUserLoading && !createUserNetworkStatus === 4)
					}
					called={createUserCalled}
					onOpen={() => setAddModal(true)}
					count={
						data?.users?.count !== undefined ? data.users.count : 0
					}
					onRowClick={handleRowClick}
					fetchData={(rows, page) =>
						getUsers({
							variables: {
								where: {},
								pagination: {
									first: rows,
									skip: Math.max(page, 0) * rows,
								},
							},
						})
					}
					className={classes.display}
				/>
			</Grow>
		</div>
	);
};

export { UserList as default };
