import React from "react";
import { Typography } from "@material-ui/core";

const NoResults = (props) => {
	const { message } = props;
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				src={`/images/no_results.svg`}
				style={{
					width: "5vw",
				}}
			/>
			<br />
			<Typography variant="caption" gutterBottom>
				{message}
			</Typography>
		</div>
	);
};

export { NoResults as default };
