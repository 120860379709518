import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FisaForm } from "../../../../Forms";
import { ExpansionTab, RegistryTab, ResultTab } from "./components";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { DOCUMENT_UPDATE_QUERY, DOCUMENT_QUERY } from "../../../../requests";
import * as lang from "../../../../utils/strings";
import { useSnackbar } from "notistack";
import { green, red } from "@material-ui/core/colors";
import { generatePdf } from "../../../../utils/fetchFunctions";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: "33.33%",
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonError: {
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[700],
		},
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		position: "relative",
	},
	customBackground: {
		backgroundColor: theme.palette.background.default,
		margin: theme.spacing(1),
		padding: theme.spacing(1),
	},
	margins: {
		margin: theme.spacing(1),
	},
	rightIcon: {
		marginLeft: theme.spacing(1),
	},
}));

const DocTabs = (props) => {
	const classes = useStyles();
	const { id, form, language } = props;
	const [expanded, setExpanded] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [buttonStatus, setButtonStatus] = useState(false);

	const { register, handleSubmit, errors, clearError, setValue, getValues, reset, unregister } = useForm({});

	const [updateDocument] = useMutation(DOCUMENT_UPDATE_QUERY);

	const [getDocument, { data: docData, loading, refetch }] = useLazyQuery(DOCUMENT_QUERY, {
		fetchPolicy: "no-cache",
	});

	const onSubmitStep2 = (data, e) => {
		let registries = [];
		let auxData = data;
		delete auxData.doc;
		data && data.registries && data.type === "FAPT"
			? (registries = [{ ...data.registries[0], able: data.registries[0].able === "able" }])
			: data.registries.map((item) => registries.push({ ...item, able: item.able === "able" }));
		setButtonStatus("loading");

		updateDocument({
			variables: {
				id,
				state: "DONE",
				data: { registries },
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.DOC_UPDATE_SUCCESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
				refetch();
				form.refetchDocs();
				setExpanded(response.data.updateDocument.state);
				unregister("registries");
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const onSubmitStep3 = (data, e) => {
		setButtonStatus("loading");

		let FAUT = null;
		if (data.type === "FAUT") {
			FAUT = {
				...data.FAUT,
				result: data.FAUT.result === 1 ? true : false,
			};
		}
		let DMED = null;
		if (data.type === "DMED") {
			DMED = {
				...data.DMED,
				ability: data.DMED.result,
			};
		}

		updateDocument({
			variables: {
				id,
				state: "DELIVERED",
				data: { FAPT: data.FAPT, FAUT, DMED },
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.DOC_UPDATE_SUCCESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
				refetch();
				form.refetchDocs();
				setExpanded(response.data.updateDocument.state);
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};
	const onSubmitStep4 = () => {
		let fileName = `file-${new Date().getTime()}.pdf`;
		setButtonStatus("loading");
		generatePdf(fileName, docData)
			.then((response) => {
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
					setButtonStatus("error");
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
					updateDocument({
						variables: {
							id,
							state: "CLOSED",
							data: { name: fileName, dataUri: response.file },
						},
					})
						.then((response) => {
							enqueueSnackbar(lang.DOC_UPDATE_SUCCESS[language], {
								variant: "success",
							});
							setButtonStatus(true);
							setTimeout(() => {
								setButtonStatus(false);
							}, 2000);
							refetch();
							form.refetchDocs();
							props.onClose();
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
							// setButtonStatus("error");
						});
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
					// setButtonStatus("error");
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : docData?.document?.state);
		unregister();
	};

	useEffect(() => {
		if (docData?.document?.state) {
			setExpanded(docData.document.state);
		}
	}, [docData?.document]);

	useEffect(() => {
		if (id)
			getDocument({
				variables: {
					id,
				},
			});
	}, [id]);

	return (
		<Grid container spacing={2}>
			{/* <ExpansionTab
				classes={classes}
				expanded={expanded}
				type="OPEN"
				state={docData?.document?.state}
				title="Detalii generale"
				handleChange={handleChange}
				content={
					id &&
					expanded === "OPEN" && (
						<Grid container spacing={2}>
							<FisaForm
								{...form}
								data={docData?.document}
								expanded={expanded}
							/>
						</Grid>
					)
				}
				handleSubmit={form.handleSubmit(onSubmitStep1)}
				buttonStatus={buttonStatus}
				language={language}
			/> */}
			<ExpansionTab
				classes={classes}
				expanded={expanded}
				type="OPEN"
				state={docData?.document?.state}
				title={`Confirmare registre${
					docData?.document?.FAUT?.type
						? ` - Grupa ${docData.document.FAUT.type === 3 ? "1 și 2" : docData.document.FAUT.type}`
						: ""
				}`}
				handleChange={handleChange}
				content={
					id &&
					expanded === "OPEN" && (
						<Grid container spacing={2}>
							<RegistryTab
								{...form}
								register={register}
								setValue={setValue}
								unregister={unregister}
								errors={errors}
								clearError={clearError}
								classes={classes}
								data={docData?.document}
								expanded={expanded}
							/>
						</Grid>
					)
				}
				handleSubmit={handleSubmit(onSubmitStep2)}
				buttonStatus={buttonStatus}
				language={language}
			/>
			<ExpansionTab
				classes={classes}
				expanded={expanded}
				type="DONE"
				state={docData?.document?.state}
				title="Concluzionare fișă"
				handleChange={handleChange}
				content={
					id &&
					expanded === "DONE" && (
						<Grid container spacing={2}>
							<ResultTab
								{...form}
								register={register}
								setValue={setValue}
								unregister={unregister}
								errors={errors}
								clearError={clearError}
								data={docData?.document}
								classes={classes}
								expanded={expanded}
							/>
						</Grid>
					)
				}
				handleSubmit={handleSubmit(onSubmitStep3)}
				buttonStatus={buttonStatus}
				language={language}
			/>
			<ExpansionTab
				classes={classes}
				expanded={expanded}
				type="DELIVERED"
				state={docData?.document?.state}
				title="Închidere fișă"
				handleChange={handleChange}
				content={
					id &&
					expanded === "DELIVERED" && (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4" gutterBottom>
									Fișa va fi închisă și un fișier va fi generat și descărcat.
								</Typography>
							</Grid>
						</Grid>
					)
				}
				handleSubmit={onSubmitStep4}
				buttonStatus={buttonStatus}
				language={language}
			/>
		</Grid>
	);
};

export { DocTabs as default };
