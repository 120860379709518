import React from "react";
import { TextField, Grid, MenuItem, Divider } from "@material-ui/core";
import * as lang from "../../utils/strings";
import getError from "../../utils/getError";
import { AddressForm } from "..";

const CompanyFormContent = (props) => {
	const { language, register, errors, edit } = props;

	return (
		<React.Fragment>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					margin={edit ? "dense" : "none"}
					label={lang.COMPANY_NAME[language]}
					name="company.name"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.company?.name}
					helperText={
						!!errors?.company?.name
							? getError(errors.company.name, language)
							: null
					}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.CIF[language]}
					name="company.cif"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.PHONE[language]}
					name="company.phone"
					type="number"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<AddressForm {...props} />
		</React.Fragment>
	);
};

const CompanyForm = (props) => {
	const { standalone } = props;

	if (standalone)
		return (
			<Grid container spacing={2}>
				<CompanyFormContent {...props} />
			</Grid>
		);
	else return <CompanyFormContent {...props} />;
};

export { CompanyForm as default };
