import React, { useEffect, useState } from "react";
import {
	Grid,
	Card,
	Typography,
	TextField,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormHelperText,
	Divider,
} from "@material-ui/core";
import * as lang from "../../../../../../utils/strings";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";
import getError from "../../../../../../utils/getError";
import { useQuery } from "@apollo/react-hooks";
import { REGISTRIES_SEARCH_QUERY } from "../../../../../../requests";
import VirtualizedSelect from "../../../../../../components/VirtualizedSelect/VirtualizedSelect";

const getNote = (type) => {
	switch (type) {
		case "MED_INT":
			return "Clinic normal";
		case "PNEUM":
			return "Sănătos clinic, risc scăzit SASO";
		case "CHIR":
			return "Clinic normal";
		case "NEURO":
			return "Clinic normal, neagă epilepsie";
		case "PSHT":
			return "Psihic normal";
		case "ORL":
			return "Normal";
		case "OFT":
			return "Simt cromatic normal";
		default:
			return "";
	}
};

const RegistryTab = (props) => {
	const { data } = props;
	return (
		<React.Fragment>
			{data?.registries.map((registry, index) => (
				<RegistryElement key={index} {...props} item={registry} index={index} />
			))}
		</React.Fragment>
	);
};

export { RegistryTab as default };

const RegistryElement = (props) => {
	const {
		classes,
		item,
		language,
		index,
		register,
		setValue,
		unregister,
		errors,
		clearError,
		expanded,
		docType,
		data: docData,
	} = props;
	
	const [ability, setAbility] = useState("able");
	const [consultDate, setConsultDate] = useState(new Date(docData.createDate).toISOString().split("T")[0]);
	const [conditions, setConditions] = useState("");

	const today = new Date().toISOString().split("T")[0];

	const handleRadioChange = (event) => {
		setValue(`registries[${index}].able`, event.target.value);
		clearError(`registries[${index}].able`);
		setAbility(event.target.value);
	};
	const handleConsultDateChange = (value) => {
		const date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue(`registries[${index}].consultDate`, date);
			clearError(`registries[${index}].consultDate`);
			setConsultDate(date);
		}
	};

	const { data } = useQuery(REGISTRIES_SEARCH_QUERY, {
		variables: {
			where: {
				type: item.type,
			},
		},
		fetchPolicy: "no-cache",
	});

	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index && value !== null && value !== undefined && value !== "";
	};

	useEffect(() => {
		if (expanded === "OPEN") {
			clearError();
			register({ name: `registries[${index}].consultDate` }, { required: true });
			register({ name: `registries[${index}].able` }, { required: true });
			register({ name: `registries[${index}].id` });
			setValue(`registries[${index}].consultDate`, today);
		}
		unregister("doc");
	}, [register, expanded]);

	useEffect(() => {
		if (item?.id) setValue(`registries[${index}].id`, item.id);
		setValue(`registries[${index}].able`, "able");
	}, [item, setValue, docType]);

	return (
		<Card className={classes.customBackground} style={{ width: "100%" }}>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} sm={6} md={4}>
					<Typography className={classes.margins} onClick={() => console.log(errors)}>
						{lang[item.type][language].toUpperCase()}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
						<KeyboardDatePicker
							autoOk
							variant="inline"
							required={true}
							inputVariant="outlined"
							fullWidth
							name={`registries[${index}].consultDate`}
							label={lang.CHECKUP_DATE[language]}
							format="dd/MM/yyyy"
							onChange={handleConsultDateChange}
							value={consultDate}
							error={!!errors.registries && !!errors.registries[index]?.consultDate}
							helperText={
								!!errors.registries && !!errors.registries[index]?.consultDate
									? getError(errors.registries[index].consultDate, language)
									: null
							}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<FormControl
							component="fieldset"
							className={classes.margins}
							name={`registries[${index}].able`}
							required
							error={!!errors.registries && !!errors.registries[index]?.able}
						>
							<RadioGroup value={ability} onChange={handleRadioChange} row>
								<FormControlLabel value={"able"} control={<Radio />} label="Apt" />
								<FormControlLabel value={"unable"} control={<Radio />} label="Inapt" />
							</RadioGroup>
							<FormHelperText>
								{!!errors.registries && !!errors.registries[index]?.able
									? getError(errors.registries[index].able, language)
									: null}
							</FormHelperText>
						</FormControl>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>

				{docType === "FAUT" && (
					<Grid item xs={12} sm={6}>
						<VirtualizedSelect
							freeSolo={true}
							language={language}
							options={
								data?.registries?.registries?.length
									? [...data.registries.registries.map((item) => item.conditions), getNote(item.type)].filter(
											onlyUnique
									  )
									: [getNote(item.type)]
							}
							value={conditions}
							textFieldProps={{
								required: true,
								name: `registries[${index}].conditions`,
								inputRef: register,
								label: lang.CONDITIONS[language],
							}}
						/>
					</Grid>
				)}
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						multiline
						label={lang.NOTE[language]}
						name={`registries[${index}].note`}
						inputRef={register}
						variant="outlined"
					/>
				</Grid>
			</Grid>
		</Card>
	);
};
