export const SOMETHING_WRONG = {
	ro: "Am întâmpinat o eroare!",
	en: "Something went wrong!",
};
export const UNABLE_LOGIN = {
	ro: "Am întâmpinat o eroare!",
	en: "Unable to login!",
};
export const ACCOUNT_BLOCKED = {
	ro: "Cont dezactivat!",
	en: "Account blocked!",
};
export const LOGIN_SUCCESS = {
	ro: "Ai fost autentificat cu succes!",
	en: "You have been successfully logged in!",
};
export const LOGOUT_SUCCESS = {
	ro: "Ai fost deconectat!",
	en: "You have been logged out!",
};
export const WRONG_PASSWORD = {
	ro: "Ai greșit parola!",
	en: "Wrong password!",
};

export const USER_CREATE_SUCCSESS = {
	ro: "Utilizator creat cu succes!",
	en: "User created successfully!",
};
export const USER_UPDATE_SUCCSESS = {
	ro: "Utilizator actualizat cu succes!",
	en: "User updated successfully!",
};
export const COMPANY_UPDATE_SUCCSESS = {
	ro: "Companie actualizat cu succes!",
	en: "Company updated successfully!",
};
export const CUSTOMER_UPDATE_SUCCESS = {
	ro: "Pacient actualizat cu succes!",
	en: "Patient updated successfully!",
};
export const CUSTOMER_CREATE_SUCCESS = {
	ro: "Pacient creat cu succes!",
	en: "Patient created successfully!",
};
export const COMPANY_CREATE_SUCCESS = {
	ro: "Companie creată cu succes!",
	en: "Company created successfully!",
};
export const LANGUAGE_CHANGED_SUCCESS = {
	ro: "Limbă actualizată cu succes!",
	en: "Language changed successfully!",
};
export const PASSWORD_RESET_SUCCESS = {
	ro: "Parolă resetată cu succes!",
	en: "Password reset successfully!",
};
export const CUSTOMER_DELETE_SUCCESS = {
	ro: "Pacient șters cu succes!",
	en: "Patient deleted successfully!",
};
export const COMPANY_DELETE_SUCCESS = {
	ro: "Companie ștearsă cu succes!",
	en: "Company deleted successfully!",
};
export const PAYMENT_UPDATE_SUCCESS = {
	ro: "Plată actualizată cu succes!",
	en: "Payment updated successfully!",
};
export const USER_UNBLOCK_SUCCESS = {
	ro: "Utilizator activat cu succes!",
	en: "User unblocked successfully!",
};
export const USER_BLOCK_SUCCESS = {
	ro: "Utilizator dezactivat cu succes!",
	en: "User blocked successfully!",
};
export const PASSWORD_CONFIRM_ERROR = {
	ro: "Confirmă noua parola!",
	en: "Confim the new password!",
};
export const EMPLOYEES_DELETED_SUCCESS = {
	ro: "Angajații au fost eliminați cu succes!",
	en: "Employees deleted successfully!",
};
export const JOBS_DELETED_SUCCESS = {
	ro: "Locurile de muncă au fost eliminați cu succes!",
	en: "Jobs deleted successfully!",
};

export const DOC_CREATE_SUCCESS = {
	ro: "Document creat cu succes!",
	en: "Document created successfully!",
};
export const DOC_UPDATE_SUCCESS = {
	ro: "Document actualizat cu succes!",
	en: "Document updated successfully!",
};
export const DOCUMENTS_DELETED_SUCCESS = {
	ro: "Documentele au fost eliminați cu succes!",
	en: "Documents deleted successfully!",
};
