import { gql } from "apollo-boost";

export const CREATE_CUSTOMER_COMPANY_QUERY = gql`
	mutation AddCustomerCompanyQuery($data: CompanyEmployeeCreateInput!) {
		createCompanyEmployee(data: $data) {
			id
		}
	}
`;
export const DELETE_COMPANY_EMPLOYEES_QUERY = gql`
	mutation DeleteCompanyEmployees($data: [ID!]!) {
		deleteCompanyEmployees(data: $data)
	}
`;
