import React from "react";
import PropTypes from "prop-types";
import { CustomMenu } from "../../../../components";
import {
	AddBox,
	MoreVert,
	Refresh,
	DoneAll,
	CheckBoxRounded,
	Clear,
	Save,
} from "@material-ui/icons";
import { IconButton, Typography, CardHeader, Divider } from "@material-ui/core";
import * as lang from "../../../../utils/strings";

const CustomersMenu = (props) => {
	const {
		language,
		classes,
		onOpen,
		onExport,
		employees,
		onSelection,
		selection,
		selected,
	} = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	return (
		<React.Fragment>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.ADD_CUSTOMER[language],
						icon: <AddBox className={classes.leftButton} />,
						render: !employees && !selection,
						action: onOpen,
					},
					{
						title: lang.ADD_EMPLOYEE[language],
						icon: <AddBox className={classes.leftButton} />,
						render: !!employees && !selection,
						action: onOpen,
					},
					{
						title: lang.EXPORT[language],
						icon: <Save className={classes.leftButton} />,
						render: !employees && !selection,
						action: onExport,
					},
					{
						title: lang.SELECT[language],
						icon: (
							<CheckBoxRounded className={classes.leftButton} />
						),
						render: false,
						action: onSelection,
					},
				]}
			/>
			<CardHeader
				title={
					<div className={classes.tableExtraHeaderSelect}>
						<div className={classes.tableExtraHeader}>
							{selection ? (
								<IconButton
									className={classes.leftButton}
									onClick={onSelection}
									size="small"
								>
									<Clear fontSize="small" />
								</IconButton>
							) : (
								<IconButton
									className={classes.leftButton}
									onClick={(e) =>
										setAnchorEl(e.currentTarget)
									}
									size="small"
								>
									<MoreVert fontSize="small" />
								</IconButton>
							)}

							<Typography
								variant="h4"
								className={classes.tableTitle}
							>
								{employees
									? lang.EMPLOYEES[language]
									: lang.CUSTOMERS[language]}
							</Typography>
						</div>
						{selection && (
							<Typography
								variant="body1"
								color="textSecondary"
								className={classes.tableTitle}
							>
								{`${selected?.length} items selected`}
							</Typography>
						)}
					</div>
				}
			/>
		</React.Fragment>
	);
};

CustomersMenu.propTypes = {
	language: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
};

export default CustomersMenu;
