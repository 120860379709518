import React, { useEffect, useState } from "react";
import { CssBaseline, Grid, LinearProgress, Grow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { UserProfile, UserDetails } from "./components";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER_QUERY, USER_UPDATE_QUERY } from "../../requests";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/react-hooks";
import * as lang from "../../utils/strings";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
	space: {
		marginTop: theme.spacing(4),
	},
}));

const User = (props) => {
	const { language } = props;
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [role, setRole] = useState(null);
	const [buttonStatus, setButtonStatus] = useState(false);

	const { register, handleSubmit, errors, setValue, getValues } = useForm();

	const [updateUser] = useMutation(USER_UPDATE_QUERY);

	const handleUserEdit = (newData) => {
		setButtonStatus("loading");
		updateUser({
			variables: {
				id: data.user.id,
				data: { ...newData },
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.USER_UPDATE_SUCCSESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
			})
			.then((response) => {
				refetch();
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const [getUser, { data, loading, refetch }] = useLazyQuery(USER_QUERY);

	const handleRoleChange = (event) => {
		setValue("role", event.target.value);
		setRole(event.target.value);
	};

	useEffect(() => {
		const userId = window.location.pathname.split("/")[2];
		getUser({
			variables: {
				id: userId,
			},
		});
	}, [getUser]);

	useEffect(() => {
		if (data?.user?.profile) setValue("profile", data.user.profile);
		if (data?.user?.role) {
			setValue("role", data.user.role);
			setRole(data.user.role);
		}
	}, [data, setValue]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{loading ? (
				<LinearProgress />
			) : (
				<Grid container spacing={4} direction="row">
					<Grid item lg={4} md={6} xl={4} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={true}>
									<UserProfile
										{...props}
										user={data?.user}
										className={classes.display}
										userActions
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={8} md={6} xl={8} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={true}>
									<UserDetails
										{...props}
										user={data?.user}
										className={classes.display}
										register={register}
										errors={errors}
										setValue={setValue}
										role={role}
										handleRoleChange={handleRoleChange}
										onExecute={handleUserEdit}
										handleSubmit={handleSubmit}
										getValues={getValues}
										buttonStatus={buttonStatus}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export { User as default };
