import { gql } from "apollo-boost";

export const DOCUMENT_SEARCH_QUERY = gql`
	query SearchDocumentsQuery($where: DocumentWhereInput, $pagination: PaginationInput) {
		documents(where: $where, pagination: $pagination, orderBy: createdAt_DESC) {
			count
			documents(where: $where, pagination: $pagination, orderBy: createdAt_DESC) {
				id
				name
				type
				dataUri
				state
				paid
				amount
				invoiceNo
				FAPT {
					nextDate
				}
				job {
					id
					job
					company {
						id
						name
						phone
						address {
							addressString
						}
					}
				}
				employee {
					id
					lastName
					firstName
					gender
					phone
					ci {
						cnp
					}
					birthDate
				}
				createDate
				createdAt
			}
		}
	}
`;
export const DOCUMENT_UPDATE_QUERY = gql`
	mutation UpdateDocumentQuery($id: Int!, $state: DocState!, $data: DocumentUpdateInput!) {
		updateDocument(id: $id, state: $state, data: $data) {
			id
			state
		}
	}
`;
export const DOCUMENT_UPDATE_PAYMENT_QUERY = gql`
	mutation UpdateDocumentPaymentQuery($id: Int!, $data: DocumentUpdateInput!) {
		updateDocumentPayment(id: $id, data: $data) {
			id
		}
	}
`;
export const DOCUMENT_QUERY = gql`
	query DocumentQuery($id: Int!) {
		document(id: $id) {
			id
			name
			createDate
			createdAt
			type
			dataUri
			state
			paid
			amount
			invoiceNo
			FAPT {
				id
				docNo
				type
				nextDate
				result
				note
			}
			FAUT {
				id
				type
				result
			}
			DMED {
				id
				result
				ability
				note
			}
			employee {
				id
				firstName
				lastName
				gender
				phone
				birthDate
				parentF
				parentM
				birthPlace {
					region
					city
					addressString
				}
				residence {
					region
					city
					street
					streetNo
					addressString
				}
				ci {
					cnp
					idSeries
					idNo
					idIssuer
					idDate
				}
			}
			job {
				job
				company {
					id
					name
					cif
					phone
					address {
						addressString
					}
				}
			}
			registries {
				id
				regNo
				type
				able
				consultDate
				note
				conditions
			}
		}
	}
`;

export const DOCUMENT_CREATE_QUERY = gql`
	mutation CreateDocumentQuery($type: DocType!, $aux: Int, $data: CompanyEmployeeWithDocumentCreateInput!) {
		createDocument(type: $type, aux: $aux, data: $data) {
			id
			type
			state
			job {
				id
				company {
					id
					address {
						addressString
					}
				}
			}
			registries {
				id
				regNo
				type
			}
		}
	}
`;

export const REGISTRIES_SEARCH_QUERY = gql`
	query SearchRegistriesQuery(
		$where: RegistryWhereInput
		$pagination: PaginationInput
		$orderBy: RegistryOrderByInput
	) {
		registries(where: $where, pagination: $pagination, orderBy: $orderBy) {
			count
			registries(where: $where, pagination: $pagination, orderBy: $orderBy) {
				id
				regNo
				type
				able
				note
				conditions
				consultDate
				doc {
					id
					employee {
						id
						lastName
						firstName
					}
					job {
						job
						company {
							name
						}
					}
				}
				createdAt
			}
		}
	}
`;

export const DOC_UNIQUE_NOTES = gql`
	query DocumentUniqueNotes($type: DocType!) {
		documentNotes(type: $type)
	}
`;

export const DELETE_DOCUMENTS_QUERY = gql`
	mutation DeleteDocuments($data: [ID!]!) {
		deleteDocuments(data: $data)
	}
`;
