export const generatePdf = (fileName, data) => {
    const type = data.document.type;
    if (type === "FAPT")
        return fetch(`/api/generate_fapt`, {
            method: "POST",
            body: JSON.stringify({...data, fileName }),
        });
    if (type === "FAUT")
        return fetch(`/api/generate_faut`, {
            method: "POST",
            body: JSON.stringify({...data, fileName }),
        });
    if (type === "DMED")
        return fetch(`/api/generate_dmed`, {
            method: "POST",
            body: JSON.stringify({...data, fileName }),
        });
    return null;
};

export const downloadFile = (name, data) => {
    var a = document.createElement("a");
    a.href = data;
    a.download = name;
    a.click();
};

export const exportData = (type, idComp, idCust, state, start, end) => {
        if (type === "CUST")
            return fetch(`/api/export_customers?s=1${idComp ? `&id=${idComp}` : ""}`, {
			method: "POST",
		});
	if (type === "COMP")
		return fetch(`/api/export_companies?s=2`, {
			method: "POST",
		});
	if (type === "DOCS")
		return fetch(`/api/export_docs?s=3${idComp ? `&id=${idComp}` : ""}${idCust ? `&id2=${idCust}` : ""}`, {
			method: "POST",
		});
	if (type === "REGS")
		return fetch(`/api/export_regs?s=4${start && `&start=${start}`}${end && `&end=${end}`}`, {
			method: "POST",
		});
	return null;
};