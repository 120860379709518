import React, { useEffect } from "react";
import { Grid, TextField, InputAdornment, Checkbox, FormControlLabel } from "@material-ui/core";
import { PAID_AMOUNT, PAID_ENTIRELY, INVOICE_NO } from "../../../../../../utils/strings";

const InvoiceForm = (props) => {
	const { register, data, setValue, language, invoicePaid, setInvoicePaid, doc } = props;

	const handleCheckbox = () => {
		setValue("paid", !invoicePaid);
		setInvoicePaid(!invoicePaid);
	};

	useEffect(() => {
		register("paid");
		if (data && data?.paid !== undefined) {
			setValue("paid", data.paid);
			setInvoicePaid(data.paid);
		}
		if (data && data?.amount !== undefined) setValue("amount", data.amount);
		if (data && data?.invoiceNo !== undefined) setValue("invoiceNo", data.invoiceNo);
	}, [data]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				{/* <Checkbox checked={invoicePaid} color="primary"  /> */}
				<FormControlLabel
					control={<Checkbox checked={invoicePaid} color="primary" onChange={handleCheckbox} />}
					label={PAID_ENTIRELY[language]}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField fullWidth variant="outlined" name="invoiceNo" inputRef={register} label={INVOICE_NO[language]} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					fullWidth
					variant="outlined"
					name="amount"
					type="number"
					inputRef={register}
					label={PAID_AMOUNT[language]}
					InputProps={{
						endAdornment: <InputAdornment position="end">RON</InputAdornment>,
					}}
				/>
			</Grid>
		</Grid>
	);
};

export { InvoiceForm as default };
