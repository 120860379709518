import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { ME_QUERY } from "../requests";
import { useLazyQuery } from "@apollo/react-hooks";
import browserHistory from "./history";

const RouteWithLayout = (props) => {
	const { layout: Layout, component: Component, ...rest } = props;

	const { auth } = props;
	const {
		location: { pathname },
	} = browserHistory;
	const [me, { data, loading, error, refetch }] = useLazyQuery(ME_QUERY, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (pathname !== "/sign-in" && auth.isAuthenticated) me();
		console.log(data);
	}, []);

	useEffect(() => {
		if (error) auth.logout();
	}, [data, error]);

	return (
		<Route
			{...rest}
			render={(matchProps) => (
				<Layout {...rest} me={{ ...data?.me, loading }}>
					<Component {...rest} me={{ ...data?.me, loading, refetch }} />
				</Layout>
			)}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: PropTypes.any.isRequired,
	layout: PropTypes.any.isRequired,
	path: PropTypes.string,
};

export default RouteWithLayout;
