import React from "react";
import PropTypes from "prop-types";
import { CustomMenu } from "../../../../components";
import { AddBox, MoreVert, Refresh } from "@material-ui/icons";
import { IconButton, Typography, CardHeader, Divider } from "@material-ui/core";
import * as lang from "../../../../utils/strings";

const UsersMenu = (props) => {
	const { language, classes, onOpen } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	return (
		<React.Fragment>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.ADD_USER[language],
						icon: <AddBox className={classes.leftButton} />,
						action: onOpen,
					},
				]}
			/>
			<CardHeader
				title={
					<div className={classes.tableExtraHeader}>
						<IconButton
							className={classes.leftButton}
							onClick={(e) => setAnchorEl(e.currentTarget)}
							size="small"
						>
							<MoreVert fontSize="small" />
						</IconButton>
						<Typography variant="h4" className={classes.tableTitle}>
							{lang.USERS[language]}
						</Typography>
					</div>
				}
			/>
		</React.Fragment>
	);
};

UsersMenu.propTypes = {
	language: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
};

export default UsersMenu;
