import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as lang from "../../../../../../utils/strings";
import getError from "../../../../../../utils/getError";
import { DOC_UNIQUE_NOTES } from "../../../../../../requests";
import { useQuery } from "@apollo/react-hooks";
import { VirtualizedSelect } from "../../../../../../components";

const results = [
	{
		value: 1,
		name: "Apt",
	},
	{
		value: 2,
		name: "Apt condiționat",
	},
	{
		value: 3,
		name: "Inapt temporar",
	},
	{
		value: 4,
		name: "Inapt",
	},
];

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const ResultTab = (props) => {
	const classes = useStyles();
	const { language, register, setValue, unregister, data, errors, clearError, expanded, docType } = props;

	const [result, setResult] = useState(null);
	const [resultAuto, setResultAuto] = useState(null);

	const handleResultSelect = (event) => {
		setValue(`${docType}.result`, event.target.value, true);
		setResult(event.target.value);
	};

	const { data: docNotes } = useQuery(DOC_UNIQUE_NOTES, {
		variables: {
			type: data.type,
		},
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (expanded === "DONE") {
			clearError();
			register({ name: `${docType}.result` }, { required: true });
			register({ name: "type" });
		}
		unregister("registries");
		unregister("doc");
	}, [register, expanded]);

	useEffect(() => {
		if (data) {
			setValue("FAPT", data.FAPT);
			setValue("FAUT", data.FAUT);
			setValue("DMED", data.DMED);
			setValue("type", data.type);
		}
	}, [data, setValue]);

	return (
		<React.Fragment>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					required
					select
					label={lang.RESULT[language]}
					name={`${docType}.result`}
					onChange={handleResultSelect}
					variant="outlined"
					value={result ? result : ""}
					error={errors[docType] && errors[docType]?.result}
					helperText={
						!!errors[docType] && !!errors[docType]?.result ? getError(errors[docType].result, language) : null
					}
				>
					{results
						.filter((item) => (docType === "FAUT" ? [1, 4].includes(item.value) : true))
						.map((item) => (
							<li className={classes.listItem} key={item.value} value={item.value}>
								<Typography variant="body1">{item.name}</Typography>
							</li>
						))}
				</TextField>
			</Grid>
			{docType !== "FAUT" && (
				<Grid item xs={12} sm={6}>
					<VirtualizedSelect
						freeSolo={true}
						language={language}
						options={docNotes?.documentNotes ? docNotes.documentNotes : []}
						textFieldProps={{
							required: true,
							multiline: true,
							name: `${docType}.note`,
							inputRef: register,
							label: docType === "FAPT" ? lang.NOTE[language] : lang.DIAGNOSTIC[language],
							error: errors[docType] && errors[docType]?.note,
							helperText:
								!!errors[docType] && !!errors[docType]?.note ? getError(errors[docType].note, language) : null,
						}}
					/>
				</Grid>
			)}
		</React.Fragment>
	);
};

export { ResultTab as default };
