import React, { useState } from "react";
import * as lang from "../../../../utils/strings";
import { TextField, InputAdornment, Grid, IconButton, Typography, ListSubheader, Divider } from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";
import { CustomModal, VirtualizedSelect } from "../../../../components";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { COMPANY_SEARCH_QUERY, CUSTOMER_SEARCH_QUERY } from "../../../../requests";
import { CompaniesTable } from "../../../../Views/Companies/components";
import { AddressForm, CompanyForm, CustomerForm } from "../../..";
import getError from "../../../../utils/getError";
import moment from "moment";

const CustomerSearch = (props) => {
	const {
		language,
		setValue,
		register,
		unregister,
		errors,
		includeCompany,
		exists,
		setExists,
		clearError,
		noJobs,
		custOnly,
	} = props;
	const [searchQuery, setSearchQuery] = useState("");
	const [jobs, setJobs] = useState([]);
	const [jobExists, setJobExists] = useState(false);

	const { data } = useQuery(CUSTOMER_SEARCH_QUERY, {
		variables: { query: null, orderBy: "lastName_ASC" },
		fetchPolicy: "no-cache",
	});

	const handleSearchChange = (event, value, reason) => {
		if (reason === "select-option") {
			setSearchQuery(value.name);
			setJobs(value.companies);
			setValue("job", "");
			register({ name: "customer.id" }, { required: true });
			clearError("customer.id");
			unregister("customer.residence.region");
			unregister("customer.residence.city");
			unregister("customer.birthPlace.region");
			unregister("customer.birthPlace.city");
			unregister("customer.gender");
			unregister("customer.birthDate");
			setValue("customer", { id: value.id });
		}
		if (["blur", "clear", "remove-option"].includes(reason)) {
			setSearchQuery("");
			setValue("customer.id", "");
			setValue("job", "");
			setJobs([]);
		}
	};

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Typography
					variant="caption"
					color="secondary"
					style={{ cursor: "pointer" }}
					onClick={() => {
						if (exists) {
							unregister("customer.id");
						} else register({ name: "customer.id" }, { required: true });
						setExists(!exists);
						clearError("customer.id");
					}}
					gutterBottom
					align="center"
				>
					{exists ? "Client nou?" : "Client existent?"}
				</Typography>
			</Grid>
			{exists && (
				<Grid item xs={12}>
					<VirtualizedSelect
						// freeSolo
						textValue
						autoComplete={false}
						autoHighlight={false}
						value={searchQuery}
						language={language}
						options={data?.customers?.customers ? data.customers.customers : []}
						onChange={handleSearchChange}
						textFieldProps={{
							label: lang.PATIENT[language],
							InputProps: {
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							},
							placeholder: lang.SEARCH_EXISTING_PATIENT[language],
							error: !!errors?.customer?.id,
							helperText: !!errors?.customer?.id ? getError(errors.customer.id, language) : null,
						}}
						renderOption={(option) => (
							<Typography noWrap>{`${option.lastName} ${option.firstName}, n. ${moment(option.birthDate).format(
								"DD-MM-YYYY"
							)}`}</Typography>
						)}
						getOptionLabel={(option) => (option ? `${option.lastName} ${option.firstName}` : "")}
						renderGroup={(params) => [
							<ListSubheader key={params.key} component="div">
								{params.group}
							</ListSubheader>,
							params.children,
						]}
						groupBy={(option) => option.lastName[0]}
					/>
				</Grid>
			)}
			<CustomerForm
				{...props}
				custExists={exists}
				jobs={jobs}
				// jobExists={jobExists}
				jobExists={noJobs ? false : jobExists}
				noJobs={noJobs ? true : false}
				setJobExists={setJobExists}
				selectedCust={searchQuery}
				custOnly={custOnly}
			/>
		</React.Fragment>
	);
};

export { CustomerSearch as default };
