import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Hidden,
	Grow,
	useMediaQuery,
	Fade,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		backgroundColor: theme.palette.secondary.transparent(0.2),
		boxShadow: "0 0 0",
		padding: theme.spacing(2),
		position: "relative",
		overflow: "visible",
	},
	content: {
		opacity: 1,
	},
	title: {
		fontWeight: 700,
		color: theme.palette.secondary.main,
	},
	avatar: {
		position: "absolute",
		width: 150,
		height: 150,
		right: 0,
		bottom: 0,
	},
}));

const WelcomeMessage = (props) => {
	const { className, me, language, ...rest } = props;
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));

	const classes = useStyles();

	return (
		<Fade in={true}>
			<Card className={clsx(classes.root, className)}>
				<Hidden xsDown>
					<img
						src={
							me?.avatar
								? `/images/avatars/avatar-default-0${me?.avatar}.svg`
								: ""
						}
						className={classes.avatar}
					/>
				</Hidden>
				<CardContent className={classes.content}>
					<Grid container justify="space-between" spacing={0}>
						<Grid item>
							<Typography
								className={classes.title}
								// gutterBottom
								variant={tiny ? "h3" : "h1"}
							>
								{lang.WELCOME(me?.profile?.firstName)[language]}
							</Typography>
							{/* <Typography variant="h4">No news for you</Typography> */}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Fade>
	);
};

WelcomeMessage.propTypes = {
	className: PropTypes.string,
};

export default WelcomeMessage;
