import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import * as lang from "../../../../utils/strings";
import { CustomModal } from "../../../../components";
import { CREATE_CUSTOMER_COMPANY_QUERY } from "../../../../requests";
import { useMutation } from "@apollo/react-hooks";
import { CustomerForm } from "../../../../Forms";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { CustomerSearch } from "../../../../Forms/CustomerForm/components";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		display: "flex",
		justifyContent: "center",
	},
	button: {
		margin: theme.spacing(1),
	},
	icon: {
		height: 32,
		width: 32,
	},
	iconLeft: {
		marginRight: theme.spacing(1),
	},
	difference: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		alignItems: "flex-start",
		justifyItems: "flex-start",
	},
}));

const CompanyActions = (props) => {
	const { className, language, companyId, refetch, addModal, setAddModal } = props;
	const { enqueueSnackbar } = useSnackbar();
	const [exists, setExists] = React.useState(false);

	const { register, handleSubmit, errors, setValue, getValues, reset, unregister, clearError } = useForm({
		defaultValues: {
			customer: { gender: "MALE" },
		},
	});

	const [createCustomer] = useMutation(CREATE_CUSTOMER_COMPANY_QUERY);

	const handleCustomerCreateSubmit = (data) => {
		return {
			job: data.job,
			employee: {
				...data.customer,
			},
			company: {
				id: companyId,
			},
		};
	};

	useEffect(() => {
		reset({ customer: { gender: "MALE" } });
	}, [addModal, reset]);

	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<CustomModal
				language={language}
				title={lang.ADD_EMPLOYEE[language]}
				open={addModal}
				onClose={() => setAddModal(false)}
				onExecute={(data) => {
					createCustomer({
						variables: { data: handleCustomerCreateSubmit(data) },
					})
						.then((response) => {
							enqueueSnackbar(lang.CUSTOMER_CREATE_SUCCESS[language], {
								variant: "success",
							});
							refetch();
							setAddModal(false);
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				content={
					addModal && (
						<Grid container spacing={2}>
							<CustomerSearch
								standalone
								noJobs={true}
								language={language}
								register={register}
								unregister={unregister}
								setValue={setValue}
								getValues={getValues}
								errors={errors}
								exists={exists}
								setExists={setExists}
								clearError={clearError}
							/>
						</Grid>
					)
				}
				handleSubmit={handleSubmit}
				nonDismissible
				form
				fullWidth
			/>

			<div className={classes.difference}>
				<Button variant="contained" color="primary" className={classes.button} onClick={() => setAddModal(true)}>
					<Add className={classes.iconLeft} />
					{lang.ADD_EMPLOYEE[language]}
				</Button>
				{/* <ButtonGroup
					variant="outlined"
					color="primary"
					className={classes.button}
				>
					<Button>One</Button>
					<Button>Two</Button>
				</ButtonGroup>
				<ButtonGroup
					variant="outlined"
					color="primary"
					className={classes.button}
				>
					<Button>One</Button>
					<Button>Two</Button>
					<Button>Three</Button>
				</ButtonGroup> */}
			</div>
		</div>
	);
};

CompanyActions.propTypes = {
	className: PropTypes.string,
};

export default CompanyActions;
