import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
	Checkbox,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CustomersMenu } from "..";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableExtraHeaderSelect: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const CustomersTable = (props) => {
	const {
		className,
		data,
		index,
		loading,
		fetchData,
		count,
		called,
		language,
		onOpen,
		onExport,
		onRowClick,
		custom,
		employees,
		calledList,
	} = props;

	const classes = useStyles();

	const [selectedRows, setSelectedRows] = useState([]);
	const [selection, setSelection] = useState(false);
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	const handleSelectAll = (event) => {
		let selectedRows;

		if (event.target.checked) {
			selectedRows = index;
		} else {
			selectedRows = [];
		}

		setSelectedRows(selectedRows);
	};
	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedRows.indexOf(id);
		let newSelectedRows = [];

		if (selectedIndex === -1) {
			newSelectedRows = newSelectedRows.concat(selectedRows, id);
		} else if (selectedIndex === 0) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
		} else if (selectedIndex === selectedRows.length - 1) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedRows = newSelectedRows.concat(
				selectedRows.slice(0, selectedIndex),
				selectedRows.slice(selectedIndex + 1)
			);
		}

		setSelectedRows(newSelectedRows);
	};

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0);
		setRowsPerPage(value);
	};

	useEffect(() => {
		fetchData(rowsPerPage, 0);
	}, [called]);

	return (
		<Card className={clsx(classes.root, className)}>
			{custom && (
				<>
					<CustomersMenu
						language={language}
						classes={classes}
						onOpen={onOpen}
						onExport={onExport}
						employees={employees}
						selection={selection}
						selected={selectedRows}
						onSelection={() => {
							setSelection(!selection);
							setSelectedRows([]);
						}}
					/>
					<Divider />
				</>
			)}
			{!calledList ? (
				<LinearProgress />
			) : (
				<>
					<CardContent className={classes.content}>
						<PerfectScrollbar>
							<div className={classes.inner}>
								<Table>
									{loading ? (
										<TableHead>
											<TableRow>
												<TableCell> </TableCell>
											</TableRow>
										</TableHead>
									) : (
										<TableHead>
											<TableRow>
												{selection && (
													<TableCell padding="checkbox">
														<Checkbox
															checked={
																selectedRows.length ===
																count
															}
															color="primary"
															indeterminate={
																selectedRows.length >
																	0 &&
																selectedRows.length <
																	count
															}
															onChange={
																handleSelectAll
															}
														/>
													</TableCell>
												)}
												<TableCell>
													{lang.FULL_NAME[language]}
												</TableCell>
												<TableCell>
													{lang.PHONE[language]}
												</TableCell>
												<TableCell>
													{lang.GENDER[language]}
												</TableCell>
												<TableCell>
													{lang.CNP[language]}
												</TableCell>
												<TableCell>
													{lang.CREATE_DATE[language]}
												</TableCell>
											</TableRow>
										</TableHead>
									)}
									{loading ? (
										<TableBody>
											<TableRow>
												<TableCell>
													<LinearProgress color="secondary" />
												</TableCell>
											</TableRow>
										</TableBody>
									) : (
										<TableBody>
											{data.map((item) => (
												<TableRow
													onClick={(event) => {
														selection
															? handleSelectOne(
																	event,
																	item.id
															  )
															: onRowClick(item);
													}}
													className={classes.tableRow}
													hover
													key={item.id}
													selected={
														selectedRows.indexOf(
															item.id
														) !== -1
													}
												>
													{selection && (
														<TableCell padding="checkbox">
															<Checkbox
																checked={
																	selectedRows.indexOf(
																		item.id
																	) !== -1
																}
																color="primary"
																onChange={(
																	event
																) =>
																	handleSelectOne(
																		event,
																		item.id
																	)
																}
																value="true"
															/>
														</TableCell>
													)}
													<TableCell>
														<Typography variant="body1">
															{`${item.lastName} ${item.firstName}`}
														</Typography>
													</TableCell>
													<TableCell>
														{item.phone}
													</TableCell>
													<TableCell>
														{item.gender[0]}
													</TableCell>
													<TableCell>
														{item.ci?.cnp}
													</TableCell>
													<TableCell>
														{moment(
															item.createdAt
														).format("DD/MM/YYYY")}
													</TableCell>
												</TableRow>
											))}

											{rowsPerPage - data.length > 0 && (
												<TableRow
													style={{
														height:
															53 *
															(rowsPerPage -
																data.length),
													}}
												>
													<TableCell colSpan={6} />
												</TableRow>
											)}
										</TableBody>
									)}
								</Table>
							</div>
						</PerfectScrollbar>
					</CardContent>
					<CardActions className={classes.actions}>
						<Pagination
							count={count}
							onChangePage={handlePageChange}
							page={count ? page : 0}
							rowsPerPage={parseInt(rowsPerPage, 10)}
							onChangeRowsPerPage={handleRowsPerPageChange}
						/>
					</CardActions>
				</>
			)}
		</Card>
	);
};

function Pagination(props) {
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
		/>
	);
}

CustomersTable.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default CustomersTable;
