import React from "react";
import PropTypes from "prop-types";
import {
	AddBox,
	MoreVert,
	CheckBoxRounded,
	Clear,
	DeleteForever,
	ExpandMore,
	Filter,
	FilterList,
	Save,
} from "@material-ui/icons";
import { IconButton, Typography, CardHeader, Divider, Switch, FormControlLabel } from "@material-ui/core";
import * as lang from "../../../../../../utils/strings";
import * as langDoc from "../../../../../../utils/strings/docs";
import { CustomMenu, CustomModal } from "../../../../../../components";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_COMPANY_EMPLOYEES_QUERY, DELETE_DOCUMENTS_QUERY } from "../../../../../../requests";
import { useForm } from "react-hook-form";
import { DocFilters } from "..";
import moment from "moment";

const states = ["OPEN", "DONE", "DELIVERED", "CLOSED"];
// const paid_states = ["NOT_PAID", "PARTIAL_PAYMENT", "PAID_ENTIRELY"];

const translate = (term, array, language) => {
	for (let i = 0; i < array.length; i++) if (langDoc[array[i]][language] === term) return array[i];
	// return array.forEach((item) => lang[item][language] === term && item);
};

const LatestOrdersMenu = (props) => {
	const { language, classes, onOpen, onExport, onSelection, selection, selected, refetch, count, filter } = props;
	const { fetchData, docFilters, setDocFilters } = props;
	const [filterModal, setFilterModal] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const [deleteEmployees] = useMutation(DELETE_DOCUMENTS_QUERY);

	let today = new Date();
	let tomorrow = new Date(today);
	tomorrow.setDate(today.getDate() + 1);
	tomorrow = tomorrow.toISOString().split("T")[0];
	today = today.toISOString().split("T")[0];

	const { register, handleSubmit, errors, clearError, setValue, getValues, reset, unregister } = useForm({
		defaultValues: {
			state: "",
			// paid: "",
			createDate_gte: moment().subtract(2, "year").startOf("day"),
			createDate_lt: moment().endOf("day"),
		},
	});

	React.useEffect(() => {
		register("createDate_gte");
		register("createDate_lt");
		let filters = {};
		docFilters.forEach((item) => (filters = { ...filters, ...item }));
		console.log(filters);
		setValue("state", filters.state ? filters.state : "");
		setValue("createDate_gte", filters.createDate_gte);
		setValue("createDate_lt", filters.createDate_lt);
	}, [docFilters]);

	return (
		<React.Fragment>
			<CustomModal
				fullWidth
				open={filterModal}
				title={lang.FILTERS[language]}
				content={
					<DocFilters
						language={language}
						register={register}
						unregister={unregister}
						errors={errors}
						setValue={setValue}
					/>
				}
				language={language}
				onClose={() => setFilterModal(false)}
				onExecute={(data) => {
					let filters = [];
					Object.keys(data)
						.filter((key) => data[key] !== "")
						.forEach(
							(key) =>
								("state" === key && filters.push({ [key]: translate(data[key], states, language) })) ||
								// ("paid" === key && filters.push({ [key]: translate(data[key], paid_states, language) })) ||
								(["paid", "state"].indexOf(key) === -1 && filters.push({ [key]: data[key] }))
						);

					setDocFilters(filters);
					setFilterModal(false);
				}}
				handleSubmit={handleSubmit}
				form
			/>
			<CustomModal
				open={openModal}
				title={lang.DELETE_EMPLOYEES[language]}
				content={lang.CONFIRM_DELETE_DOCUMENTS(selected.length)[language]}
				language={language}
				onClose={() => setOpenModal(false)}
				onExecute={() => {
					deleteEmployees({
						variables: {
							data: selected,
						},
					})
						.then((response) => {
							enqueueSnackbar(lang.DOCUMENTS_DELETED_SUCCESS[language], {
								variant: "success",
							});
							refetch();
							onSelection();
							setOpenModal(false);
						})
						.catch((error) => {
							onSelection();
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
							// setButtonStatus("error");
						});
				}}
			/>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.EXPORT[language],
						icon: <Save className={classes.leftButton} />,
						render: !selection,
						action: onExport,
					},
					{
						title: lang.SELECT[language],
						icon: <CheckBoxRounded className={classes.leftButton} />,
						render: !selection,
						disabled: count === 0,
						action: onSelection,
					},
					{
						title: lang.DELETE[language],
						icon: <DeleteForever className={classes.leftButton} />,
						render: selection,
						disabled: selected.length === 0,
						action: () => setOpenModal(true),
					},
				]}
			/>
			<CardHeader
				title={
					<div className={classes.tableExtraHeaderSelect}>
						<div className={classes.tableExtraHeader}>
							{selection ? (
								<IconButton className={classes.leftButton} onClick={onSelection} size="small">
									<Clear fontSize="small" />
								</IconButton>
							) : (
								<IconButton className={classes.leftButton} onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
									<MoreVert fontSize="small" />
								</IconButton>
							)}
							<Typography variant="h4" className={classes.tableTitle}>
								{lang.ENTRY_REGISTRY[language]}
							</Typography>
						</div>

						<div className={classes.tableExtraHeader}>
							{selection ? (
								<>
									<Typography variant="body1" color="textSecondary" className={classes.leftButton}>
										{`${selected?.length} items selected`}
									</Typography>
									<IconButton
										onClick={(e) => setAnchorEl(e.currentTarget)}
										size="small"
										// disabled={selected.length === 0}
									>
										<ExpandMore fontSize="small" />
									</IconButton>
								</>
							) : (
								<IconButton onClick={() => setFilterModal(true)}>
									<FilterList />
								</IconButton>
							)}
						</div>
					</div>
				}
			/>
		</React.Fragment>
	);
};

LatestOrdersMenu.propTypes = {
	language: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
};

export { LatestOrdersMenu as default };
