import React from "react";
import { Divider, Grid, TextField, MenuItem } from "@material-ui/core";
import * as lang from "../../../../../../utils/strings";
import { CustomerForm } from "../../../../../../Forms";
import { CustomerSearch } from "../../../../../../Forms/CustomerForm/components";
import getError from "../../../../../../utils/getError";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";

const types = ["DMED", "FAPT", "FAUT"];
const reasons = [
	{
		value: 1,
		name: "Angajare",
	},
	{
		value: 2,
		name: "Reluarea muncii",
	},
	{
		value: 3,
		name: "Examen periodic",
	},
	{
		value: 4,
		name: "Supraveghere specială",
	},
	{
		value: 5,
		name: "Adaptare",
	},
	{
		value: 6,
		name: "Alte",
	},
];

const OptionFAPT = (props) => {
	const { language, docAux, setDocAux, setValue, register, errors, docType } = props;

	const handleChange = (e) => {
		const { value } = e.target;
		setValue("docAux", value);
		setDocAux(value);
	};

	React.useEffect(() => {
		register({ name: "docAux" }, { required: true });
		setValue("docAux", 1);
	}, [docType]);

	return (
		<Grid item xs={12} sm={6}>
			<TextField
				fullWidth
				select
				label={lang.REASON[language]}
				name="docAux"
				onChange={handleChange}
				value={docAux}
				variant="outlined"
				error={!!errors?.docAux}
				helperText={!!errors?.docAux ? getError(errors.docAux, language) : null}
			>
				{reasons.map((item) => (
					<MenuItem key={item.value} value={item.value}>
						{item.name}
					</MenuItem>
				))}
			</TextField>
		</Grid>
	);
};
const OptionFAUT = (props) => {
	const { language, docAux, setDocAux, setValue, register, errors } = props;

	const handleChange = (e) => {
		const { value } = e.target;
		setValue("docAux", value);
		setDocAux(value);
	};

	React.useEffect(() => {
		register({ name: "docAux" }, { required: true });
		setValue("docAux", 1);
	}, []);

	return (
		<Grid item xs={12} sm={6}>
			<TextField
				fullWidth
				select
				label={lang.AUTO_GROUP[language]}
				name="docAux"
				onChange={handleChange}
				value={docAux}
				variant="outlined"
				error={!!errors?.docAux}
				helperText={!!errors?.docAux ? getError(errors.docAux, language) : null}
			>
				<MenuItem value={1}>Grupa I</MenuItem>
				<MenuItem value={2}>Grupa II</MenuItem>
				<MenuItem value={3}>Grupa I & Grupa II</MenuItem>
			</TextField>
		</Grid>
	);
};
const DateSelect = (props) => {
	const { language, today, setValue, register, errors, clearError } = props;
	const [docDate, setDocDate] = React.useState(today);

	const handleChange = (value) => {
		const date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("docDate", date);
			clearError("docDate");
			setDocDate(date);
		}
	};

	React.useEffect(() => {
		register({ name: "docDate" }, { required: true });
		setValue("docAux", today);
	}, []);

	return (
		<Grid item xs={12} sm={6}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
				<KeyboardDatePicker
					autoOk
					variant="inline"
					required={true}
					inputVariant="outlined"
					fullWidth
					name="docDate"
					label={lang.CREATE_DATE[language]}
					format="dd/MM/yyyy"
					onChange={handleChange}
					value={docDate}
					error={!!errors?.docDate}
					helperText={!!errors?.docDate ? getError(errors.docDate, language) : null}
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	);
};

const NextDateSelect = (props) => {
	const { language, setValue, register, errors, clearError } = props;
	const [nextDate, setNextDate] = React.useState(null);

	const handleChange = (value) => {
		const date = value;
		try {
			date = new Date(new Date(value) - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
			// new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]
		} catch (e) {
			console.log(e);
		} finally {
			setValue("nextDate", date);
			clearError("nextDate");
			setNextDate(date);
		}
	};

	React.useEffect(() => {
		register({ name: "nextDate" });
	}, []);

	return (
		<Grid item xs={12} sm={6}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
				<KeyboardDatePicker
					autoOk
					variant="inline"
					required={true}
					inputVariant="outlined"
					fullWidth
					name="nextDate"
					label={lang.NEXT_DATE[language]}
					format="dd/MM/yyyy"
					onChange={handleChange}
					value={nextDate}
					error={!!errors?.nextDate}
					helperText={!!errors?.nextDate ? getError(errors.nextDate, language) : null}
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	);
};

const DocTypeSelect = (props) => {
	const { language, docType, setDocType, docAux, setDocAux, register, unregister, clearError } = props;
	const { exists, setExists, setValue } = props;

	const handleChange = (e) => {
		const { value } = e.target;
		setValue("docType", value);
		setDocType(value);
		unregister("docAux");
		unregister("nextDate");
		if (value === "FAUT") {
			unregister("company.address.region");
			unregister("company.address.city");
		}
		setDocAux(1);
	};

	React.useEffect(() => {
		clearError();
		setDocType("DMED");
	}, []);

	return (
		<Grid container spacing={2}>
			<DateSelect {...props} />
			{docType === "FAPT" ? <NextDateSelect {...props} /> : <Grid item xs={"auto"} sm={6} />}
			<Grid item xs={12} sm={6}>
				<TextField
					fullWidth
					select
					label={lang.TYPE[language]}
					name="docType"
					onChange={handleChange}
					onClick={() => console.log(props.errors)}
					value={docType}
					variant="outlined"
				>
					{types.map((item, index) => (
						<MenuItem key={index} value={item}>
							{lang[item][language]}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			{docType === "FAPT" && <OptionFAPT {...props} />}
			{docType === "FAUT" && <OptionFAUT {...props} />}
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<CustomerSearch {...props} includeCompany />
		</Grid>
	);
};

export { DocTypeSelect as default };
