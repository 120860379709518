import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { useQuery } from "@apollo/react-hooks";
import { USERS_COUNT_QUERY } from "../../../../requests";

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
	},
	content: {
		alignItems: "center",
		display: "flex",
	},
	title: {
		fontWeight: 700,
	},
	avatar: {
		backgroundColor: theme.palette.success.main,
		height: 56,
		width: 56,
	},
	icon: {
		height: 32,
		width: 32,
	},
	difference: {
		marginTop: theme.spacing(2),
		display: "flex",
		alignItems: "center",
	},
	differenceIcon: {
		color: theme.palette.success.dark,
	},
	differenceValue: {
		color: theme.palette.success.dark,
		marginRight: theme.spacing(1),
	},
}));

const TotalUsers = (props) => {
	const { className, ...rest } = props;

	const { data } = useQuery(USERS_COUNT_QUERY, {
		variables: { where: {} },
	});

	const classes = useStyles();

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<CardContent>
				<Grid container justify="space-between">
					<Grid item>
						<Typography
							className={classes.title}
							color="textSecondary"
							gutterBottom
							variant="body2"
						>
							TOTAL UTILIZATORI
						</Typography>
						<Typography variant="h3">
							{data?.users?.count}
						</Typography>
					</Grid>
					<Grid item>
						<Avatar className={classes.avatar}>
							<PeopleIcon className={classes.icon} />
						</Avatar>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

TotalUsers.propTypes = {
	className: PropTypes.string,
};

export default TotalUsers;
