import React from "react";
import { Grid, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	avatar: {
		// marginLeft: "auto",
		height: 90,
		width: 90,
		// flexShrink: 0,
		// flexGrow: 0,
		transition: "0.3s",
		cursor: "pointer",
		"&:hover": {
			opacity: 0.7,
			transition: "0.3s",
		},
	},
	selected: {
		transition: "0.3s",
		border: `2px solid ${theme.palette.secondary.main}`,
		cursor: "pointer",
	},
	wrapper: {
		display: "flex",
		justifyContent: "center",
	},
}));

const avatars = [
	{ id: 1, name: "avatar-01.svg" },
	{ id: 2, name: "avatar-02.svg" },
	{ id: 3, name: "avatar-03.svg" },
	{ id: 4, name: "avatar-04.svg" },
	{ id: 5, name: "avatar-05.svg" },
	{ id: 6, name: "avatar-06.svg" },
	{ id: 7, name: "avatar-07.svg" },
	{ id: 8, name: "avatar-08.svg" },
];

const AvatarSelect = (props) => {
	const classes = useStyles();
	const { selected, handleSelect } = props;

	return (
		<Grid container spacing={3}>
			{avatars.map((item) => (
				<Grid
					item
					xs={6}
					md={3}
					key={item.id}
					className={classes.wrapper}
				>
					<Avatar
						className={clsx(classes.avatar, {
							[classes.selected]: selected === item.id,
						})}
						src={`/images/avatars/${item.name}`}
						onClick={() => handleSelect(item.id)}
					/>
				</Grid>
			))}
		</Grid>
	);
};

AvatarSelect.propTypes = {
	selected: PropTypes.number.isRequired,
	handleSelect: PropTypes.func.isRequired,
};

export { AvatarSelect as default };
