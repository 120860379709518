import React, { useEffect, useState } from "react";
import { CssBaseline, Grid, LinearProgress, Grow, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CompanyActions, CompanyEmployees, CompanyDocs } from "./components";
import { useLazyQuery } from "@apollo/react-hooks";
import { COMPANY_QUERY, UPDATE_COMPANY_QUERY, CUSTOMER_SEARCH_QUERY, DOCUMENT_SEARCH_QUERY } from "../../requests";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/react-hooks";
import * as lang from "../../utils/strings";
import { CompanyDetails, CompanyProfile } from "./components";
import redirectTo from "../../utils/redirect";
import { exportData } from "../../utils/fetchFunctions";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
	space: {
		marginTop: theme.spacing(4),
	},
}));

const Company = (props) => {
	const { language } = props;
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [buttonStatus, setButtonStatus] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [invoiceModal, setInvoiceModal] = useState(false);

	const { register, handleSubmit, errors, setValue, getValues, unregister } = useForm();

	const [updateCompany] = useMutation(UPDATE_COMPANY_QUERY);

	const handleCompanyEdit = (newData) => {
		setButtonStatus("loading");
		updateCompany({
			variables: {
				id: data.company.id,
				data: { ...newData.company },
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.COMPANY_UPDATE_SUCCSESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
			})
			.then((response) => {
				refetch();
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const handleExportEmployees = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("CUST", data?.company?.id)
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const handleExportDocs = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("DOCS", data?.company?.id)
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const [getCompany, { data, loading, networkStatus, refetch }] = useLazyQuery(COMPANY_QUERY);

	const handleRowClick = (id) => redirectTo(`/customer/${id}`);

	const [
		getDocs,
		{ data: dataDocs, loading: loadingDocs, networkStatus: networkStatusDocs, refetch: refetchDocs },
	] = useLazyQuery(DOCUMENT_SEARCH_QUERY, {
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		const id = window.location.pathname.split("/")[2];
		getCompany({
			variables: {
				id,
			},
		});
	}, [getCompany]);

	useEffect(() => {
		if (data?.company?.address) {
			setValue("company.address", data.company.address);
		}
		if (data?.company) {
			setValue("company.name", data.company.name);
			setValue("company.cif", data.company.cif);
			setValue("company.phone", data.company.phone);
		}
	}, [data, setValue]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{loading ? (
				<LinearProgress />
			) : (
				<Grid container spacing={4} direction="row">
					<Grid item xs={12}>
						<CompanyActions
							{...props}
							addModal={addModal}
							setAddModal={setAddModal}
							refetch={refetch}
							companyId={data?.company?.id}
							className={classes.display}
						/>
					</Grid>
					<Grid item lg={4} md={6} xl={4} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={true}>
									<CompanyProfile {...props} data={data?.company} className={classes.display} />
								</Grow>
							</Grid>
							<Grid item className={classes.space}>
								<Grow in={true}>
									<CompanyDetails
										{...props}
										className={classes.display}
										data={data?.company}
										register={register}
										errors={errors}
										setValue={setValue}
										unregister={unregister}
										getValues={getValues}
										onExecute={handleCompanyEdit}
										handleSubmit={handleSubmit}
										buttonStatus={buttonStatus}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={8} md={6} xl={8} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item xs={12}>
								<Grow in={true}>
									<CompanyEmployees
										{...props}
										custom
										refetch={refetch}
										className={classes.display}
										data={data?.company?.employees !== undefined ? data.company.employees : []}
										loading={loading && !networkStatus === 4}
										onOpen={() => setAddModal(true)}
										onExport={handleExportEmployees}
										count={data?.company?.employees !== undefined ? data.company.employees.length : 0}
										onRowClick={handleRowClick}
										/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grow in={true}>
							<CompanyDocs
								{...props}
								custom
								companyId={data?.company?.id}
								fetchData={(rows, page, filters) =>
									data?.company?.id &&
									getDocs({
										variables: {
											where: {
												AND: filters,
											},
											pagination: {
												first: rows,
												skip: Math.max(page, 0) * rows,
											},
										},
									})
								}
								onExport={handleExportDocs}
								refetch={refetchDocs}
								loading={loadingDocs && !networkStatusDocs === 4}
								called={true}
								// onOpen={() => setAddModal(true)}
								data={dataDocs?.documents?.documents !== undefined ? dataDocs.documents.documents : []}
								count={dataDocs?.documents?.count !== undefined ? dataDocs.documents.count : 0}
								// onRowClick={() => setInvoiceModal(true)}
								className={classes.display}
								/>
						</Grow>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export { Company as default };
