import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, CssBaseline, Grow, LinearProgress } from "@material-ui/core";
import { UserProfile, UserDetails } from "../User/components";
import { UserPassword } from "../User/components";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/react-hooks";
import * as lang from "../../utils/strings";
import { USER_UPDATE_QUERY } from "../../requests";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	space: {
		marginTop: theme.spacing(4),
	},
	display: {
		minWidth: "250px",
	},
}));

const Account = (props) => {
	const classes = useStyles();
	const { language, me } = props;
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [role, setRole] = useState(null);
	const [buttonStatus, setButtonStatus] = useState(false);

	const {
		register,
		handleSubmit,
		errors,
		setValue,
		getValues,
		reset,
	} = useForm();

	const [updateUser] = useMutation(USER_UPDATE_QUERY);

	const handleUserEdit = (data) => {
		setButtonStatus("loading");
		updateUser({
			variables: {
				id: me.id,
				data,
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.USER_UPDATE_SUCCSESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
			})
			.then((response) => {
				me.refetch();
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
				setButtonStatus("error");
			});
	};

	const handleRoleChange = (event) => {
		setValue("role", event.target.value);
		setRole(event.target.value);
	};

	useEffect(() => {
		setValue("profile.firstName", me?.profile?.firstName);
		setValue("profile.lastName", me?.profile?.lastName);
		setValue("profile.email", me?.profile?.email);
		setValue("role", me?.role);
		setRole(me?.role);
	}, [me]);

	useEffect(() => {
		reset({});
	}, []);

	return (
		<div className={classes.root}>
			<CssBaseline />
			{me?.profile ? (
				<Grid container spacing={4} direction="row">
					<Grid item lg={4} md={6} xl={4} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={!!me?.profile}>
									<UserProfile
										{...props}
										user={me}
										className={classes.display}
									/>
								</Grow>
							</Grid>
						</Grid>
						<Grid
							container
							spacing={0}
							direction="column"
							className={classes.space}
						>
							<Grid item>
								<Grow in={!!me?.profile}>
									<UserPassword
										{...props}
										className={classes.display}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={8} md={6} xl={8} xs={12}>
						<Grid container spacing={0} direction="column">
							<Grid item>
								<Grow in={!!me?.profile}>
									<UserDetails
										{...props}
										className={classes.display}
										user={me}
										register={register}
										errors={errors}
										setValue={setValue}
										role={role}
										handleRoleChange={handleRoleChange}
										onExecute={handleUserEdit}
										handleSubmit={handleSubmit}
										getValues={getValues}
										buttonStatus={buttonStatus}
									/>
								</Grow>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<LinearProgress />
			)}
		</div>
	);
};

export default Account;
