import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
	Checkbox,
	Button,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CustomerDocsMenu } from "../../../Customer/components/CustomerDocs/components";
import { NoResults } from "../../../../components";
import { InvoicePayment, InvoiceForm } from "./components";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/react-hooks";
import { DOCUMENT_UPDATE_PAYMENT_QUERY } from "../../../../requests";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		// minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableExtraHeaderSelect: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const CompanyDocs = (props) => {
	const {
		className,
		data,
		loading,
		language,
		onOpen,
		onExport,
		onRowClick,
		custom,
		count,
		refetch,
		fetchData,
		called,
        companyId,
	} = props;

	const { register, handleSubmit, errors, setValue, getValues, reset, unregister } = useForm();
	const { enqueueSnackbar } = useSnackbar();

	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [docFilters, setDocFilters] = useState(null);
	const [invoiceModal, setInvoiceModal] = useState(false);
	const [invoicePaid, setInvoicePaid] = useState(false);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	const [updateDocument] = useMutation(DOCUMENT_UPDATE_PAYMENT_QUERY);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page, docFilters);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0, docFilters);
		setRowsPerPage(value);
	};
	const toggleFilter = (filter) => {
		let newFilter = filter
			? [{ job: { company: { id: companyId } } }, { state_in: ["OPEN", "DONE", "DELIVERED"] }]
			: [{ job: { company: { id: companyId } } }, { state: "CLOSED" }];
		console.log(newFilter);
		setDocFilters(newFilter);
		fetchData(rowsPerPage, 0, newFilter);
	};

	useEffect(() => {
		if (companyId) {
			setDocFilters([{ job: { company: { id: companyId } } }, { state_in: ["OPEN", "DONE", "DELIVERED"] }]);
			console.log("SALUT");
		}
	}, [companyId]);

	useEffect(() => {
		if (docFilters) {
			console.log(`docFilters = ${docFilters}`);
			fetchData(rowsPerPage, 0, docFilters);
		}
	}, [called, docFilters, rowsPerPage]);

	return (
		<Card className={clsx(classes.root, className)}>
			<InvoicePayment
				fullWidth
				title={lang.INVOICE_PAID[language]}
				open={invoiceModal !== false}
				language={language}
				onClose={() => setInvoiceModal(false)}
				content={
					<InvoiceForm
						language={language}
						register={register}
						errors={errors}
						setValue={setValue}
						invoicePaid={invoicePaid}
						setInvoicePaid={setInvoicePaid}
						data={invoiceModal}
					/>
				}
				onExecute={(data) => {
					console.log(data);
					updateDocument({
						variables: {
							id: invoiceModal.id,
							data,
						},
					})
						.then((response) => {
							enqueueSnackbar(lang.PAYMENT_UPDATE_SUCCESS[language], {
								variant: "success",
							});
                            setInvoiceModal(false);
                            refetch();
						})
						.catch((error) => {
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
						});
				}}
				handleSubmit={handleSubmit}
				form
			/>
			{custom && (
				<>
					<CustomerDocsMenu
						refetch={refetch}
						count={count}
						language={language}
						classes={classes}
						onOpen={onOpen}
						onExport={onExport}
						filter={docFilters?.length === 2 && docFilters[1].state !== undefined}
						switchAction={toggleFilter}
					/>
					<Divider />
				</>
			)}
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{!loading && data?.length > 0 && (
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>{lang.FULL_NAME[language]}</TableCell>
										<TableCell>{lang.JOB[language]}</TableCell>
										<TableCell>{lang.INVOICE_NO[language]}</TableCell>
										<TableCell>{lang.INVOICE_PAID[language]}</TableCell>
										<TableCell>{lang.TYPE[language]}</TableCell>
										<TableCell>{lang.STATE[language]}</TableCell>
										<TableCell>{lang.CREATE_DATE[language]}</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : data?.length === 0 ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<NoResults message={lang.NO_RESULTS[language]} />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.slice(0, rowsPerPage).map((item) => (
										<TableRow onClick={() => setInvoiceModal(item)} className={classes.tableRow} hover key={item.id}>
											<TableCell>
												<Typography variant="body1">{item?.id}</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1">
													{item?.employee?.firstName} {item?.employee?.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												{item?.job?.job === undefined ? "-" : `${item?.job?.job} @ ${item?.job?.company?.name}`}
											</TableCell>
											<TableCell>{item.invoiceNo ? item.invoiceNo : "-"}</TableCell>
											<TableCell>
												{item.paid === true
													? lang.PAID[language].toUpperCase()
													: item.amount > 0
													? `${lang.PARTIAL_PAYMENT[language].toUpperCase()} (${item.amount} RON)`
													: lang.NOT_PAID[language].toUpperCase()}
											</TableCell>
											<TableCell>{item?.type ? lang[item.type][language] : ""}</TableCell>
											<TableCell>{item?.state}</TableCell>
											<TableCell>{moment(item.createdAt).format("DD/MM/YYYY")}</TableCell>
										</TableRow>
									))}

									{page === Math.floor(count / rowsPerPage) && (
										<TableRow
											style={{
												height: 53 * (rowsPerPage - (count % rowsPerPage)),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
					language={language}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	// const { language, rowsPerPage, page, count } = props;
	// const from = page * rowsPerPage + 1;
	// const to = count % rowsPerPage === 0 ? rowsPerPage : count % rowsPerPage;
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
			// labelDisplayedRows={() =>
			// 	lang.TABLE_DISPLAY_ROWS(from, to, count)[language]
			// }
			// labelRowsPerPage={lang.TABLE_ROWS_PAGE[language]}
		/>
	);
}

CompanyDocs.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default CompanyDocs;
