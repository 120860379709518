import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, CssBaseline } from "@material-ui/core";
import { Configurations } from "./components";
import * as lang from "../../utils/strings";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
}));

const Settings = (props) => {
	const { language } = props;
	const classes = useStyles();

	useEffect(() => {
		document.title = `Pronto - ${lang.SETTINGS[language]}`;
	}, []);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Grid container spacing={4}>
				<Grid item md={7} xs={12}>
					<Configurations {...props} />
				</Grid>
				<Grid item md={5} xs={12}>
					{/* <Password /> */}
				</Grid>
			</Grid>
		</div>
	);
};

export default Settings;
