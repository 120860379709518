export const DASHBOARD = {
	ro: "Administrare",
	en: "Dashboard",
};
export const USERS = {
	ro: "Utilizatori",
	en: "Users",
};
export const CUSTOMERS = {
	ro: "Pacienți",
	en: "Patients",
};
export const REGISTRIES = {
	ro: "Registre",
	en: "Registries",
};
export const SETTINGS = {
	ro: "Setări",
	en: "Settings",
};