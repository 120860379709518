import React from "react";
import { Grid, Divider, Typography, Hidden, InputAdornment, ListSubheader } from "@material-ui/core";
import { VirtualizedSelect } from "../../../../../../components";
import * as lang from "../../../../../../utils/strings";
import * as langDoc from "../../../../../../utils/strings/docs";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";
import moment from "moment";
import { CUSTOMER_SEARCH_QUERY, COMPANY_SEARCH_QUERY } from "../../../../../../requests";
import { useQuery } from "@apollo/react-hooks";
import { Search } from "@material-ui/icons";
import getError from "../../../../../../utils/getError";

const states = ["OPEN", "DONE", "DELIVERED", "CLOSED"];
// const paid_states = ["NOT_PAID", "PARTIAL_PAYMENT", "PAID_ENTIRELY"];

const DocFilters = (props) => {
	const { register, unregister, language, setValue, errors } = props;
	const [createDateStart, setCreateDateStart] = React.useState(moment().subtract(2, "year").startOf("day"));
	const [createDateEnd, setCreateDateEnd] = React.useState(moment().endOf("day"));
	const [searchPatient, setSearchPatient] = React.useState("");
	const [searchCompany, setSearchCompany] = React.useState("");

	const { data } = useQuery(CUSTOMER_SEARCH_QUERY, {
		variables: { query: null, orderBy: "lastName_ASC" },
		fetchPolicy: "no-cache",
	});
	const { data: dataCo } = useQuery(COMPANY_SEARCH_QUERY, {
		variables: { query: null, orderBy: "name_ASC" },
		fetchPolicy: "no-cache",
	});

	const handleSearchPatient = (event, value, reason) => {
		if (reason === "select-option") {
			setSearchPatient(value.name);
			register({ name: "employee.id" });
			setValue("employee", { id: value.id });
		}
		if (["blur", "clear", "remove-option"].includes(reason)) {
			setSearchPatient("");
			unregister("employee.id");
		}
	};
	const handleSearchCompany = (event, value, reason) => {
		if (reason === "select-option") {
			setSearchCompany(value.name);
			register({ name: "job.company.id" });
			setValue("job.company", { id: value.id });
		}
		if (["blur", "clear", "remove-option"].includes(reason)) {
			setSearchCompany("");
			unregister("job.company.id");
		}
	};

	React.useEffect(() => {
		unregister("employee.id");
		unregister("job.company.id");
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					language={language}
					options={states}
					autoComplete={false}
					textFieldProps={{
						name: "state",
						inputRef: register,
						label: lang.STATE[language],
					}}
					renderOption={(option) => <Typography noWrap>{langDoc[option][language].toUpperCase()}</Typography>}
					getOptionLabel={(option) => langDoc[option][language]}
				/>
			</Grid>
			<Hidden smDown>
				<Grid item md={6} />
			</Hidden>
			{/* <Grid item xs={12} md={6}>
				<VirtualizedSelect
					language={language}
					options={paid_states}
					// onChange={(e, value) => setValue("paid", value)}
					textFieldProps={{
						name: "paid",
						inputRef: register,
						label: lang.PAID[language],
					}}
					renderOption={(option) => <Typography noWrap>{langDoc[option][language].toUpperCase()}</Typography>}
					getOptionLabel={(option) => langDoc[option][language]}
				/>
			</Grid> */}
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<DatePicker
						autoOk
						variant="inline"
						inputVariant="outlined"
						fullWidth
						name="createDate_gte"
						label={lang.CREATE_DATE[language]}
						format="dd/MM/yyyy"
						onChange={(value) => {
							setCreateDateStart(moment(value).startOf("day"));
							setValue("createDate_gte", moment(value).startOf("day"));
						}}
						maxDate={moment(createDateEnd)}
						value={createDateStart}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={12} md={6}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<DatePicker
						autoOk
						variant="inline"
						inputVariant="outlined"
						fullWidth
						name="createDate_lt"
						label={lang.CREATE_DATE[language]}
						format="dd/MM/yyyy"
						onChange={(value) => {
							setCreateDateEnd(moment(value).endOf("day"));
							setValue("createDate_lt", moment(value).endOf("day"));
						}}
						minDate={moment(createDateStart)}
						value={createDateEnd}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<VirtualizedSelect
					// freeSolo
					textValue
					autoComplete={false}
					autoHighlight={false}
					value={searchPatient}
					language={language}
					options={data?.customers?.customers ? data.customers.customers : []}
					onChange={handleSearchPatient}
					textFieldProps={{
						label: lang.PATIENT[language],
						InputProps: {
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
						},
						placeholder: lang.SEARCH_EXISTING_PATIENT[language],
						error: !!errors?.employee?.id,
						helperText: !!errors?.employee?.id ? getError(errors.employee.id, language) : null,
					}}
					renderOption={(option) => (
						<Typography noWrap>{`${option.lastName} ${option.firstName}, n. ${moment(option.birthDate).format(
							"DD-MM-YYYY"
						)}`}</Typography>
					)}
					getOptionLabel={(option) => (option ? `${option.lastName} ${option.firstName}` : "")}
					renderGroup={(params) => [
						<ListSubheader key={params.key} component="div">
							{params.group}
						</ListSubheader>,
						params.children,
					]}
					groupBy={(option) => option.lastName[0]}
				/>
			</Grid>
			<Grid item xs={12}>
				<VirtualizedSelect
					// freeSolo
					textValue
					autoComplete={false}
					autoHighlight={false}
					value={searchCompany}
					language={language}
					options={dataCo?.companies?.companies ? dataCo.companies.companies : []}
					onChange={handleSearchCompany}
					textFieldProps={{
						label: lang.COMPANY[language],
						InputProps: {
							startAdornment: (
								<InputAdornment position="start">
									<Search />
								</InputAdornment>
							),
						},
						placeholder: lang.SEARCH_EXISTING_COMPANY[language],
						error: !!errors?.job?.company?.id,
						helperText: !!errors?.job?.company?.id ? getError(errors.job.company.id, language) : null,
					}}
					renderOption={(option) => <Typography noWrap>{option.name}</Typography>}
					getOptionLabel={(option) => (option ? option.name : "")}
					renderGroup={(params) => [
						<ListSubheader key={params.key} component="div">
							{params.group}
						</ListSubheader>,
						params.children,
					]}
					groupBy={(option) => option.name[0]}
				/>
			</Grid>
		</Grid>
	);
};

export { DocFilters as default };
