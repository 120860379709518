import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	Divider,
	Button,
	TextField,
	MenuItem,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";

const useStyles = makeStyles(() => ({
	root: {},
	item: {
		display: "flex",
		flexDirection: "column",
	},
}));

const Configurations = (props) => {
	const classes = useStyles();
	const { className, language, togglelanguage } = props;

	const [localLanguage, setLanguage] = useState(language);

	return (
		<Card className={clsx(classes.root, className)}>
			<form>
				<CardHeader
					subheader="Manage the notifications"
					title="Configurations"
				/>
				<Divider />
				<CardContent>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								select
								label={lang.LANGUAGE[language]}
								variant="outlined"
								value={localLanguage}
								onChange={(e) => setLanguage(e.target.value)}
							>
								<MenuItem value="ro">
									{lang.ROMANIAN[language]}
								</MenuItem>
								<MenuItem value="en">
									{lang.ENGLISH[language]}
								</MenuItem>
							</TextField>
						</Grid>
					</Grid>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						color="primary"
						variant="outlined"
						disabled={language === localLanguage}
						onClick={togglelanguage}
					>
						{lang.SAVE[language]}
					</Button>
				</CardActions>
			</form>
		</Card>
	);
};

Configurations.propTypes = {
	className: PropTypes.string,
};

export default Configurations;
