import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	Typography,
	Divider,
	Button,
	LinearProgress,
	ButtonGroup,
} from "@material-ui/core";
import { VerifiedUser, WatchLater, Business, Delete } from "@material-ui/icons";
import { CustomModal } from "../../../../components";
import * as lang from "../../../../utils/strings";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/react-hooks";
import { COMPANY_DELETE_QUERY } from "../../../../requests";
import redirectTo from "../../../../utils/redirect";

const useStyles = makeStyles((theme) => ({
	root: {},
	details: {
		display: "flex",
	},
	avatar: {
		marginLeft: "auto",
		height: 90,
		width: 90,
		flexShrink: 0,
		flexGrow: 0,
	},
	uploadButton: {
		marginRight: theme.spacing(2),
	},
	role: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}));

const CompanyProfile = (props) => {
	const classes = useStyles();
	const { className, data, language, me, ...rest } = props;
	const [confirmModal, setConfirmModal] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const [deleteCompany] = useMutation(COMPANY_DELETE_QUERY);

	const handleCompanyDelete = () => {
		deleteCompany({
			variables: {
				id: data.id,
			},
		})
			.then((response) => {
				enqueueSnackbar(lang.COMPANY_DELETE_SUCCESS[language], {
					variant: "success",
				});
				setConfirmModal(false);
				redirectTo("/companies");
			})
			.catch((error) => {
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent>
				<div className={classes.details}>
					<div>
						<Typography gutterBottom variant="h3">
							{data?.name}
						</Typography>
						{data?.address && (
							<Typography variant="body1" gutterBottom>
								{`${data.address?.region}, ${data.address?.city}`}
							</Typography>
						)}
						<Typography color="textSecondary" variant="caption">
							<span className={classes.role}>
								<WatchLater fontSize="small" className={classes.icon} />
								{moment(data?.createdAt).fromNow()}
							</span>
						</Typography>
					</div>
					<Avatar className={classes.avatar}>
						<Business fontSize="large" />
					</Avatar>
				</div>
			</CardContent>

			{me?.role === "ADMIN" && (
				<>
					<Divider />
					<CustomModal
						{...props}
						title={lang.DELETE_COMPANY[language]}
						content={lang.DELETE_COMPANY_CONFIRM[language]}
						open={confirmModal === "DELETE"}
						onClose={() => setConfirmModal(false)}
						onExecute={handleCompanyDelete}
					/>
					<CardActions>
						<ButtonGroup size="small" variant="text" fullWidth>
							<Button color="primary" fullWidth onClick={() => setConfirmModal("DELETE")}>
								<Delete fontSize="small" className={classes.icon} /> {lang.DELETE_COMPANY[language]}
							</Button>
						</ButtonGroup>
					</CardActions>
				</>
			)}
		</Card>
	);
};

CompanyProfile.propTypes = {
	className: PropTypes.string,
};

export default CompanyProfile;
