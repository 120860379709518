import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
	Checkbox,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import * as langDoc from "../../../../utils/strings/docs";
import { LatestOrdersMenu } from "./components";
import { NoResults, CustomMenu } from "../../../../components";
import { exportData, downloadFile } from "../../../../utils/fetchFunctions";
import redirectTo from "../../../../utils/redirect";
import { useSnackbar } from "notistack";
import { AssignmentInd, Business, Edit, CloudDownload } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		// minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableExtraHeaderSelect: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const LatestOrders = (props) => {
	const {
		className,
		data,
		loading,
		language,
		onOpen,
		onRowClick,
		custom,
		count,
		refetch,
		fetchData,
		called,
		customerId,
	} = props;

	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [docFilters, setDocFilters] = useState([
		{ createDate_gte: moment().subtract(2, "year").startOf("day") },
		{ createDate_lt: moment().endOf("day") },
	]);
	const { enqueueSnackbar } = useSnackbar();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedEl, setSelectedEl] = React.useState(null);
	const [selectedRows, setSelectedRows] = useState([]);
	const [selection, setSelection] = useState(false);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page, docFilters);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0, docFilters);
		setRowsPerPage(value);
	};

	const handleExportDocs = () => {
		let fileName = `file-${new Date().getTime()}.csv`;
		exportData("DOCS")
			.then((response) => {
				console.log(response);
				if (response.status >= 400) {
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
				return response.json();
			})
			.then((response) => {
				try {
					var link = document.createElement("a");
					link.href = response.file;
					link.download = fileName;
					link.click();
					// window.open(fileURL);
				} catch (e) {
					console.log(e);
					enqueueSnackbar(lang.SOMETHING_WRONG[language], {
						variant: "error",
					});
				}
			})
			.catch((err) => {
				console.log(err);
				enqueueSnackbar(lang.SOMETHING_WRONG[language], {
					variant: "error",
				});
			});
	};
	const handleSelectAll = (event) => {
		let selectedRows;

		if (event.target.checked) {
			selectedRows = data.map((item) => item.id);
		} else {
			selectedRows = [];
		}

		setSelectedRows(selectedRows);
	};
	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedRows.indexOf(id);
		let newSelectedRows = [];

		if (selectedIndex === -1) {
			newSelectedRows = newSelectedRows.concat(selectedRows, id);
		} else if (selectedIndex === 0) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
		} else if (selectedIndex === selectedRows.length - 1) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedRows = newSelectedRows.concat(
				selectedRows.slice(0, selectedIndex),
				selectedRows.slice(selectedIndex + 1)
			);
		}

		setSelectedRows(newSelectedRows);
	};

	useEffect(() => {
		if (docFilters) fetchData(rowsPerPage, 0, docFilters);
	}, [called, docFilters, rowsPerPage]);

	return (
		<Card className={clsx(classes.root, className)}>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.EDIT_DOCUMENT[language],
						icon: <Edit className={classes.leftButton} />,
						action: () => onRowClick(selectedEl),
					},
					{
						title: lang.GO_TO(lang.PATIENT[language])[language],
						icon: <AssignmentInd className={classes.leftButton} />,
						action: () => redirectTo(`/customer/${selectedEl?.employee?.id}`),
					},
					{
						title: lang.GO_TO(lang.COMPANY[language])[language],
						icon: <Business className={classes.leftButton} />,
						disabled: selectedEl?.job === null,
						action: () => redirectTo(`/company/${selectedEl?.job?.company?.id}`),
					},
					{
						title: lang.DOWNLOAD_DOCUMENT[language],
						icon: <CloudDownload className={classes.leftButton} />,
						render: selectedEl?.name !== null && selectedEl?.dataUri !== null,
						action: () => downloadFile(selectedEl.name, selectedEl.dataUri),
						// action: () => console.log(selectedEl),
					},
				]}
			/>
			{custom && (
				<>
					<LatestOrdersMenu
						refetch={refetch}
						count={count}
						language={language}
						classes={classes}
						onOpen={onOpen}
						fetchData={fetchData}
						rowsPerPage={rowsPerPage}
						docFilters={docFilters}
						onExport={handleExportDocs}
						setDocFilters={setDocFilters}
						selection={selection}
						selected={selectedRows}
						onSelection={() => {
							setSelection(!selection);
							setSelectedRows([]);
						}}
					/>
					<Divider />
				</>
			)}
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{!loading && data?.length > 0 && (
								<TableHead>
									<TableRow>
										{selection && (
											<TableCell padding="checkbox">
												<Checkbox
													checked={selectedRows.length === data.length}
													color="primary"
													indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
													onChange={handleSelectAll}
												/>
											</TableCell>
										)}
										<TableCell>ID</TableCell>
										<TableCell>{lang.FULL_NAME[language]}</TableCell>
										<TableCell>{lang.JOB[language]}</TableCell>
										<TableCell>{lang.INVOICE_NO[language]}</TableCell>
										<TableCell>{lang.INVOICE_PAID[language]}</TableCell>
										<TableCell>{lang.TYPE[language]}</TableCell>
										<TableCell>{lang.STATE[language]}</TableCell>
										<TableCell>{lang.NEXT_DATE[language]}</TableCell>
										<TableCell>{lang.DOCUMENT_DATE[language]}</TableCell>
										<TableCell>{lang.ACTUAL_CREATE_DATE[language]}</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : data?.length === 0 ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<NoResults message={lang.NO_RESULTS[language]} />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.slice(0, rowsPerPage).map((item) => (
										<TableRow
											onClick={(e) => {
												if (selection) {
													handleSelectOne(e, item.id);
												} else {
													setSelectedEl(item);
													setAnchorEl(e.currentTarget);
												}
											}}
											className={classes.tableRow}
											hover
											key={item.id}
										>
											{selection && (
												<TableCell padding="checkbox">
													<Checkbox
														checked={selectedRows.indexOf(item.id) !== -1}
														color="primary"
														onChange={(event) => handleSelectOne(event, item.id)}
														value="true"
													/>
												</TableCell>
											)}
											<TableCell>
												<Typography variant="body1">{item?.id}</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1">
													{item?.employee?.lastName} {item?.employee?.firstName}
												</Typography>
											</TableCell>
											<TableCell>
												{item?.job?.job === undefined ? "-" : `${item?.job?.job} @ ${item?.job?.company?.name}`}
											</TableCell>
											<TableCell>{item.invoiceNo ? item.invoiceNo : "-"}</TableCell>
											<TableCell>
												{item.paid === true
													? lang.PAID[language].toUpperCase()
													: item.amount > 0
													? `${lang.PARTIAL_PAYMENT[language].toUpperCase()} (${item.amount} RON)`
													: lang.NOT_PAID[language].toUpperCase()}
											</TableCell>
											<TableCell>{item?.type ? lang[item.type][language] : ""}</TableCell>
											<TableCell>{item?.state ? langDoc[item.state][language].toUpperCase() : ""}</TableCell>
											<TableCell>
												{item.FAPT?.nextDate ? moment(item.FAPT.nextDate).format("DD/MM/YYYY") : "-"}
											</TableCell>
											<TableCell>{moment(item.createDate).format("DD/MM/YYYY")}</TableCell>
											<TableCell>{moment(item.createdAt).format("DD/MM/YYYY")}</TableCell>
										</TableRow>
									))}

									{page === Math.floor(count / rowsPerPage) && (
										<TableRow
											style={{
												height: 53 * (rowsPerPage - (count % rowsPerPage)),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
					language={language}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	// const { language, rowsPerPage, page, count } = props;
	// const from = page * rowsPerPage + 1;
	// const to = count % rowsPerPage === 0 ? rowsPerPage : count % rowsPerPage;
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
			// labelDisplayedRows={() =>
			// 	lang.TABLE_DISPLAY_ROWS(from, to, count)[language]
			// }
			// labelRowsPerPage={lang.TABLE_ROWS_PAGE[language]}
		/>
	);
}

LatestOrders.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default LatestOrders;
