import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, CircularProgress } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Done, Save, Error } from "@material-ui/icons";
import { CompanyForm } from "../../../../Forms";
import * as lang from "../../../../utils/strings";

const useStyles = makeStyles((theme) => ({
	root: {},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative",
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonError: {
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[700],
		},
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}));

const CompanyDetails = (props) => {
	const { className, onExecute, handleSubmit, buttonStatus, language } = props;

	const classes = useStyles();

	const onSubmit = (data, e) => {
		// e.preventDefault();
		onExecute(data);
	};

	const buttonClassname = clsx({
		[classes.buttonStd]: true,
		[classes.buttonSuccess]: buttonStatus === true,
		[classes.buttonError]: buttonStatus === "error",
	});

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader subheader={lang.DATA_EDIT[language]} title={lang.COMPANY[language]} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					<CompanyForm {...props} edit />
				</Grid>
			</CardContent>
			<Divider />
			<CardActions>
				<div className={classes.wrapper}>
					<Button
						color="primary"
						variant={buttonStatus === true ? "contained" : "outlined"}
						disabled={buttonStatus === "loading"}
						className={buttonClassname}
						onClick={handleSubmit(onSubmit)}
					>
						{buttonStatus === false && <Save fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === "error" && <Error fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === true && <Done fontSize="small" className={classes.leftIcon} />}
						{lang.SAVE[language]}
					</Button>
					{buttonStatus === "loading" && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</CardActions>
		</Card>
	);
};

CompanyDetails.propTypes = {
	className: PropTypes.string,
};

export default CompanyDetails;
