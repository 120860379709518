export function getAccessToken() {
	return localStorage.getItem("accessTokenKey");
}

export function isLoggedIn() {
	return !!localStorage.getItem("accessTokenKey");
}

export function logout() {
	localStorage.removeItem("accessTokenKey");
	localStorage.removeItem("me");
}
