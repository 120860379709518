export const INVOICE_NO = {
	ro: "Nr. factură",
	en: "Invoice No.",
};
export const INVOICE_PAID = {
	ro: "Achitare",
	en: "Payment",
};
export const PAID = {
	ro: "Achitată",
	en: "Paid",
};
export const PAID_AMOUNT = {
	ro: "Sumă achitată",
	en: "Paid amount",
};
export const PAID_ENTIRELY = {
	ro: "Achitată integral",
	en: "Paid entirely",
};
export const PARTIAL_PAYMENT = {
	ro: "Achitată parțial",
	en: "Partial payment",
};
export const NOT_PAID = {
	ro: "Neachitată",
	en: "Not paid",
};
export const TYPE = {
	ro: "Tip",
	en: "Type",
};
export const STATE = {
	ro: "Stare",
	en: "State",
};
export const DOCUMENTS = {
	ro: "Documente",
	en: "Documents",
};
export const ENTRY_REGISTRY = {
	ro: "Registru intrări",
	en: "Entries",
};
export const CLOSED_DOCS_ONLY = {
	ro: "Doar fișe închise",
	en: "Closed documents only",
};
export const FAPT = {
	ro: "Fișă de aptitudine",
	en: "Fișă de aptitudine",
};
export const DMED = {
	ro: "Dosar medical",
	en: "Dosar medical",
};
export const FAUT = {
	ro: "Fișă auto",
	en: "Fișă auto",
};
export const REASON = {
	ro: "Motiv",
	en: "Reason",
};
export const AUTO_GROUP = {
	ro: "Grupa auto",
	en: "Auto group",
};
export const RESULT = {
	ro: "Rezultat",
	en: "Result",
};
export const RESULT_AUTO1 = {
	ro: "Rezultat Grupa I",
	en: "Result I",
};
export const RESULT_AUTO2 = {
	ro: "Rezultat Grupa II",
	en: "Result II",
};
export const MED_INT = {
	ro: "Medicină internă",
	en: "Medicină internă",
};
export const PSHT = {
	ro: "Psihiatrie",
	en: "Psihiatrie",
};
export const PSHL = {
	ro: "Psihologie",
	en: "Psihologie",
};
export const DERM = {
	ro: "Dermatologie",
	en: "Dermatologie",
};
export const GINECO = {
	ro: "Ginecologie",
	en: "Ginecologie",
};
export const OFT = {
	ro: "Oftalmologie",
	en: "Oftalmologie",
};
export const ORL = {
	ro: "Otorinolaringologie",
	en: "Otorinolaringologie",
};
export const NEURO = {
	ro: "Neurologie",
	en: "Neurologie",
};
export const PNEUM = {
	ro: "Pneumologie",
	en: "Pneumologie",
};
export const CHIR = {
	ro: "Chirurgie",
	en: "Chirurgie",
};
export const MED_MUN = {
	ro: "Medicina muncii",
	en: "Medicina muncii",
};
export const OPEN = {
	ro: "Deschis",
	en: "Open",
};
export const COMPLETED = {
	ro: "Completat",
	en: "Completed",
};
export const DONE = {
	ro: "Finalizat",
	en: "Done",
};
export const DELIVERED = {
	ro: "Generat",
	en: "Delivered",
};
export const CLOSED = {
	ro: "Închis",
	en: "Closed",
};
