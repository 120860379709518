import { colors } from "@material-ui/core";

const white = "#FFFFFF";
const black = "#000000";
const blackContrast = "rgba(0, 0, 0, 0.87)";

export const paletteDark = {
	common: {
		black,
		white,
	},
	type: "dark",
	primary: {
		light: colors.pink[300],
		main: colors.pink[500],
		dark: colors.pink[700],
		contrastText: white,
	},
	secondary: {
		light: colors.amber["A200"],
		main: colors.amber["A400"],
		dark: colors.amber["A700"],
		transparent: (opacity) => `rgb(255, 171, 0, ${opacity})`,
		contrastText: white,
	},
	error: {
		light: colors.red[300],
		main: colors.red[500],
		dark: colors.red[700],
		transparent: (opacity) => `rgb(229, 115, 115, ${opacity})`,
		contrastText: white,
	},
	warning: {
		light: colors.orange[300],
		main: colors.orange[500],
		dark: colors.orange[700],
		contrastText: blackContrast,
	},
	info: {
		light: colors.blue[300],
		main: colors.blue[500],
		dark: colors.blue[700],
		contrastText: white,
	},
	success: {
		light: colors.green[300],
		main: colors.green[500],
		dark: colors.green[700],
		contrastText: blackContrast,
	},
	text: {
		primary: white,
		secondary: "rgba(255,255,255,0.7)",
		disabled: "rgba(255,255,255,0.5)",
		hint: "rgba(255,255,255,0.5)",
		icon: "rgba(255,255,255,0.5)",
	},
	divider: "rgba(255,255,255,0.12)",
	background: {
		paper: colors.grey[800],
		default: colors.grey["A400"],
	},
	action: {
		active: white,
		hover: "rgba(255,255,255,0.2)",
		hoverOpacity: 0.08,
		selected: "rgba(255,255,255,0.16)",
		selectedOpacity: 0.16,
		disabled: "rgba(255,255,255,0.3)",
		disabledBackground: "rgba(255,255,255,0.12)",
		disabledOpacity: 0.38,
		focus: "rgba(255,255,255,0.12)",
		focusOpacity: 0.12,
		activatedOpacity: 0.24,
	},
};
