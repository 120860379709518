import * as lang from "./strings/errors";

export default (error, language, aux) => {
	const { type } = error;
	switch (type) {
		case "required":
			return lang.REQUIRED_FILED[language];
		case "minLength":
			return lang.MIN_CHARS(aux)[language];
		case "maxLength":
			return lang.MAX_CHARS(aux)[language];
		case "confirmError":
			return lang.CONFIRM_PASSWORD[language];
		case "wrongPassword":
			return lang.WRONG_PASSWORD[language];
		default:
			return null;
	}
};
