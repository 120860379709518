import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
	Checkbox,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CustomerJobsMenu } from "./components";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		// minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableExtraHeaderSelect: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const CustomerJobs = (props) => {
	const {
		className,
		data,
		loading,
		language,
		onOpen,
		onRowClick,
		custom,
		count,
		refetch,
	} = props;

	const classes = useStyles();

	const [selectedRows, setSelectedRows] = useState([]);
	const [selection, setSelection] = useState(false);
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	const handleSelectAll = (event) => {
		let selectedRows;

		if (event.target.checked) {
			selectedRows = data.map((item) => item.id);
		} else {
			selectedRows = [];
		}

		setSelectedRows(selectedRows);
	};
	const handleSelectOne = (event, id) => {
		const selectedIndex = selectedRows.indexOf(id);
		let newSelectedRows = [];

		if (selectedIndex === -1) {
			newSelectedRows = newSelectedRows.concat(selectedRows, id);
		} else if (selectedIndex === 0) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
		} else if (selectedIndex === selectedRows.length - 1) {
			newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedRows = newSelectedRows.concat(
				selectedRows.slice(0, selectedIndex),
				selectedRows.slice(selectedIndex + 1)
			);
		}

		setSelectedRows(newSelectedRows);
	};

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		setRowsPerPage(value);
	};

	return (
		<Card className={clsx(classes.root, className)}>
			{custom && (
				<>
					<CustomerJobsMenu
						refetch={refetch}
						count={count}
						language={language}
						classes={classes}
						onOpen={onOpen}
						selection={selection}
						selected={selectedRows}
						onSelection={() => {
							setSelection(!selection);
							setSelectedRows([]);
						}}
					/>
					<Divider />
				</>
			)}
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{loading ? (
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
									</TableRow>
								</TableHead>
							) : (
								<TableHead>
									<TableRow>
										{selection && (
											<TableCell padding="checkbox">
												<Checkbox
													checked={
														selectedRows.length ===
														data.length
													}
													color="primary"
													indeterminate={
														selectedRows.length >
															0 &&
														selectedRows.length <
															data.length
													}
													onChange={handleSelectAll}
												/>
											</TableCell>
										)}
										<TableCell>
											{lang.JOB[language]}
										</TableCell>
										<TableCell>
											{lang.COMPANY_NAME[language]}
										</TableCell>
										<TableCell>
											{lang.CREATE_DATE[language]}
										</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.slice(0, rowsPerPage).map((item) => (
										<TableRow
											onClick={(event) => {
												selection
													? handleSelectOne(
															event,
															item.id
													  )
													: onRowClick(item);
											}}
											className={classes.tableRow}
											hover
											key={item.id}
											selected={
												selectedRows.indexOf(
													item.id
												) !== -1
											}
										>
											{selection && (
												<TableCell padding="checkbox">
													<Checkbox
														checked={
															selectedRows.indexOf(
																item.id
															) !== -1
														}
														color="primary"
														onChange={(event) =>
															handleSelectOne(
																event,
																item.id
															)
														}
														value="true"
													/>
												</TableCell>
											)}
											<TableCell>
												<Typography variant="body1">
													{item?.job}
												</Typography>
											</TableCell>
											<TableCell>
												{item?.company?.name}
											</TableCell>
											<TableCell>
												{moment(item.createdAt).format(
													"DD/MM/YYYY"
												)}
											</TableCell>
										</TableRow>
									))}

									{page ===
										Math.floor(count / rowsPerPage) && (
										<TableRow
											style={{
												height:
													53 *
													(rowsPerPage -
														(count % rowsPerPage)),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={data?.length}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
		/>
	);
}

CustomerJobs.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default CustomerJobs;
