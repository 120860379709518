import { gql } from "apollo-boost";

const login = gql`
	mutation LoginQuery($data: LoginUserInput!) {
		login(data: $data) {
			token
			user {
				id
				username
				password
			}
		}
	}
`;

const me = gql`
	{
		me {
			id
			username
			avatar
			role
			profile {
				id
				firstName
				lastName
				email
			}
			createdAt
		}
	}
`;

const cities = gql`
	query CitiesQuery($query: String) {
		cities(query: $query, orderBy: city_ASC) {
			city
			region {
				region
			}
		}
	}
`;
const regions = gql`
	query RegionsQuery($query: String) {
		regions(query: $query, orderBy: region_ASC) {
			id
			region
		}
	}
`;
export { login as LOGIN_QUERY };
export { me as ME_QUERY };

export { cities as CITIES_QUERY };
export { regions as REGIONS_QUERY };
