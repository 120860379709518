export const REQUIRED_FILED = {
	ro: "Acest câmp este obligatoriu",
	en: "This field is required",
};
export const MIN_CHARS = (number) => ({
	ro: `Minim ${number} caractere`,
	en: `Minimum ${number} characters`,
});
export const MAX_CHARS = (number) => ({
	ro: `Maxim ${number} caractere`,
	en: `Maximum ${number} characters`,
});
export const WRONG_PASSWORD = {
	ro: `Parolă greșită`,
	en: `Wrong password`,
};
export const CONFIRM_PASSWORD = {
	ro: `Confirmă parola`,
	en: `Confirm password`,
};