import React, { useEffect, useState } from "react";
import { TextField, Grid, MenuItem, Divider, Typography } from "@material-ui/core";
import * as lang from "../../utils/strings";
import getError from "../../utils/getError";
import { AddressForm, CustomerAddressForm } from "..";
import { CompanySearch } from "./components";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { ro, enUS } from "date-fns/esm/locale";

const CustomerFormContent = (props) => {
	const {
		language,
		register,
		unregister,
		setValue,
		errors,
		includeCompany,
		edit,
		data,
		custExists,
		today,
		custOnly,
	} = props;

	const [customerGender, setCustomerGender] = useState("MALE");
	const [birthDate, setBirthDate] = useState(today);
	const [idDate, setIdDate] = useState(null);
	const [exists, setExists] = React.useState(false);

	const handleGenderChange = (event) => {
		setValue("customer.gender", event.target.value);
		setCustomerGender(event.target.value);
	};

	const handleBirthDateChange = (value) => {
		let date = value;
		try {
			date = format(new Date(value), "yyyy-MM-dd");
		} catch (e) {
			console.log("Date incomplete");
		} finally {
			setValue("customer.birthDate", date);
			setBirthDate(date);
		}
	};
	const handleIdDateChange = (value) => {
		let date = value;
		try {
			date = format(new Date(value), "yyyy-MM-dd");
		} catch (e) {
			console.log("Date incomplete");
		} finally {
			setValue("customer.ci.idDate", date);
			setIdDate(date);
		}
	};

	useEffect(() => {
		if (data?.birthDate) {
			setValue("customer.birthDate", data.birthDate);
			setBirthDate(new Date(data.birthDate));
		}
		if (data?.gender) {
			setValue("customer.gender", data.gender);
			setCustomerGender(data.gender);
		}
		if (data?.ci?.idDate) {
			register({ name: "customer.ci.idDate" });
			setValue("customer.ci.idDate", data.ci.idDate);
			setIdDate(new Date(data.ci.idDate));
		}
	}, [data]);

	useEffect(() => {
		setValue("customer", data);
	}, []);

	useEffect(() => {
		register({ name: "customer.firstName" });
		register({ name: "customer.gender" });
		register({ name: "customer.birthDate" });
		register({ name: "customer.ci.idDate" });
	}, [register]);

	return (
		<React.Fragment>
			{!custExists && (
				<>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							required
							onClick={() => console.log(errors)}
							label={lang.LAST_NAME[language]}
							name="customer.lastName"
							inputRef={register({ required: true })}
							variant="outlined"
							error={!!errors?.customer?.lastName}
							helperText={!!errors?.customer?.lastName ? getError(errors.customer.lastName, language) : null}
							margin={edit ? "dense" : "none"}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							required
							label={lang.FIRST_NAME[language]}
							name="customer.firstName"
							inputRef={register({ required: true })}
							variant="outlined"
							error={!!errors?.customer?.firstName}
							helperText={!!errors?.customer?.firstName ? getError(errors.customer.firstName, language) : null}
							margin={edit ? "dense" : "none"}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label={lang.PHONE[language]}
							name="customer.phone"
							type="number"
							inputRef={register}
							variant="outlined"
							margin={edit ? "dense" : "none"}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							label={lang.CNP[language]}
							name="customer.ci.cnp"
							type="number"
							inputRef={register({
								minLength: 13,
							})}
							onInput={(e) => {
								e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 13);
							}}
							variant="outlined"
							error={!!errors?.customer?.ci?.cnp}
							helperText={!!errors?.customer?.ci?.cnp ? getError(errors.customer.ci.cnp, language, 13) : null}
							margin={edit ? "dense" : "none"}
						/>
					</Grid>
					{!edit && (
						<>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label={lang.CI1[language]}
									name="customer.ci.idSeries"
									inputRef={register}
									variant="outlined"
									error={!!errors?.customer?.ci?.idSeries}
									helperText={!!errors?.customer?.ci?.idSeries ? getError(errors.customer.ci.idSeries, language) : null}
									margin={edit ? "dense" : "none"}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label={lang.CI2[language]}
									name="customer.ci.idNo"
									type="number"
									inputRef={register({
										minLength: 6,
									})}
									onInput={(e) => {
										e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
									}}
									variant="outlined"
									error={!!errors?.customer?.ci?.idNo}
									helperText={!!errors?.customer?.ci?.idNo ? getError(errors.customer.ci.idNo, language, 6) : null}
									margin={edit ? "dense" : "none"}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									fullWidth
									label={lang.CI_ISSUER[language]}
									name="customer.ci.idIssuer"
									inputRef={register}
									variant="outlined"
									error={!!errors?.customer?.ci?.idIssuer}
									helperText={!!errors?.customer?.ci?.idIssuer ? getError(errors.customer.ci.idIssuer, language) : null}
									margin={edit ? "dense" : "none"}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
									<KeyboardDatePicker
										// className={classes.dateSelect}
										// autoOk
										variant="inline"
										inputVariant="outlined"
										fullWidth
										name="customer.ci.idDate"
										label={lang.CI_DATE[language]}
										format="dd/MM/yyyy"
										onChange={handleIdDateChange}
										value={idDate}
										error={!!errors?.customer?.ci?.idDate}
										helperText={!!errors?.customer?.ci?.idDate ? getError(errors.customer.ci.idDate, language) : null}
										margin={edit ? "dense" : "none"}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						</>
					)}
					<Grid item xs={12} md={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
							<KeyboardDatePicker
								// className={classes.dateSelect}
								autoOk
								variant="inline"
								required={true}
								inputVariant="outlined"
								fullWidth
								name="customer.birthDate"
								label={lang.BIRTH_DATE[language]}
								format="dd/MM/yyyy"
								onChange={handleBirthDateChange}
								value={birthDate}
								error={!!errors?.customer?.birthDate}
								helperText={!!errors?.customer?.birthDate ? getError(errors.customer.birthDate, language) : null}
								margin={edit ? "dense" : "none"}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							fullWidth
							select
							label={lang.GENDER[language]}
							name="customer.gender"
							onChange={handleGenderChange}
							value={customerGender}
							variant="outlined"
							margin={edit ? "dense" : "none"}
						>
							<MenuItem value="MALE">{lang.MALE[language]}</MenuItem>
							<MenuItem value="FEMALE">{lang.FEMALE[language]}</MenuItem>
						</TextField>
					</Grid>
					{!edit && <CustomerAddressForm {...props} includeCountry />}
				</>
			)}
			{!custOnly && includeCompany && (
				<Grid item xs={12}>
					<Divider />
				</Grid>
			)}
			{!edit && !custOnly && <CompanySearch {...props} exists={exists} setExists={setExists} />}
		</React.Fragment>
	);
};

const CustomerForm = (props) => {
	const { standalone } = props;

	if (standalone)
		return (
			<Grid container spacing={2}>
				<CustomerFormContent {...props} />
			</Grid>
		);
	else return <CustomerFormContent {...props} />;
};

export { CustomerForm as default };
