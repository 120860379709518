import React, { useState, useEffect } from "react";
import { TextField, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as lang from "../../../../utils/strings";
import getError from "../../../../utils/getError";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ro, enUS } from "date-fns/esm/locale";
import { CITIES_QUERY, REGIONS_QUERY } from "../../../../requests";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import VirtualizedSelect from "../../../../components/VirtualizedSelect/VirtualizedSelect";

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const FisaAutForm = (props) => {
	const classes = useStyles();
	const { language, register, setValue, unregister, data, errors, clearError, expanded } = props;
	const today = new Date().toISOString().split("T")[0];

	const [createDate, setCreateDate] = useState(today);
	const [birthDate, setBirthDate] = useState(null);
	const [ciDate, setCiDate] = useState(null);
	const [gender, setGender] = useState(null);
	const [region, setRegion] = useState(null);
	const [city, setCity] = useState(null);
	const [birthRegion, setBirthRegion] = useState(null);
	const [birthCity, setBirthCity] = useState(null);

	const { data: regions } = useQuery(REGIONS_QUERY, {
		variables: { query: null },
	});

	const [getCities, { data: cities }] = useLazyQuery(CITIES_QUERY);

	const [getBirthCities, { data: birthCities }] = useLazyQuery(CITIES_QUERY);

	const handleRegionSelect = (event, value, reason) => {
		setValue("doc.fisaAut.region", value, true);
		clearError("doc.fisaAut.region");
		clearError("doc.fisaAut.city");
		setValue("doc.fisaAut.city", "");
		setRegion(value);
		setCity("");
	};
	const handleCitySelect = (event, value, reason) => {
		setValue("doc.fisaAut.city", value, true);
		clearError("doc.fisaAut.city");
		setCity(value);
	};
	const handleBirthRegionSelect = (event, value, reason) => {
		setValue("doc.fisaAut.birthRegion", value, true);
		clearError("doc.fisaAut.birthRegion");
		clearError("doc.fisaAut.birthCity");
		setValue("doc.fisaAut.birthCity", "");
		setBirthRegion(value);
		setBirthCity("");
	};

	const handleBirthCitySelect = (event, value, reason) => {
		setValue("doc.fisaAut.birthCity", value, true);
		clearError("doc.fisaAut.birthCity");
		setBirthCity(value);
	};

	const handleCreateDateChange = (value) => {
		const date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("doc.fisaAut.createDate", date);
			clearError("doc.fisaAut.createDate");
			setCreateDate(date);
		}
	};
	const handleGenderChange = (event) => {
		setValue("doc.fisaAut.gender", event.target.value);
		clearError("doc.fisaAut.gender");
		setGender(event.target.value);
	};

	const handleBirthDateChange = (value) => {
		const date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("doc.fisaAut.birthDate", date);
			clearError("doc.fisaAut.birthDate");
			setBirthDate(date);
		}
	};
	const handleCiDateChange = (value) => {
		const date = value;
		try {
			date = new Date(value).toISOString().split("T")[0];
		} catch (e) {
			console.log(e);
		} finally {
			setValue("doc.fisaAut.ciDate", date);
			clearError("doc.fisaAut.ciDate");
			setCiDate(date);
		}
	};

	useEffect(() => {
		if (expanded === "OPEN") {
			clearError();
			register({ name: "doc.fisaAut.createDate" }, { required: true });
			register({ name: "doc.fisaAut.birthDate" }, { required: true });
			register({ name: "doc.fisaAut.ciDate" }, { required: true });
			register({ name: "doc.fisaAut.region" }, { required: true });
			register({ name: "doc.fisaAut.city" }, { required: true });
			register({ name: "doc.fisaAut.birthRegion" }, { required: true });
			register({ name: "doc.fisaAut.birthCity" }, { required: true });
			register({ name: "doc.type" });
			register({ name: "doc.state" });
			setValue("doc.fisaAut.createDate", today);
		}
		unregister("registries");
		console.log("OPEN");
	}, [register, expanded]);

	useEffect(() => {
		if (data) {
			console.log(data);
			setValue("doc", data);
		}
		unregister("registries");
	}, [data, setValue]);

	useEffect(() => {
		getCities({
			variables: {
				query: region,
			},
		});
	}, [region]);

	useEffect(() => {
		if (data?.fisaAut) handleBirthDateChange(new Date(data.fisaAut.birthDate));
		if (data?.fisaAut) setGender(data.fisaAut.gender);
	}, [data]);

	useEffect(() => {
		getBirthCities({
			variables: {
				query: birthRegion,
			},
		});
	}, [birthRegion]);

	return (
		<React.Fragment>
			<Grid item xs={12} sm={6} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<KeyboardDatePicker
						autoOk
						disabled={["DONE", "DELIVERED"].includes(data?.state)}
						variant="inline"
						required={true}
						inputVariant="outlined"
						fullWidth
						name="doc.fisaAut.createDate"
						label={lang.CREATE_DATE[language]}
						format="dd/MM/yyyy"
						onChange={handleCreateDateChange}
						value={createDate}
						error={!!errors?.doc?.fisaAut?.createDate}
						helperText={!!errors?.doc?.fisaAut?.createDate ? getError(errors.doc.fisaAut.createDate, language) : null}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					language={language}
					options={regions ? regions.regions?.map((item) => item.region) : []}
					onChange={handleBirthRegionSelect}
					value={birthRegion}
					textFieldProps={{
						required: true,
						name: "doc.fisaAut.birthRegion",
						error: !!errors?.doc?.fisaAut?.birthRegion,
						helperText: !!errors?.doc?.fisaAut?.birthRegion ? getError(errors.doc.fisaAut.birthRegion, language) : null,
						label: lang.B_REGION[language],
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					disabled={!birthRegion}
					language={language}
					options={birthCities ? birthCities.cities?.map((item) => item.city) : []}
					onChange={handleBirthCitySelect}
					value={birthCity}
					textFieldProps={{
						required: true,
						name: "doc.fisaAut.birthCity",
						error: !!errors?.doc?.fisaAut?.birthCity,
						helperText: !!errors?.doc?.fisaAut?.birthCity ? getError(errors.doc.fisaAut.birthCity, language) : null,
						label: lang.B_CITY[language],
					}}
				/>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					language={language}
					options={regions ? regions.regions?.map((item) => item.region) : []}
					onChange={handleRegionSelect}
					value={region}
					textFieldProps={{
						required: true,
						name: "doc.fisaAut.region",
						error: !!errors?.doc?.fisaAut?.region,
						helperText: !!errors?.doc?.fisaAut?.region ? getError(errors.doc.fisaAut.region, language) : null,
						label: lang.REGION[language],
					}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<VirtualizedSelect
					disabled={!region}
					language={language}
					options={cities ? cities.cities?.map((item) => item.city) : []}
					onChange={handleCitySelect}
					value={city}
					textFieldProps={{
						required: true,
						name: "doc.fisaAut.city",
						error: !!errors?.doc?.fisaAut?.city,
						helperText: !!errors?.doc?.fisaAut?.city ? getError(errors.doc.fisaAut.city, language) : null,
						label: lang.CITY[language],
					}}
				/>
			</Grid>

			<Grid item xs={8} sm={6} md={3}>
				<TextField
					fullWidth
					label={lang.STREET[language]}
					name="doc.fisaAut.street"
					inputRef={register}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.street}
					helperText={!!errors?.doc?.fisaAut?.street ? getError(errors.doc.fisaAut?.street, language) : null}
				/>
			</Grid>
			<Grid item xs={4} sm={2} md={1}>
				<TextField
					fullWidth
					label={lang.STREET_NO[language]}
					name="doc.fisaAut.streetNo"
					inputRef={register}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.streetNo}
					helperText={!!errors?.doc?.fisaAut?.streetNo ? getError(errors.doc.fisaAut?.streetNo, language) : null}
				/>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					required
					label={lang.FIRST_NAME[language]}
					name="doc.fisaAut.firstName"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.firstName}
					helperText={!!errors?.doc?.fisaAut?.firstName ? getError(errors.doc.fisaAut?.firstName, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					required
					label={lang.LAST_NAME[language]}
					name="doc.fisaAut.lastName"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.lastName}
					helperText={!!errors?.doc?.fisaAut?.lastName ? getError(errors.doc.fisaAut.lastName, language) : null}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<KeyboardDatePicker
						// className={classes.dateSelect}
						autoOk
						variant="inline"
						required={true}
						inputVariant="outlined"
						fullWidth
						name="doc.fisaAut.birthDate"
						label={lang.BIRTH_DATE[language]}
						format="dd/MM/yyyy"
						onChange={handleBirthDateChange}
						value={birthDate}
						error={!!errors?.doc?.fisaAut?.birthDate}
						helperText={!!errors?.doc?.fisaAut?.birthDate ? getError(errors.doc.fisaAut.birthDate, language) : null}
					/>
				</MuiPickersUtilsProvider>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					select
					label={lang.GENDER[language]}
					name="doc.fisaAut.gender"
					onChange={handleGenderChange}
					value={gender}
					variant="outlined"
					InputLabelProps={{
						shrink: true,
					}}
				>
					<li className={classes.listItem} value={"MALE"}>
						<Typography variant="body1">{lang.MALE[language]}</Typography>
					</li>
					<li className={classes.listItem} value={"FEMALE"}>
						<Typography variant="body1">{lang.FEMALE[language]}</Typography>
					</li>
				</TextField>
			</Grid>
			<Grid item xs={4} sm={2} md={1}>
				<TextField
					fullWidth
					required
					label={lang.CI1[language]}
					name="doc.fisaAut.ci1"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.ci1}
					helperText={!!errors?.doc?.fisaAut?.ci1 ? getError(errors.doc.fisaAut.ci1, language) : null}
				/>
			</Grid>
			<Grid item xs={8} sm={4} md={2}>
				<TextField
					fullWidth
					required
					label={lang.CI2[language]}
					name="doc.fisaAut.ci2"
					type="number"
					inputRef={register({
						required: true,
						minLength: 6,
					})}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.ci2}
					helperText={!!errors?.doc?.fisaAut?.ci2 ? getError(errors.doc.fisaAut.ci2, language, 6) : null}
					onInput={(e) => {
						e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					required
					label={lang.CI_ISSUER[language]}
					name="doc.fisaAut.ciIssuer"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.ciIssuer}
					helperText={!!errors?.doc?.fisaAut?.ciIssuer ? getError(errors.doc.fisaAut.ciIssuer, language) : null}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === "ro" ? ro : enUS}>
					<KeyboardDatePicker
						autoOk
						disabled={["DONE", "DELIVERED"].includes(data?.state)}
						variant="inline"
						required={true}
						inputVariant="outlined"
						fullWidth
						name="doc.fisaAut.ciDate"
						label={lang.CI_DATE[language]}
						format="dd/MM/yyyy"
						onChange={handleCiDateChange}
						value={ciDate}
						error={!!errors?.doc?.fisaAut?.ciDate}
						helperText={!!errors?.doc?.fisaAut?.ciDate ? getError(errors.doc.fisaAut.ciDate, language) : null}
					/>
				</MuiPickersUtilsProvider>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>

			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					required
					label={lang.PARENTF[language]}
					name="doc.fisaAut.parentF"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.parentF}
					helperText={!!errors?.doc?.fisaAut?.parentF ? getError(errors.doc.fisaAut.parentF, language) : null}
				/>
			</Grid>
			<Grid item xs={12} sm={6} md={3}>
				<TextField
					fullWidth
					required
					label={lang.PARENTM[language]}
					name="doc.fisaAut.parentM"
					inputRef={register({ required: true })}
					variant="outlined"
					error={!!errors?.doc?.fisaAut?.parentM}
					helperText={!!errors?.doc?.fisaAut?.parentM ? getError(errors.doc.fisaAut.parentM, language) : null}
				/>
			</Grid>
		</React.Fragment>
	);
};

export { FisaAutForm as default };
