import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar, Typography } from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		minHeight: "fit-content",
	},
	avatar: {
		width: 60,
		height: 60,
	},
	name: {
		marginTop: theme.spacing(1),
	},
	role: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}));

const Profile = (props) => {
	const { className, data, ...rest } = props;
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Avatar
				alt="Person"
				className={classes.avatar}
				component={RouterLink}
				src={
					data?.avatar
						? `/images/avatars/avatar-0${data?.avatar}.svg`
						: ""
				}
				to="/account"
				onClick={props.onClose}
			/>
			<Typography gutterBottom className={classes.name} variant="h4">
				{data?.profile?.firstName} {data?.profile?.lastName}
			</Typography>
			{data?.role === "ADMIN" ? (
				<Typography variant="body1" color="secondary">
					<span className={classes.role}>
						<VerifiedUser
							fontSize="small"
							className={classes.icon}
						/>
						{data?.username}
					</span>
				</Typography>
			) : (
				<Typography variant="body1">{data?.username}</Typography>
			)}
		</div>
	);
};

Profile.propTypes = {
	className: PropTypes.string,
};

export default Profile;
