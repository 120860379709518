import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Tabs, Tab, IconButton } from "@material-ui/core";
import { MoreVert, Save } from "@material-ui/icons";
import { CustomMenu } from "../../../../components";
import { EXPORT } from "../../../../utils/strings";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
}));

const RegistriesMenu = (props) => {
	const classes = useStyles();
	const { filter, setFilter, onExport, language } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleChange = (event, newValue) => {
		setFilter(newValue);
	};

	return (
		<>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: EXPORT[language],
						icon: <Save className={classes.leftButton} />,
						action: onExport,
					},
				]}
			/>
			<AppBar
				position="sticky"
				color="default"
				style={{ maxWidth: "100%", zIndex: 0, display: "flex", flexDirection: "row" }}
			>
				<IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
					<MoreVert />
				</IconButton>
				<Tabs
					value={filter}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="desktop"
				>
					<Tab label="Medicină internă" value="MED_INT" />
					<Tab label="Psihiatrie" value="PSHT" />
					<Tab label="Oftalmologie" value="OFT" />
					<Tab label="ORL" value="ORL" />
					<Tab label="Neurologie" value="NEURO" />
					<Tab label="Penumologie" value="PNEUM" />
					<Tab label="Chirurgie" value="CHIR" />
					<Tab label="Medicina muncii" value="MED_MUN" />
					{/* <Tab label="Dermatologie" value="DERM" />
					<Tab label="Psihologie" value="PSHL" />
					<Tab label="Ginecologie" value="GINECO" /> */}
				</Tabs>
			</AppBar>
		</>
	);
};

export { RegistriesMenu as default };
