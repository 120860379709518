import React, { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	Button,
	TextField,
	MenuItem,
	CircularProgress,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import getError from "../../../../utils/getError";
import { green, red } from "@material-ui/core/colors";
import { Done, Save, Error } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		// opacity: 0.1
	},
	details: {
		display: "flex",
	},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative",
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonError: {
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[700],
		},
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}));

const UserDetails = (props) => {
	const {
		className,
		language,
		user,
		register,
		errors,
		setValue,
		role,
		handleRoleChange,
		onExecute,
		handleSubmit,
		buttonStatus,
		...rest
	} = props;

	const classes = useStyles();

	const onSubmit = (data, e) => {
		// e.preventDefault();
		onExecute(data);
	};

	useEffect(() => {
		register({ name: "role" });
		setValue("role", user?.role);
	}, [register]);

	useEffect(() => {
		setValue("role", user?.role);
	}, [user]);

	const buttonClassname = clsx({
		[classes.buttonStd]: true,
		[classes.buttonSuccess]: buttonStatus === true,
		[classes.buttonError]: buttonStatus === "error",
	});

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader subheader={lang.DATA_EDIT[language]} title={lang.USER[language]} />
			<Divider />
			<CardContent>
				<div className={classes.details}>
					<Grid container spacing={3}>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label={lang.FIRST_NAME[language]}
								margin="dense"
								name="profile.firstName"
								required
								inputRef={register({ required: true })}
								variant="outlined"
								disabled={user?.active === false}
								error={!!errors?.profile?.firstName}
								helperText={!!errors?.profile?.firstName ? getError(errors.profile.firstName, language) : null}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label={lang.LAST_NAME[language]}
								margin="dense"
								name="profile.lastName"
								required
								inputRef={register({ required: true })}
								variant="outlined"
								disabled={user?.active === false}
								error={!!errors?.profile?.lastName}
								helperText={!!errors?.profile?.lastName ? getError(errors.profile.lastName, language) : null}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label={lang.EMAIL[language]}
								margin="dense"
								name="profile.email"
								inputRef={register}
								variant="outlined"
								disabled={user?.active === false}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								fullWidth
								label={lang.ROLE[language]}
								margin="dense"
								name="role"
								onChange={handleRoleChange}
								select
								value={role ? role : ""}
								variant="outlined"
								disabled={user?.active === false}
								helperText={role !== user?.role && role === "ADMIN" ? lang.ADMIN_WARNING[language] : null}
							>
								<MenuItem value="ADMIN">ADMIN</MenuItem>
								<MenuItem value="USER">USER</MenuItem>
							</TextField>
						</Grid>
					</Grid>
				</div>
			</CardContent>
			<Divider />
			<CardActions>
				<div className={classes.wrapper}>
					<Button
						color="primary"
						variant={buttonStatus === true ? "contained" : "outlined"}
						disabled={buttonStatus === "loading" || user?.active === false}
						className={buttonClassname}
						onClick={handleSubmit(onSubmit)}
					>
						{buttonStatus === false && <Save fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === "error" && <Error fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === true && <Done fontSize="small" className={classes.leftIcon} />}
						{lang.SAVE[language]}
					</Button>
					{buttonStatus === "loading" && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</CardActions>
		</Card>
	);
};

UserDetails.propTypes = {
	className: PropTypes.string,
};

export default UserDetails;
