import React from "react";
import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	ExpansionPanelActions,
	Divider,
	Button,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import { ExpandMore, ChevronRight } from "@material-ui/icons";
import clsx from "clsx";
import * as lang from "../../../../../../utils/strings";

const statePriority = {
	OPEN: 1,
	DONE: 2,
	DELIVERED: 3,
	CLOSED: 4,
};

const ExpansionTab = (props) => {
	const { classes, state, type, title, content, expanded, handleChange, handleSubmit, buttonStatus, language } = props;

	const buttonClassname = clsx({
		[classes.buttonStd]: true,
		[classes.buttonSuccess]: buttonStatus === true,
		[classes.buttonError]: buttonStatus === "error",
	});

	return (
		<ExpansionPanel
			className={classes.root}
			expanded={expanded === type}
			onChange={handleChange(type)}
			disabled={statePriority[state] < statePriority[type]}
		>
			<ExpansionPanelSummary expandIcon={(expanded !== type || type !== state) && <ExpandMore />}>
				<Typography className={classes.heading}>{title}</Typography>
				{/* <Typography className={classes.secondaryHeading}>
					{state}-{buttonStatus}
				</Typography> */}
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>{content}</ExpansionPanelDetails>
			<Divider />
			<ExpansionPanelActions>
				<div className={classes.wrapper}>
					<Button
						size="small"
						color="primary"
						disabled={buttonStatus !== false}
						onClick={handleSubmit}
						className={buttonClassname}
					>
						{type === "DELIVERED" && lang.DONE[language]}
						{type !== "DELIVERED" && lang.NEXT_STEP[language]}
						{type !== "DELIVERED" && <ChevronRight className={classes.rightIcon} />}
					</Button>
					{buttonStatus === "loading" && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</ExpansionPanelActions>
		</ExpansionPanel>
	);
};

export { ExpansionTab as default };
