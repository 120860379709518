import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import Profile from "./components/Profile";
import SidebarNav from "./components/Sidebarnav";
import * as lang from "../utils/strings";
import {
	AssignmentInd,
	Settings,
	People,
	Dashboard,
	Business,
	Assignment,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up("lg")]: {
			marginTop: 64,
			height: "calc(100% - 64px)",
		},
	},
	root: {
		backgroundColor: theme.palette.white,
		display: "flex",
		flexDirection: "column",
		height: "100%",
		padding: theme.spacing(2),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	nav: {
		marginBottom: theme.spacing(2),
	},
}));

const Sidebar = (props) => {
	const { open, variant, onClose, className, me, language } = props;

	const classes = useStyles();

	const pages = [
		{
			title: lang.DASHBOARD[language],
			href: "/dashboard",
			icon: <Dashboard />,
			render: true,
		},
		{
			title: lang.USERS[language],
			href: "/users",
			icon: <People />,
			render: me?.role === "ADMIN",
		},
		{
			title: lang.CUSTOMERS[language],
			href: "/customers",
			icon: <AssignmentInd />,
			render: true,
		},
		{
			title: lang.COMPANIES[language],
			href: "/companies",
			icon: <Business />,
			render: true,
		},
		{
			title: lang.REGISTRIES[language],
			href: "/registries",
			icon: <Assignment />,
			render: true,
		},
		{
			title: lang.SETTINGS[language],
			href: "/settings",
			icon: <Settings />,
			render: true,
		},
	];

	return (
		<Drawer
			anchor="left"
			classes={{ paper: classes.drawer }}
			onClose={onClose}
			open={open}
			variant={variant}
		>
			<div className={clsx(classes.root, className)}>
				<Profile data={me} onClose={onClose} />
				<Divider className={classes.divider} />
				<SidebarNav
					className={classes.nav}
					pages={pages}
					onClose={onClose}
				/>
				{/* <UpgradePlan /> */}
			</div>
		</Drawer>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired,
};

export default Sidebar;
