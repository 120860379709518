import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import CustomDrawer from "../CustomDrawer";

const UsersMenu = (props) => {
	const { handleClose, anchorEl, menuList } = props;
	const theme = useTheme();
	const tiny = useMediaQuery(theme.breakpoints.down("xs"));

	const handleClick = (func) => {
		func();
		handleClose();
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl) && !tiny}
				onClose={handleClose}
			>
				{menuList?.filter(item => item.render !== false).map((item, index) => (
					<MenuItem
						key={index}
						disabled={!!item.disabled}
						onClick={() => handleClick(item.action)}
					>
						{item.icon} {item.title}
					</MenuItem>
				))}
			</Menu>
			<CustomDrawer tiny={tiny} {...props} />
		</React.Fragment>
	);
};

UsersMenu.propTypes = {
	handleClose: PropTypes.func.isRequired,
	menuList: PropTypes.array,
	anchorEl: PropTypes.object,
};

export default UsersMenu;
