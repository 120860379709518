import React, { useState } from "react";
import * as lang from "../../../../utils/strings";
import { TextField, InputAdornment, Grid, IconButton, Typography, ListSubheader, Divider } from "@material-ui/core";
import { Search, Clear } from "@material-ui/icons";
import { CustomModal, VirtualizedSelect } from "../../../../components";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { COMPANY_SEARCH_QUERY } from "../../../../requests";
import { CompaniesTable } from "../../../../Views/Companies/components";
import { AddressForm, CompanyForm } from "../../..";
import getError from "../../../../utils/getError";

const CompanySearch = (props) => {
	const {
		language,
		setValue,
		register,
		unregister,
		errors,
		includeCompany,
		exists,
		setExists,
		jobExists,
		setJobExists,
		jobs,
		custExists,
		clearError,
		selectedCust,
		noJobs,
	} = props;
	const [searchQuery, setSearchQuery] = useState("");
	const [searchJob, setJobSearch] = useState("");

	const { data } = useQuery(COMPANY_SEARCH_QUERY, {
		variables: { query: null },
		fetchPolicy: "no-cache",
	});

	const handleSearchChange = (event, value, reason) => {
		if (reason === "select-option") {
			setSearchQuery(value.name);
			register({ name: "company.id" }, { required: true });
			setValue("company", { id: value.id });
			clearError("company.id");
			unregister("company.address.region");
			unregister("company.address.city");
		}
		if (["blur", "clear", "remove-option"].includes(reason)) {
			setValue("company.id", "");
			unregister("company");
			setSearchQuery("");
		}
	};
	const handleSearchJobChange = (event, value, reason) => {
		if (reason === "select-option") {
			setJobSearch(value.name);
			register({ name: "job" }, { required: true });
			clearError("job");
			unregister("company.id");
			unregister("company.address.region");
			unregister("company.address.city");
			setValue("job", value.id);
		}
		if (["blur", "clear", "remove-option"].includes(reason)) {
			setValue("job", "");
			setJobSearch("");
		}
	};

	React.useEffect(() => {
		setJobSearch("");
		setSearchQuery("");
		console.log("CLEAR!!!");
	}, [selectedCust, setJobSearch]);

	return (
		<React.Fragment>
			{!noJobs && custExists && !exists && (
				<Grid item xs={12}>
					<Typography
						variant="caption"
						color="secondary"
						style={{ cursor: "pointer" }}
						onClick={() => {
							if (exists) unregister("job");
							else register({ name: "job" }, { required: true });
							clearError("job");
							setJobExists(!jobExists);
						}}
						align="center"
						gutterBottom
					>
						{jobExists ? "Job nou?" : "Job existent?"}
					</Typography>
				</Grid>
			)}
			{jobExists ? (
				<Grid item xs={12}>
					<VirtualizedSelect
						textValue
						disabled={jobs?.length === 0}
						autoComplete={false}
						autoHighlight={false}
						value={searchJob}
						language={language}
						options={jobs ? jobs : []}
						onChange={handleSearchJobChange}
						textFieldProps={{
							label: lang.JOB[language],
							InputProps: {
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							},
							disabled: jobs?.length === 0,
							placeholder: lang.SEARCH_EXISTING_JOB[language],
							error: !!errors.job,
							helperText: !!errors.job ? getError(errors.job, language) : null,
						}}
						renderOption={(option) => <Typography noWrap>{`${option.job} @ ${option.company.name}`}</Typography>}
						getOptionLabel={(option) => (option?.job ? option.job : "")}
						renderGroup={(params) => [
							<ListSubheader key={params.key} component="div">
								{params.group}
							</ListSubheader>,
							params.children,
						]}
						groupBy={(option) => option.job[0]}
					/>
				</Grid>
			) : (
				<Grid item xs={12} md={includeCompany ? 12 : 6}>
					<TextField
						fullWidth
						required
						label={lang.JOB[language]}
						name="job"
						inputRef={register({ required: true })}
						variant="outlined"
						error={!!errors.job}
						helperText={!!errors.job ? getError(errors.job, language) : null}
					/>
				</Grid>
			)}
			{includeCompany && !jobExists && (
				<Grid item xs={12}>
					<Typography
						variant="caption"
						color="secondary"
						style={{ cursor: "pointer" }}
						onClick={() => {
							if (exists) unregister("company.id");
							else register({ name: "company.id" }, { required: true });
							clearError("company.id");
							setExists(!exists);
						}}
						align="center"
						gutterBottom
					>
						{exists ? "Companie nouă?" : "Companie existentă?"}
					</Typography>
				</Grid>
			)}
			{includeCompany &&
				!jobExists &&
				(exists ? (
					<Grid item xs={12}>
						<VirtualizedSelect
							// freeSolo
							textValue
							autoComplete={false}
							autoHighlight={false}
							value={searchQuery}
							language={language}
							options={data?.companies?.companies ? data.companies.companies : []}
							onChange={handleSearchChange}
							textFieldProps={{
								label: lang.COMPANY[language],
								InputProps: {
									startAdornment: (
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									),
								},
								placeholder: lang.SEARCH_EXISTING_COMPANY[language],
								error: !!errors?.company?.id,
								helperText: !!errors?.company?.id ? getError(errors.company.id, language) : null,
							}}
							renderOption={(option) => <Typography noWrap>{option.name}</Typography>}
							getOptionLabel={(option) => (option?.name ? option.name : "")}
							renderGroup={(params) => [
								<ListSubheader key={params.key} component="div">
									{params.group}
								</ListSubheader>,
								params.children,
							]}
							groupBy={(option) => option.name[0]}
						/>
					</Grid>
				) : (
					<CompanyForm {...props} companyExists={exists} standalone={false} />
				))}
		</React.Fragment>
	);
};

export { CompanySearch as default };
