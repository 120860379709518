import React from "react";
import PropTypes from "prop-types";
import { MoreVert, CheckBoxRounded, Clear, AddBox, ExpandMore, DeleteForever, Save } from "@material-ui/icons";
import { IconButton, Typography, CardHeader, Divider } from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CustomMenu, CustomModal } from "../../../../components";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { DELETE_COMPANY_EMPLOYEES_QUERY } from "../../../../requests";

const CompanyEmployeesMenu = (props) => {
	const { language, classes, onOpen, onExport, onSelection, selection, selected, refetch, count } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const [deleteEmployees] = useMutation(DELETE_COMPANY_EMPLOYEES_QUERY);

	return (
		<React.Fragment>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.ADD_EMPLOYEE[language],
						icon: <AddBox className={classes.leftButton} />,
						render: !selection,
						action: onOpen,
					},
					{
						title: lang.SELECT[language],
						icon: <CheckBoxRounded className={classes.leftButton} />,
						render: !selection,
						disabled: count === 0,
						action: onSelection,
					},
					{
						title: lang.EXPORT[language],
						icon: <Save className={classes.leftButton} />,
						render: !selection,
						action: onExport,
					},
					{
						title: lang.DELETE[language],
						icon: <DeleteForever className={classes.leftButton} />,
						render: selection,
						disabled: selected.length === 0,
						action: () => setOpenModal(true),
					},
				]}
			/>
			<CustomModal
				open={openModal}
				title={lang.DELETE_EMPLOYEES[language]}
				content={lang.CONFIRM_DELETE_EMPLOYEES(selected.length)[language]}
				language={language}
				onClose={() => setOpenModal(false)}
				onExecute={() => {
					deleteEmployees({
						variables: {
							data: selected,
						},
					})
						.then((response) => {
							enqueueSnackbar(lang.EMPLOYEES_DELETED_SUCCESS[language], {
								variant: "success",
							});
							refetch();
							onSelection();
							setOpenModal(false);
						})
						.catch((error) => {
							onSelection();
							enqueueSnackbar(lang.SOMETHING_WRONG[language], {
								variant: "error",
							});
							// setButtonStatus("error");
						});
				}}
			/>
			<CardHeader
				title={
					<div className={classes.tableExtraHeaderSelect}>
						<div className={classes.tableExtraHeader}>
							{selection ? (
								<IconButton className={classes.leftButton} onClick={onSelection} size="small">
									<Clear fontSize="small" />
								</IconButton>
							) : (
								<IconButton className={classes.leftButton} onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
									<MoreVert fontSize="small" />
								</IconButton>
							)}

							<Typography variant="h4" className={classes.tableTitle}>
								{lang.EMPLOYEES[language]}
							</Typography>
						</div>
						{selection && (
							<div className={classes.tableExtraHeader}>
								<Typography variant="body1" color="textSecondary" className={classes.leftButton}>
									{`${selected?.length} items selected`}
								</Typography>
								{selection && (
									<IconButton
										onClick={(e) => setAnchorEl(e.currentTarget)}
										size="small"
										// disabled={selected.length === 0}
									>
										<ExpandMore fontSize="small" />
									</IconButton>
								)}
							</div>
						)}
					</div>
				}
			/>
		</React.Fragment>
	);
};

CompanyEmployeesMenu.propTypes = {
	language: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
};

export { CompanyEmployeesMenu as default };
