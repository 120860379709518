import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Typography } from "@material-ui/core";
import * as lang from "../../utils/strings";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: style.top + LISTBOX_PADDING,
		},
	});
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

ListboxComponent.propTypes = {
	children: PropTypes.node,
};

function random(length) {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";

	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}

	return result;
}

const useStyles = makeStyles({
	listbox: {
		boxSizing: "border-box",
		"& ul": {
			padding: 0,
			margin: 0,
		},
	},
});

const OPTIONS = Array.from(new Array(10000))
	.map(() => random(10 + Math.ceil(Math.random() * 20)))
	.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

const renderGroup = (params) => [
	<ListSubheader key={params.key} component="div">
		{params.group}
	</ListSubheader>,
	params.children,
];

const AutoCompleteTextField = (props) => {
	const classes = useStyles();
	const {
		language,
		freeSolo,
		options,
		onChange,
		disabled,
		value,
		textFieldProps,
		renderOption,
		getOptionLabel,
		renderGroup,
		groupBy,
		autoComplete,
		autoHighlight,
	} = props;

	const defaultRenderOption = (option) => <Typography noWrap>{option}</Typography>;
	const defaultGetOptionLabel = (option) => option;
	const defaultRenderGroup = (params) => [params.children];
	const defaultGroupBy = (option) => option[0];

	return (
		<Autocomplete
			fullWidth
			freeSolo={freeSolo}
			disabled={disabled}
			autoComplete={autoComplete === false ? false : true}
			autoHighlight={autoHighlight === false ? false : true}
			noOptionsText={lang.NO_RESULTS[language]}
			disableListWrap
			classes={classes}
			ListboxComponent={ListboxComponent}
			renderGroup={renderGroup ? renderGroup : defaultRenderGroup}
			options={options}
			groupBy={groupBy ? groupBy : defaultGroupBy}
			// value={value}
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					InputProps={{ ...params.InputProps, ...textFieldProps.InputProps }}
					variant="outlined"
					disabled
					value={value}
				/>
			)}
			renderOption={renderOption ? renderOption : defaultRenderOption}
			getOptionLabel={getOptionLabel ? getOptionLabel : defaultGetOptionLabel}
			onChange={onChange}
		/>
	);
};
const AutoCompleteClasic = (props) => {
	const classes = useStyles();
	const {
		language,
		freeSolo,
		options,
		onChange,
		disabled,
		value,
		textFieldProps,
		renderOption,
		getOptionLabel,
		renderGroup,
		groupBy,
		autoComplete,
		autoHighlight,
		multiple,
		disableCloseOnSelect,
	} = props;

	const defaultRenderOption = (option) => <Typography noWrap>{option}</Typography>;
	const defaultGetOptionLabel = (option) => option;
	const defaultRenderGroup = (params) => [params.children];
	const defaultGroupBy = (option) => option[0];

	return (
		<Autocomplete
			fullWidth
			disableCloseOnSelect={disableCloseOnSelect}
			multiple={multiple}
			freeSolo={freeSolo}
			disabled={disabled}
			autoComplete={autoComplete === false ? false : true}
			autoHighlight={autoHighlight === false ? false : true}
			noOptionsText={lang.NO_RESULTS[language]}
			disableListWrap
			classes={classes}
			ListboxComponent={ListboxComponent}
			renderGroup={renderGroup ? renderGroup : defaultRenderGroup}
			options={options}
			groupBy={groupBy ? groupBy : defaultGroupBy}
			value={value}
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					InputProps={{ ...params.InputProps, ...textFieldProps.InputProps }}
					variant="outlined"
				/>
			)}
			renderOption={renderOption ? renderOption : defaultRenderOption}
			getOptionLabel={getOptionLabel ? getOptionLabel : defaultGetOptionLabel}
			onChange={onChange}
		/>
	);
};

export default (props) => {
	const { textValue } = props;
	return textValue ? <AutoCompleteTextField {...props} /> : <AutoCompleteClasic {...props} />;
};
