import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { Route } from "react-router-dom";
import RouteWithLayout from "./RouteWithLayout";
import MainLayout from "../Layout/Main";

import {
	Dashboard as DashboardView,
	UserList as UserListView,
	Account as AccountView,
	Settings as SettingsView,
	SignIn as SignInView,
	NotFound as NotFoundView,
	Customer as CustomerView,
	Customers as CustomersView,
	Companies as CompaniesView,
	User as UserView,
	Company as CompanyView,
	Registries as RegistriesView,
} from "../Views";
import RouteWithoutLayout from "./RouteWithoutLayout";

const Routes = (props) => {
	return (
		<Switch>
			<Redirect exact from="/" to="/dashboard" />
			<RouteWithLayout
				component={DashboardView}
				exact
				layout={MainLayout}
				path="/dashboard"
				{...props}
			/>
			<RouteWithLayout
				component={UserListView}
				exact
				layout={MainLayout}
				path="/users"
				{...props}
			/>
			<RouteWithLayout
				component={UserView}
				exact={false}
				layout={MainLayout}
				path="/user/"
				{...props}
			/>
			<RouteWithLayout
				component={CustomersView}
				exact
				layout={MainLayout}
				path="/customers"
				{...props}
			/>
			<RouteWithLayout
				component={CustomerView}
				exact={false}
				layout={MainLayout}
				path="/customer"
				{...props}
			/>
			<RouteWithLayout
				component={CompaniesView}
				exact
				layout={MainLayout}
				path="/companies"
				{...props}
			/>
			<RouteWithLayout
				component={CompanyView}
				exact={false}
				layout={MainLayout}
				path="/company/"
				{...props}
			/>
			<RouteWithLayout
				component={AccountView}
				exact
				layout={MainLayout}
				path="/account"
				{...props}
			/>
			<RouteWithLayout
				component={SettingsView}
				exact
				layout={MainLayout}
				path="/settings"
				{...props}
			/>
			<RouteWithLayout
				component={RegistriesView}
				exact
				layout={MainLayout}
				path="/registries"
				{...props}
			/>
			<RouteWithoutLayout
				component={SignInView}
				exact
				path="/sign-in"
				{...props}
			/>
			<Route component={NotFoundView} exact path="/not-found" />
			<Redirect to="/not-found" />
		</Switch>
	);
};

export default Routes;
