import { gql } from "apollo-boost";

const companies = gql`
	query SearchCompaniesQuery($where: CompanyWhereInput, $pagination: PaginationInput, $orderBy: CompanyOrderByInput) {
		companies(where: $where, pagination: $pagination, orderBy: $orderBy) {
			count
			companies(where: $where, pagination: $pagination, orderBy: $orderBy) {
				id
				name
				cif
				phone
				address {
					country
					region
					city
					street
					streetNo
					building
					entrance
					apartment
					floor
					zip
				}
				createdAt
			}
		}
	}
`;
const company = gql`
	query SearchCompany($id: ID!) {
		company(id: $id) {
			id
			name
			cif
			phone
			address {
				id
				country
				region
				city
				street
				streetNo
				building
				entrance
				apartment
				floor
				zip
			}
			employees {
				id
				job
				employee {
					id
					firstName
					lastName
					phone
					gender
				}
				createdAt
			}
			createdAt
		}
	}
`;
const createCompany = gql`
	mutation AddCompanyQuery($data: CompanyCreateInput!) {
		createCompany(data: $data) {
			id
		}
	}
`;
const updateCompany = gql`
	mutation UpdateCompany($id: ID!, $data: CompanyUpdateInput!) {
		updateCompany(id: $id, data: $data) {
			id
		}
	}
`;

export const COMPANY_DELETE_QUERY = gql`
	mutation DeleteCompany($id: ID!) {
		deleteCompany(id: $id) {
			id
		}
	}
`;

export { companies as COMPANY_SEARCH_QUERY };
export { company as COMPANY_QUERY };
export { createCompany as CREATE_COMPANY_QUERY };
export { updateCompany as UPDATE_COMPANY_QUERY };
