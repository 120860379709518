import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 56,
		height: "100%",
		[theme.breakpoints.up("sm")]: {
			paddingTop: 64,
		},
	},
	shiftContent: {
		paddingLeft: 240,
	},
	content: {
		height: "100%",
	},
}));

const Main = (props) => {
	const { children, me } = props;

	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
		defaultMatches: true,
	});

	const [openSidebar, setOpenSidebar] = useState(false);

	const handleSidebarOpen = () => {
		setOpenSidebar(true);
	};

	const handleSidebarClose = () => {
		setOpenSidebar(false);
	};

	const shouldOpenSidebar = isDesktop ? true : openSidebar;

	return (
		<div
			className={clsx({
				[classes.root]: true,
				[classes.shiftContent]: isDesktop,
			})}
		>
			<Topbar onSidebarOpen={handleSidebarOpen} {...props} />
			<Sidebar
				{...props}
				onClose={handleSidebarClose}
				open={shouldOpenSidebar}
				variant={isDesktop ? "persistent" : "temporary"}
			/>
			<main className={classes.content}>
				{children}
				{/* <Footer /> */}
			</main>
		</div>
	);
};

Main.propTypes = {
	children: PropTypes.node,
};

export default Main;
