import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	DialogContentText,
	useMediaQuery,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import * as lang from "../../utils/strings";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	form: {
		// height: "100%",
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignContent: "space-between",
	},
	button: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		// bottom: theme.spacing(0),
		right: theme.spacing(1),
	},
	customBackground: {
		backgroundColor: theme.palette.background.default,
	},
}));

// const Transition = React.createRef((props, ref) => (
// 	<Slide direction="up" ref={ref} {...props} />
// ));

const CustomModal = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const {
		customBackground,
		nonDismissible,
		title,
		content,
		onExecute,
		handleSubmit,
		language,
		form,
		disableExecute,
		...rest
	} = props;
	rest.fullScreen = rest.fullScreen || fullScreen;

	const onSubmit = (data, e) => {
		// e.preventDefault();
		console.log(data);
		onExecute(data);
	};

	return (
		<Dialog
			{...rest}
			disableBackdropClick={nonDismissible}
			disableEscapeKeyDown={nonDismissible}
			TransitionComponent={Slide}
			TransitionProps={{
				direction: "up",
			}}
			closeAfterTransition={true}
		>
			<div
				className={clsx(classes.form, {
					[classes.customBackground]: customBackground,
				})}
				style={{ height: rest.fullScreen ? "100%" : "auto" }}
			>
				{typeof title === "string" && (
					<DialogTitle>{title}</DialogTitle>
				)}

				<DialogContent
					style={{
						maxHeight:
							!rest.fullScreen && !fullScreen ? "70vh" : "auto",
					}}
				>
					{typeof content === "string" ? (
						<DialogContentText>{content}</DialogContentText>
					) : (
						content
					)}
				</DialogContent>

				<DialogActions className={classes.button}>
					<>
						<Button onClick={rest.onClose}>
							<Typography color="error">
								{lang.BACK[language]}
							</Typography>
						</Button>
						{!!onExecute &&
							(form ? (
								<Button onClick={handleSubmit(onSubmit)}>
									<Typography>
										{lang.DONE[language]}
									</Typography>
								</Button>
							) : (
								<Button onClick={onExecute} disabled={disableExecute}>
									<Typography>
										{lang.DONE[language]}
									</Typography>
								</Button>
							))}
					</>
				</DialogActions>
			</div>
		</Dialog>
	);
};

CustomModal.propTypes = {
	language: PropTypes.string.isRequired,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onExecute: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
	title: PropTypes.string,
	nonDismissible: PropTypes.bool,
	disableExecute: PropTypes.bool,
	form: PropTypes.bool,
	handleSubmit: PropTypes.func,
};

export { CustomModal as default };
