import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
	Checkbox,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { CustomerDocsMenu } from "./components";
import { NoResults } from "../../../../components";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		// minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableExtraHeaderSelect: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const CustomerDocs = (props) => {
	const {
		className,
		data,
		loading,
		language,
		onOpen,
		onExport,
		onRowClick,
		custom,
		count,
		refetch,
		fetchData,
		called,
		customerId,
	} = props;

	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [docFilters, setDocFilters] = useState(null);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page, docFilters);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0, docFilters);
		setRowsPerPage(value);
	};
	const toggleFilter = (filter) => {
		let newFilter = filter
			? [
					{ employee: { id: customerId } },
					{ state_in: ["OPEN", "DONE", "DELIVERED"] },
			  ]
			: [{ employee: { id: customerId } }, { state: "CLOSED" }];
		console.log(newFilter);
		setDocFilters(newFilter);
		fetchData(rowsPerPage, 0, newFilter);
	};

	useEffect(() => {
		if (customerId)
			setDocFilters([
				{ employee: { id: customerId } },
				{ state_in: ["OPEN", "DONE", "DELIVERED"] },
			]);
	}, [customerId]);

	useEffect(() => {
		if (docFilters) fetchData(rowsPerPage, 0, docFilters);
	}, [called, docFilters, rowsPerPage]);

	return (
		<Card className={clsx(classes.root, className)}>
			{custom && (
				<>
					<CustomerDocsMenu
						refetch={refetch}
						count={count}
						language={language}
						classes={classes}
						onOpen={onOpen}
						onExport={onExport}
						filter={
							docFilters?.length === 2 &&
							docFilters[1].state !== undefined
						}
						switchAction={toggleFilter}
					/>
					<Divider />
				</>
			)}
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{!loading && data?.length > 0 && (
								<TableHead>
									<TableRow>
										<TableCell>ID</TableCell>
										<TableCell>
											{lang.FULL_NAME[language]}
										</TableCell>
										<TableCell>
											{lang.JOB[language]}
										</TableCell>
										<TableCell>
											{lang.TYPE[language]}
										</TableCell>
										<TableCell>
											{lang.STATE[language]}
										</TableCell>
										<TableCell>
											{lang.CREATE_DATE[language]}
										</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : data?.length === 0 ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<NoResults
												message={
													lang.NO_RESULTS[language]
												}
											/>
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{data.slice(0, rowsPerPage).map((item) => (
										<TableRow
											onClick={() => onRowClick(item)}
											className={classes.tableRow}
											hover
											key={item.id}
										>
											<TableCell>
												<Typography variant="body1">
													{item?.id}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1">
													{item?.employee?.firstName}{" "}
													{item?.employee?.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												{item?.job?.job === undefined
													? "-"
													: `${item?.job?.job} @ ${item?.job?.company?.name}`}
											</TableCell>
											<TableCell>
												{item?.type
													? lang[item.type][language]
													: ""}
											</TableCell>
											<TableCell>{item?.state}</TableCell>
											<TableCell>
												{moment(item.createdAt).format(
													"DD/MM/YYYY"
												)}
											</TableCell>
										</TableRow>
									))}

									{page ===
										Math.floor(count / rowsPerPage) && (
										<TableRow
											style={{
												height:
													53 *
													(rowsPerPage -
														(count % rowsPerPage)),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
					language={language}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	// const { language, rowsPerPage, page, count } = props;
	// const from = page * rowsPerPage + 1;
	// const to = count % rowsPerPage === 0 ? rowsPerPage : count % rowsPerPage;
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
			// labelDisplayedRows={() =>
			// 	lang.TABLE_DISPLAY_ROWS(from, to, count)[language]
			// }
			// labelRowsPerPage={lang.TABLE_ROWS_PAGE[language]}
		/>
	);
}

CustomerDocs.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default CustomerDocs;
