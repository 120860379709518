import React from "react";
import PropTypes from "prop-types";
import { Typography, CardHeader, Switch, FormControlLabel, IconButton } from "@material-ui/core";
import * as lang from "../../../../../../utils/strings";
import { CustomMenu } from "../../../../../../components";
import { Save, MoreVert } from "@material-ui/icons";

const CustomerDocsMenu = (props) => {
	const { language, classes, filter, switchAction, onExport } = props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	return (
		<React.Fragment>
			<CustomMenu
				language={language}
				anchorEl={anchorEl}
				handleClose={() => setAnchorEl(null)}
				menuList={[
					{
						title: lang.EXPORT[language],
						icon: <Save className={classes.leftButton} />,
						action: onExport,
					},
				]}
			/>
			<CardHeader
				title={
					<div className={classes.tableExtraHeaderSelect}>
						<div className={classes.tableExtraHeader}>
							<IconButton className={classes.leftButton} onClick={(e) => setAnchorEl(e.currentTarget)} size="small">
								<MoreVert fontSize="small" />
							</IconButton>
							<Typography variant="h4" className={classes.tableTitle}>
								{lang.DOCUMENTS[language]}
							</Typography>
						</div>

						<div className={classes.tableExtraHeader}>
							<FormControlLabel
								control={<Switch checked={filter} onChange={() => switchAction(filter)} color="secondary" />}
								label={<Typography variant="caption">{lang.CLOSED_DOCS_ONLY[language]}</Typography>}
							/>
						</div>
					</div>
				}
			/>
		</React.Fragment>
	);
};

CustomerDocsMenu.propTypes = {
	language: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
};

export { CustomerDocsMenu as default };
