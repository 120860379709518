import { gql } from "apollo-boost";

export const CUSTOMER_QUERY = gql`
	query Customer($id: ID!) {
		customer(id: $id) {
			id
			firstName
			lastName
			gender
			phone
			residence {
				id
				country
				region
				city
				street
				streetNo
				building
				entrance
				apartment
				floor
				zip
				addressString
			}
			birthPlace {
				id
				country
				region
				city
				addressString
			}
			ci {
				cnp
				idSeries
				idNo
				idIssuer
				idDate
			}
			birthDate
			companies {
				id
				job
				company {
					id
					name
					phone
					address {
						addressString
					}
				}
				createdAt
			}
			createdAt
		}
	}
`;
export const CUSTOMER_SEARCH_QUERY = gql`
	query SearchCustomersQuery($where: CustomerWhereInput, $pagination: PaginationInput, $orderBy: CustomerOrderByInput) {
		customers(where: $where, pagination: $pagination, orderBy: $orderBy) {
			count
			index(where: $where) {
				id
			}
			customers(where: $where, pagination: $pagination, orderBy: $orderBy) {
				id
				firstName
				lastName
				gender
				phone
				birthDate
				ci {
					cnp
					idSeries
					idNo
				}
				companies {
					id
					job
					company {
						id
						name
						cif
					}
				}
				createdAt
			}
		}
	}
`;
export const CUSTOMER_UPDATE_QUERY = gql`
	mutation UpdateCustomer($id: ID!, $data: CustomerUpdateInput!) {
		updateCustomer(id: $id, data: $data) {
			id
			firstName
			lastName
			phone
			gender
			ci {
				cnp
				idSeries
				idNo
			}
			birthDate
		}
	}
`;
export const CUSTOMER_DELETE_QUERY = gql`
	mutation DeleteCustomer($id: ID!) {
		deleteCustomer(id: $id) {
			id
		}
	}
`;
