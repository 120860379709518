export const FIRST_NAME = {
    ro: "Prenume",
    en: "First name",
};
export const LAST_NAME = {
    ro: "Nume",
    en: "Last name",
};
export const FULL_NAME = {
    ro: "Nume",
    en: "Name",
};
export const USER = {
    ro: "Utilizator",
    en: "User",
};
export const USERNAME = {
    ro: "Utilizator",
    en: "Username",
};
export const EMAIL = {
    ro: "E-Mail",
    en: "E-Mail",
};
export const ROLE = {
    ro: "Rol",
    en: "Role",
};
export const CREATE_DATE = {
    ro: "Dată creare",
    en: "Create date",
};
export const FROM_DATE = {
    ro: "De la",
    en: "From date",
};
export const TO_DATE = {
    ro: "Până la",
    en: "To date",
};
export const ACTUAL_CREATE_DATE = {
    ro: "Dată introducere",
    en: "Actual Create date",
};
export const CI_DATE = {
    ro: "Dată eliberare",
    en: "Issuing date",
};
export const CI_ISSUER = {
    ro: "Eliberat de",
    en: "Issuer",
};
export const CI1 = {
    ro: "Serie CI",
    en: "ID Series",
};
export const CI2 = {
    ro: "Nr. CI",
    en: "ID No.",
};
export const NEXT_DATE = {
    ro: "Următorul control",
    en: "Next checkup",
};
export const CHECKUP_DATE = {
    ro: "Dată consult",
    en: "Checkup date",
};
export const AUTO_ABLE = {
    ro: "Apt grupa",
    en: "Apt",
};
export const AUTO_UNABLE = {
    ro: "Inapt grupa",
    en: "Unapt",
};
export const NOTE = {
    ro: "Observații",
    en: "Note",
};
export const DIAGNOSTIC = {
    ro: "Diagnostic și recomandări",
    en: "Diagnostic",
};
export const CONDITIONS = {
    ro: "Afecțiuni",
    en: "Conditions",
};
export const SIGN_IN = {
    ro: "Autentificare",
    en: "Sign In",
};
export const PASSWORD = {
    ro: "Parolă",
    en: "Password",
};
export const CURRENT_PASSWORD = {
    ro: "Parola curentă",
    en: "Current password",
};
export const NEW_PASSWORD = {
    ro: "Parola nouă",
    en: "New password",
};
export const CONFIRM_PASSWORD = {
    ro: "Confirmare parolă",
    en: "Confirm password",
};
export const USERS = {
    ro: "Utilizatori",
    en: "Users",
};
export const PHONE = {
    ro: "Telefon",
    en: "Phone",
};
export const GENDER = {
    ro: "Sex",
    en: "Gender",
};
export const CNP = {
    ro: "CNP",
    en: "CNP",
};
export const BIRTH_DATE = {
    ro: "Data nașterii",
    en: "Date of birth",
};
export const MALE = {
    ro: "Masculin",
    en: "Male",
};
export const FEMALE = {
    ro: "Feminin",
    en: "Female",
};
export const PARENTF = {
    ro: "Nume complet tată",
    en: "Father's name",
};
export const PARENTM = {
    ro: "Nume complet mamă",
    en: "Mother's name",
};
export const ADD_USER = {
    ro: "Utilizator nou",
    en: "New user",
};
export const PATIENT = {
    ro: "Pacient",
    en: "Patient",
};
export const EDIT_CUSTOMER = {
    ro: "Editare pacient",
    en: "Edit patient",
};
export const VIEW_MORE = {
    ro: "Alte detalii",
    en: "View more",
};
export const ADD_CUSTOMER = {
    ro: "Pacient nou",
    en: "New patient",
};
export const SELECT = {
    ro: "Selectează",
    en: "Select",
};
export const DELETE = {
    ro: "Șterge",
    en: "Delete",
};
export const ADD_EMPLOYEE = {
    ro: "Angajat nou",
    en: "New employee",
};
export const ADD_JOB = {
    ro: "Loc de muncă nou",
    en: "New job",
};
export const ADD_DOCUMENT = {
    ro: "Fișă nouă",
    en: "New document",
};
export const EXPORT = {
    ro: "Exportă",
    en: "Export",
};
export const EMPLOYEES = {
    ro: "Angajați",
    en: "Employees",
};
export const BACK = {
    ro: "Înapoi",
    en: "Back",
};
export const DONE = {
    ro: "Finalizează",
    en: "Done",
};
export const COMPANY_NAME = {
    ro: "Denumire companie",
    en: "Company name",
};
export const CIF = {
    ro: "CIF",
    en: "FIC",
};
export const COUNTRY = {
    ro: "Țară",
    en: "Country",
};
export const REGION = {
    ro: "Județ",
    en: "Region",
};
export const CITY = {
    ro: "Oraș",
    en: "City",
};
export const B_COUNTRY = {
    ro: "Țară (Naștere)",
    en: "Country (Birth)",
};
export const B_REGION = {
    ro: "Județ (Naștere)",
    en: "Region (Birth)",
};
export const B_CITY = {
    ro: "Oraș (Naștere)",
    en: "City (Birth)",
};
export const STREET = {
    ro: "Strada",
    en: "Street",
};
export const STREET_NO = {
    ro: "Nr.",
    en: "No.",
};
export const BUILDING = {
    ro: "Bloc",
    en: "Building",
};
export const ENTRANCE = {
    ro: "Scară",
    en: "Entrance",
};
export const APARTMENT = {
    ro: "Apartament",
    en: "Apartment",
};
export const FLOOR = {
    ro: "Etaj",
    en: "Floor",
};
export const ZIPCODE = {
    ro: "Cod poștal",
    en: "Zip code",
};
export const ADDRESS = {
    ro: "Adresă",
    en: "Address",
};
export const JOB = {
    ro: "Funcție",
    en: "Position",
};
export const JOBS = {
    ro: "Locuri de muncă",
    en: "Jobs",
};
export const ADMIN_WARNING = {
    ro: "Utilizatorul va avea drepturi de administrare",
    en: "User will have admin permissions",
};
export const HINT_NEW_COMPANY = {
    ro: "Adăugați o companie nouă",
    en: "Add a new company",
};
export const NEW_ENTRY = {
    ro: "Intrare nouă",
    en: "New entry",
};
export const HINT_MIN_CHAR_SEARCH = {
    ro: "Minim 3 caractere",
    en: "At least 3 characters",
};
export const CONFIRM_LOGOUT = {
    ro: "Ești sigur că vrei să te deconectezi?",
    en: "Are you sure you want to log out?",
};
export const WELCOME = (name) => ({
    ro: `Bine ai revenit, ${name}!`,
    en: `Welcome back, ${name}!`,
});

export const COMPANY = {
    ro: "Companie",
    en: "Company",
};
export const COMPANIES = {
    ro: "Companii",
    en: "Companies",
};
export const ADD_COMPANY = {
    ro: "Companie nouă",
    en: "New company",
};
export const SEARCH_EXISTING_COMPANY = {
    ro: "Căutați o companie existentă",
    en: "Search existing company",
};
export const SEARCH_EXISTING_JOB = {
    ro: "Căutați un job existentă",
    en: "Search existing job",
};
export const SEARCH_EXISTING_PATIENT = {
    ro: "Căutați un pacient existent",
    en: "Search existing patient",
};
export const LANGUAGE = {
    ro: "Limbă",
    en: "Language",
};
export const ROMANIAN = {
    ro: "Română",
    en: "Romanian",
};
export const ENGLISH = {
    ro: "Engleză",
    en: "English",
};
export const SAVE = {
    ro: "Salvează",
    en: "Save",
};
export const NEXT_STEP = {
    ro: "Pasul următor",
    en: "Next",
};
export const RESET = {
    ro: "Resetare",
    en: "Reset",
};
export const BLOCK = {
    ro: "Blocare",
    en: "Block",
};
export const UNBLOCK = {
    ro: "Deblocare",
    en: "Unblock",
};
export const CHANGE_AVATAR = {
    ro: "Modificare avatar",
    en: "Change avatar",
};
export const CONFIRM_ACTION = {
    ro: "Confirmați acțiunea",
    en: "Confirm action",
};
export const ACTION_RESET = {
    ro: "Parola acestui utilizator va fi resetată",
    en: "This user's password will reset",
};
export const DATA_EDIT = {
    ro: "Informațiile pot fi editate",
    en: "The information can be edited",
};
export const DELETE_CUSTOMER = {
    ro: "Șterge pacient",
    en: "Delete patient",
};
export const DELETE_CUSTOMER_CONFIRM = {
    ro: "Ești sigur că vrei să ștergi acest pacient? Această acțiune este ireversibilă iar datele nu vor putea fi recuperate!",
    en: "Are you sure you want to delete this patient? This action cannot be undone and the data will be unrecoverable!",
};
export const DELETE_COMPANY = {
    ro: "Șterge companie",
    en: "Delete company",
};
export const DELETE_COMPANY_CONFIRM = {
    ro: "Ești sigur că vrei să ștergi această companie? Această acțiune este ireversibilă iar datele nu vor putea fi recuperate!",
    en: "Are you sure you want to delete this company? This action cannot be undone and the data will be unrecoverable!",
};
export const ACTION_BLOCK = (type) => {
    switch (type) {
        case true:
            return {
                ro: "Contul acestui utilizator va fi blocat",
                en: "This account will be blocked",
            };
        case false:
            return {
                ro: "Contul acestui utilizator va fi deblocat",
                en: "This account will be unblocked",
            };
        default:
            return {
                ro: "",
                en: "",
            };
    }
};
export const EDIT_DOCUMENT = {
    ro: "Editare document",
    en: "Edit document",
};
export const DOWNLOAD_DOCUMENT = {
    ro: "Descarcă document",
    en: "Download document",
};
export const GO_TO = (value) => ({
    ro: `Vezi ${value.toLowerCase()}`,
    en: `Go to ${value.toLowerCase()}`,
});

export const DELETE_EMPLOYEES = {
    ro: "Eliminare angajați",
    en: "Deleting employees",
};
export const DELETE_DOCUMENTS = {
    ro: "Ștergere documente",
    en: "Deleting documents",
};
export const CONFIRM_DELETE_EMPLOYEES = (data) =>
    data === 1 ?
    {
        ro: `Ești sigur că vrei sa elimini acest angajat?`,
        en: `Are you sure you want to delete this employee?`,
    } :
    {
        ro: `Ești sigur că vrei sa elimini ${data} angajați?`,
        en: `Are you sure you want to delete ${data} employees?`,
    };
export const CONFIRM_DELETE_DOCUMENTS = (data) =>
    data === 1 ?
    {
        ro: `Ești sigur că vrei sa elimini acest document?`,
        en: `Are you sure you want to delete this document?`,
    } :
    {
        ro: `Ești sigur că vrei sa elimini ${data} documente?`,
        en: `Are you sure you want to delete ${data} documents?`,
    };

export const DELETE_JOBS = {
    ro: "Eliminare locuri de muncă",
    en: "Deleting jobs",
};
export const CONFIRM_DELETE_JOBS = (data) =>
    data === 1 ?
    {
        ro: `Ești sigur că vrei sa elimini acest loc de muncă?`,
        en: `Are you sure you want to delete this job?`,
    } :
    {
        ro: `Ești sigur că vrei sa elimini ${data} locuri de muncă?`,
        en: `Are you sure you want to delete ${data} jobs?`,
    };
export const NO_RESULTS = {
    ro: "Niciun rezultat",
    en: "No results",
};
export const TABLE_ROWS_PAGE = {
    ro: "Rânduri pe pagină",
    en: "Rows per page",
};
export const TABLE_DISPLAY_ROWS = (from, to, count) => ({
    ro: `${from}-${to} din ${count}`,
    en: `${from}-${to} of ${count}`,
});

export const FILTERS = {
    ro: "Filtrare",
    en: "Filter",
};

export const DOCUMENT_DATE = {
    ro: "Dată document",
    en: "Document date",
};