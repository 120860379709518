import React, { useState, useEffect } from "react";
import { TextField, Grid, Divider } from "@material-ui/core";
import * as lang from "../../utils/strings";
import getError from "../../utils/getError";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { REGIONS_QUERY, CITIES_QUERY } from "../../requests";
import { makeStyles } from "@material-ui/styles";
import VirtualizedSelect from "../../components/VirtualizedSelect/VirtualizedSelect";

const useStyles = makeStyles((theme) => ({
	listItem: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}));

const CustomerAddressForm = (props) => {
	const classes = useStyles();
	const defaultCountry = "România";
	const { language, register, unregister, setValue, existing, data, errors, edit, includeCountry } = props;
	const [country, setCountry] = useState(null);
	const [region, setRegion] = useState(null);
	const [city, setCity] = useState(null);
	const [bCountry, setBCountry] = useState(null);
	const [bRegion, setBRegion] = useState(null);
	const [bCity, setBCity] = useState(null);

	const handleCountrySelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.residence.country");
			unregister("customer.residence.region");
			unregister("customer.residence.city");
		} else {
			register({ name: "customer.residence.country" });
			setValue("customer.residence.country", value, true);
			setValue("customer.residence.region", "");
			setValue("customer.residence.city", "");
			setCountry(value);
			setRegion("");
			setCity("");
		}
	};
	const handleRegionSelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.residence.region");
			unregister("customer.residence.city");
		} else {
			register({ name: "customer.residence.region" });
			setValue("customer.residence.region", value, true);
			setValue("customer.residence.city", "");
			setRegion(value);
			setCity("");
		}
	};

	const handleCitySelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.residence.city");
		} else {
			register({ name: "customer.residence.city" });
			setValue("customer.residence.city", value, true);
			setCity(value);
		}
	};
	const handleBCountrySelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.birthPlace.country");
			unregister("customer.birthPlace.region");
			unregister("customer.birthPlace.city");
		} else {
			register({ name: "customer.birthPlace.country" });
			setValue("customer.birthPlace.country", value, true);
			setValue("customer.birthPlace.region", "");
			setValue("customer.birthPlace.city", "");
			setBCountry(value);
			setBRegion("");
			setBCity("");
		}
	};
	const handleBRegionSelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.birthPlace.region");
			unregister("customer.birthPlace.city");
		} else {
			register({ name: "customer.birthPlace.region" });
			setValue("customer.birthPlace.region", value, true);
			setValue("customer.birthPlace.city", "");
			setBRegion(value);
			setBCity("");
		}
	};

	const handleBCitySelect = (event, value, reason) => {
		if (reason === "clear") {
			unregister("customer.birthPlace.city");
		} else {
			register({ name: "customer.birthPlace.city" });
			setValue("customer.birthPlace.city", value, true);
			setBCity(value);
		}
	};

	const { data: regions } = useQuery(REGIONS_QUERY, {
		variables: { query: null },
	});

	const [getCities, { data: cities }] = useLazyQuery(CITIES_QUERY);
	const [getBCities, { data: bCities }] = useLazyQuery(CITIES_QUERY);

	useEffect(() => {
		register({ name: "customer.residence.country" });
		register({ name: "customer.residence.region" });
		register({ name: "customer.residence.city" });
		register({ name: "customer.birthPlace.country" });
		register({ name: "customer.birthPlace.region" });
		register({ name: "customer.birthPlace.city" });
	}, [register, existing]);

	useEffect(() => {
		if (data?.residence) {
			setCountry(data.residence.country);
			setRegion(data.residence.region);
			setCity(data.residence.city);
		}
		if (data?.birthPlace) {
			setBCountry(data.birthPlace.country);
			setBRegion(data.birthPlace.region);
			setBCity(data.birthPlace.city);
		}
	}, [data]);

	useEffect(() => {
		getCities({
			variables: {
				query: region,
			},
		});
	}, [region]);
	useEffect(() => {
		getBCities({
			variables: {
				query: bRegion,
			},
		});
	}, [bRegion]);

	useEffect(() => {
		if (window.location.pathname.split("/")[2] === undefined) {
			getCities({
				variables: {
					query: region,
				},
			});
			getBCities({
				variables: {
					query: region,
				},
			});
		}
	}, [region]);

	return (
		<React.Fragment>
			{includeCountry && (
				<Grid item xs={12} md={4}>
					<VirtualizedSelect
						freeSolo={true}
						language={language}
						options={[defaultCountry]}
						onChange={handleBCountrySelect}
						value={bCountry ? bCountry : ""}
						textFieldProps={{
							name: "company.birthPlace.country",
							margin: edit ? "dense" : "none",
							error: !!errors?.company?.birthPlace?.country,
							helperText: !!errors?.company?.birthPlace?.country
								? getError(errors.company.birthPlace.country, language)
								: null,
							label: lang.B_COUNTRY[language],
						}}
					/>
				</Grid>
			)}
			<Grid item xs={12} md={includeCountry ? 4 : 6}>
				<VirtualizedSelect
					freeSolo={includeCountry && bCountry !== defaultCountry}
					language={language}
					options={
						(regions && !includeCountry) || (regions && includeCountry && bCountry === defaultCountry)
							? regions.regions?.map((item) => item.region)
							: []
					}
					onChange={handleBRegionSelect}
					value={bRegion ? bRegion : ""}
					textFieldProps={{
						name: "customer.birthPlace.region",
						margin: edit ? "dense" : "none",
						error: !!errors?.customer?.birthPlace?.region,
						helperText: !!errors?.customer?.birthPlace?.region
							? getError(errors.customer.birthPlace.region, language)
							: null,
						label: lang.B_REGION[language],
					}}
				/>
			</Grid>
			<Grid item xs={12} md={includeCountry ? 4 : 6}>
				<VirtualizedSelect
					freeSolo={includeCountry && bCountry !== defaultCountry}
					disabled={(!bRegion && !includeCountry) || (!bRegion && includeCountry && country === defaultCountry)}
					language={language}
					options={
						(bCities && !includeCountry) || (bCities && includeCountry && bCountry === defaultCountry)
							? bCities.cities?.map((item) => item.city)
							: []
					}
					onChange={handleBCitySelect}
					value={bCity ? bCity : ""}
					textFieldProps={{
						name: "customer.birthPlace.city",
						margin: edit ? "dense" : "none",
						error: !!errors?.customer?.birthPlace?.city,
						helperText: !!errors?.customer?.birthPlace?.city
							? getError(errors.customer.birthPlace.city, language)
							: null,
						label: lang.B_CITY[language],
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			{includeCountry && (
				<Grid item xs={12} md={4}>
					<VirtualizedSelect
						freeSolo={true}
						language={language}
						options={[defaultCountry]}
						onChange={handleCountrySelect}
						value={country ? country : ""}
						textFieldProps={{
							name: "company.residence.country",
							margin: edit ? "dense" : "none",
							error: !!errors?.company?.residence?.country,
							helperText: !!errors?.company?.residence?.country
								? getError(errors.company.residence.country, language)
								: null,
							label: lang.COUNTRY[language],
						}}
					/>
				</Grid>
			)}
			<Grid item xs={12} md={includeCountry ? 4 : 6}>
				<VirtualizedSelect
					freeSolo={includeCountry && country !== defaultCountry}
					language={language}
					options={
						(regions && !includeCountry) || (regions && includeCountry && country === defaultCountry)
							? regions.regions?.map((item) => item.region)
							: []
					}
					onChange={handleRegionSelect}
					value={region ? region : ""}
					textFieldProps={{
						name: "customer.residence.region",
						margin: edit ? "dense" : "none",
						error: !!errors?.customer?.residence?.region,
						helperText: !!errors?.customer?.residence?.region
							? getError(errors.customer.residence.region, language)
							: null,
						label: lang.REGION[language],
					}}
				/>
			</Grid>
			<Grid item xs={12} md={includeCountry ? 4 : 6}>
				<VirtualizedSelect
					freeSolo={includeCountry && country !== defaultCountry}
					disabled={(!region && !includeCountry) || (!region && includeCountry && country === defaultCountry)}
					language={language}
					options={
						(cities && !includeCountry) || (cities && includeCountry && country === defaultCountry)
							? cities.cities?.map((item) => item.city)
							: []
					}
					onChange={handleCitySelect}
					value={city ? city : ""}
					textFieldProps={{
						name: "customer.residence.city",
						margin: edit ? "dense" : "none",
						error: !!errors?.customer?.residence?.city,
						helperText: !!errors?.customer?.residence?.city ? getError(errors.customer.residence.city, language) : null,
						label: lang.CITY[language],
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.STREET[language]}
					name="customer.residence.street"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.STREET_NO[language]}
					name="customer.residence.streetNo"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.BUILDING[language]}
					name="customer.residence.building"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.ENTRANCE[language]}
					name="customer.residence.entrance"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={3}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.FLOOR[language]}
					name="customer.residence.floor"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={6} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.APARTMENT[language]}
					name="customer.residence.apartment"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					fullWidth
					margin={edit ? "dense" : "none"}
					label={lang.ZIPCODE[language]}
					name="customer.residence.zip"
					inputRef={register}
					variant="outlined"
				/>
			</Grid>
			{/* </Grid> */}
		</React.Fragment>
	);
};

export { CustomerAddressForm as default };
