import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	Avatar,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	TablePagination,
	LinearProgress,
	Divider,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";

import getInitials from "../../../../utils/getInitials";
import { UsersMenu } from "..";

const useStyles = makeStyles((theme) => ({
	root: {
		borderColor: theme.palette.divider,
		border: "1px solid",
	},
	content: {
		padding: 0,
	},
	inner: {
		minWidth: 1050,
	},
	nameContainer: {
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	actions: {
		justifyContent: "flex-end",
	},
	tableExtraHeader: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	tableButtons: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
	},
	leftButton: {
		marginRight: theme.spacing(1),
	},
	tableRow: {
		cursor: "pointer",
	},
	blocked: {
		color: theme.palette.error.main,
	},
}));

function getInitialConfig() {
	const rowsPerPage = localStorage.getItem("rowsPerPage");
	return rowsPerPage ? rowsPerPage : 5;
}

const UsersTable = (props) => {
	const {
		className,
		users,
		loading,
		fetchData,
		count,
		called,
		language,
		onOpen,
		onRowClick,
		...rest
	} = props;

	const classes = useStyles();

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [page, setPage] = useState(0);

	const [rowsPerPage, setRowsPerPage] = useState(getInitialConfig);

	useEffect(() => {
		localStorage.setItem("rowsPerPage", rowsPerPage);
	}, [rowsPerPage]);

	const handlePageChange = (event, page) => {
		fetchData(rowsPerPage, page);
		setPage(page);
	};
	const handleRowsPerPageChange = (event) => {
		const { value } = event.target;
		fetchData(value, 0);
		setRowsPerPage(value);
	};

	useEffect(() => {
		fetchData(rowsPerPage, 0);
	}, [called]);

	return (
		<Card {...rest} className={clsx(classes.root, className)}>
			<UsersMenu language={language} classes={classes} onOpen={onOpen} />
			<Divider />
			<CardContent className={classes.content}>
				<PerfectScrollbar>
					<div className={classes.inner}>
						<Table>
							{loading ? (
								<TableHead>
									<TableRow>
										<TableCell> </TableCell>
									</TableRow>
								</TableHead>
							) : (
								<TableHead>
									<TableRow>
										<TableCell>
											{lang.FULL_NAME[language]}
										</TableCell>
										<TableCell>
											{lang.USERNAME[language]}
										</TableCell>
										<TableCell>
											{lang.EMAIL[language]}
										</TableCell>
										<TableCell>
											{lang.ROLE[language]}
										</TableCell>
										<TableCell>
											{lang.CREATE_DATE[language]}
										</TableCell>
									</TableRow>
								</TableHead>
							)}
							{loading ? (
								<TableBody>
									<TableRow>
										<TableCell>
											<LinearProgress color="secondary" />
										</TableCell>
									</TableRow>
								</TableBody>
							) : (
								<TableBody>
									{users.map((user) => (
										<TableRow
											onClick={() => onRowClick(user)}
											className={classes.tableRow}
											hover
											key={user.id}
											selected={
												selectedUsers.indexOf(
													user.id
												) !== -1
											}
										>
											<TableCell>
												<div
													className={
														classes.nameContainer
													}
												>
													<Avatar
														className={
															classes.avatar
														}
														src={`/images/avatars/avatar-0${user.avatar}.svg`}
													>
														{getInitials(
															`${user.profile.firstName} ${user.profile.lastName}`
														)}
													</Avatar>
													<Typography variant="body1">
														{`${user.profile.firstName} ${user.profile.lastName}`}
													</Typography>
												</div>
											</TableCell>
											<TableCell>
												{user.active ? (
													user.username
												) : (
													<Typography
														variant="body1"
														className={
															classes.blocked
														}
													>
														{user.username}
													</Typography>
												)}
											</TableCell>
											<TableCell>
												{user.profile.email}
											</TableCell>
											<TableCell>{user.role}</TableCell>
											<TableCell>
												{moment(user.createdAt).format(
													"DD/MM/YYYY"
												)}
											</TableCell>
										</TableRow>
									))}

									{rowsPerPage - users.length > 0 && (
										<TableRow
											style={{
												height:
													73 *
													(rowsPerPage -
														users.length),
											}}
										>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							)}
						</Table>
					</div>
				</PerfectScrollbar>
			</CardContent>
			<CardActions className={classes.actions}>
				<Pagination
					count={count}
					onChangePage={handlePageChange}
					page={page}
					rowsPerPage={parseInt(rowsPerPage, 10)}
					onChangeRowsPerPage={handleRowsPerPageChange}
				/>
			</CardActions>
		</Card>
	);
};

function Pagination(props) {
	return (
		<TablePagination
			{...props}
			component="div"
			rowsPerPageOptions={[5, 10, 25]}
		/>
	);
}

UsersTable.propTypes = {
	className: PropTypes.string,
	users: PropTypes.array.isRequired,
};

export default UsersTable;
