import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Button,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import * as lang from "../../../../utils/strings";
import { useForm } from "react-hook-form";
import getError from "../../../../utils/getError";
import { useMutation } from "@apollo/react-hooks";
import { red, green } from "@material-ui/core/colors";
import { Done, Save, Error } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { USER_PASSWORD_UPDATE_QUERY } from "../../../../requests";

const useStyles = makeStyles((theme) => ({
	root: {},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative",
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonError: {
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[700],
		},
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}));

const UserPassword = (props) => {
	const { language, className, ...rest } = props;
	const classes = useStyles();

	const [buttonStatus, setButtonStatus] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		errors,
		setValue,
		getValues,
		reset,
		setError,
	} = useForm();

	const [updatePassword, { called, loading, error }] = useMutation(
		USER_PASSWORD_UPDATE_QUERY
	);

	const onSubmit = (data, event) => {
		// event.preventDefault();
		updatePassword({
			variables: { data },
		})
			.then((response) => {
				reset({ oldPassword: "" });
				reset({ password: "" });
				reset({ confirm: "" });
				enqueueSnackbar(lang.USER_UPDATE_SUCCSESS[language], {
					variant: "success",
				});
				setButtonStatus(true);
				setTimeout(() => {
					setButtonStatus(false);
				}, 2000);
			})
			.catch((error) => {
				let _error = error.message.split(":")[1].trim();
				if (_error === "WRONG_PASSWORD")
					setError(
						"oldPassword",
						"wrongPassword",
						getError("wrongPassword", language)
					);
				if (_error === "PASSWORD_CONFIRM_ERROR") {
					console.log("LOL");
					setError(
						"password",
						"confirmError",
						getError("confirmError", language)
					);
					setError(
						"confirm",
						"confirmError",
						getError("confirmError", language)
					);
				}
				enqueueSnackbar(
					lang[_error][language],
					// error.message,
					{
						variant: "error",
					}
				);
				setButtonStatus("error");
			});
	};

	const buttonClassname = clsx({
		[classes.buttonStd]: true,
		[classes.buttonSuccess]: buttonStatus === true,
		[classes.buttonError]: buttonStatus === "error",
	});

	return (
		<Card className={clsx(classes.root, className)}>
			<form>
				<CardHeader subheader="Update password" title="Password" />
				<Divider />
				<CardContent>
					<TextField
						name="username"
						autoComplete="username"
						style={{ display: "none" }}
					/>
					<TextField
						fullWidth
						required
						label={lang.CURRENT_PASSWORD[language]}
						name="oldPassword"
						margin="dense"
						type="password"
						variant="outlined"
						autoComplete="old-password"
						inputRef={register({ required: true })}
						error={!!errors?.oldPassword}
						helperText={
							!!errors?.oldPassword
								? getError(errors.oldPassword, language)
								: null
						}
					/>
					<TextField
						fullWidth
						required
						label={lang.NEW_PASSWORD[language]}
						name="password"
						margin="dense"
						type="password"
						variant="outlined"
						autoComplete="new-password"
						style={{ marginTop: "1rem" }}
						inputRef={register({ required: true, minLength: 10 })}
						error={!!errors?.password}
						helperText={
							!!errors?.password
								? getError(errors.password, language, 10)
								: null
						}
					/>
					<TextField
						fullWidth
						required
						label={lang.CONFIRM_PASSWORD[language]}
						name="confirm"
						margin="dense"
						type="password"
						variant="outlined"
						autoComplete="new-password"
						style={{ marginTop: "1rem" }}
						inputRef={register({ required: true, minLength: 10 })}
						error={!!errors?.confirm}
						helperText={
							!!errors?.confirm
								? getError(errors.confirm, language, 10)
								: null
						}
					/>
				</CardContent>
				<Divider />
				<CardActions>
					{/* <Button
						color="primary"
						variant="outlined"
						onClick={handleSubmit(onSubmit)}
					>
						Update
					</Button> */}
					<div className={classes.wrapper}>
						<Button
							color="primary"
							variant={
								buttonStatus === true ? "contained" : "outlined"
							}
							disabled={buttonStatus === "loading"}
							className={buttonClassname}
							onClick={handleSubmit(onSubmit)}
						>
							{buttonStatus === false && (
								<Save
									fontSize="small"
									className={classes.leftIcon}
								/>
							)}
							{buttonStatus === "error" && (
								<Error
									fontSize="small"
									className={classes.leftIcon}
								/>
							)}
							{buttonStatus === true && (
								<Done
									fontSize="small"
									className={classes.leftIcon}
								/>
							)}
							Update
						</Button>
						{buttonStatus === "loading" && (
							<CircularProgress
								size={24}
								className={classes.buttonProgress}
							/>
						)}
					</div>
				</CardActions>
			</form>
		</Card>
	);
};

UserPassword.propTypes = {
	className: PropTypes.string,
};

export default UserPassword;
