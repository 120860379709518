import React from "react";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import * as lang from "../../utils/strings";
import getError from "../../utils/getError";

const UserForm = (props) => {
	const { language, register, handleRoleChange, userRole, errors } = props;
	return (
		<React.Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField
						fullWidth
						required
						label={lang.LAST_NAME[language]}
						name="profile.lastName"
						inputRef={register({ required: true })}
						variant="outlined"
						error={!!errors.profile?.lastName}
						helperText={
							!!errors.profile?.lastName
								? getError(errors.profile.lastName, language)
								: null
						}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						inputRef
						fullWidth
						required
						label={lang.FIRST_NAME[language]}
						name="profile.firstName"
						inputRef={register({ required: true })}
						variant="outlined"
						error={!!errors.profile?.firstName}
						helperText={
							!!errors.profile?.firstName
								? getError(errors.profile.firstName, language)
								: null
						}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						label={lang.EMAIL[language]}
						name="profile.email"
						inputRef={register}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						fullWidth
						required
						label={lang.USERNAME[language]}
						name="username"
						inputRef={register({ required: true, minLength: 3 })}
						variant="outlined"
						error={!!errors.username}
						helperText={
							!!errors.username
								? getError(errors.username, language, 3)
								: null
						}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						fullWidth
						select
						label={lang.ROLE[language]}
						name="role"
						onChange={handleRoleChange}
						value={userRole}
						variant="outlined"
						helperText={
							userRole === "ADMIN"
								? lang.ADMIN_WARNING[language]
								: null
						}
					>
						<MenuItem value="ADMIN">ADMIN</MenuItem>
						<MenuItem value="USER">USER</MenuItem>
					</TextField>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export { UserForm as default };
