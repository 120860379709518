import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
	Grid,
	Button,
	CircularProgress,
	ButtonGroup,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Done, Save, Error, MoreHoriz } from "@material-ui/icons";
import { CustomerForm } from "../../../../Forms";
import * as lang from "../../../../utils/strings";
import { CustomModal } from "../../../../components";

const useStyles = makeStyles((theme) => ({
	root: {},
	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: "relative",
	},
	buttonStd: {
		margin: theme.spacing(1),
	},
	buttonSuccess: {
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[700],
		},
	},
	buttonError: {
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[700],
		},
	},
	leftIcon: {
		marginRight: theme.spacing(1),
	},
}));

const CustomerDetails = (props) => {
	const {
		className,
		onExecute,
		handleSubmit,
		register,
		buttonStatus,
		language,
		viewMore,
		setViewMore,
		getValues,
		setValue,
		data,
	} = props;

	const classes = useStyles();

	const onSubmit = (data, e) => {
		onExecute(data);
	};

	const buttonClassname = clsx({
		[classes.buttonStd]: true,
		[classes.buttonSuccess]: buttonStatus === true,
		[classes.buttonError]: buttonStatus === "error",
	});


	return (
		<Card className={className}>
			<CustomModal
				language={language}
				title={lang.EDIT_CUSTOMER[language]}
				open={viewMore}
				// onClose={() => console.log(getValues())}
				onClose={() => setViewMore(false)}
				onExecute={onExecute}
				content={
					viewMore && (
						<Grid container spacing={2}>
							<CustomerForm {...props} custOnly existing/>
						</Grid>
					)
				}
				handleSubmit={handleSubmit}
				fullWidth
				form
			/>
			<CardHeader subheader={lang.DATA_EDIT[language]} title={lang.PATIENT[language]} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<CustomerForm {...props} edit />
					<Grid item xs={12}>
						<ButtonGroup size="small" variant="text" fullWidth>
							<Button color="primary" fullWidth onClick={() => setViewMore(true)}>
								<MoreHoriz fontSize="small" className={classes.leftIcon} /> {lang.VIEW_MORE[language]}
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</CardContent>
			<Divider />
			<CardActions>
				<div className={classes.wrapper}>
					<Button
						color="primary"
						variant={buttonStatus === true ? "contained" : "outlined"}
						disabled={buttonStatus === "loading"}
						className={buttonClassname}
						onClick={handleSubmit(onSubmit)}
					>
						{buttonStatus === false && <Save fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === "error" && <Error fontSize="small" className={classes.leftIcon} />}
						{buttonStatus === true && <Done fontSize="small" className={classes.leftIcon} />}
						{lang.SAVE[language]}
					</Button>
					{buttonStatus === "loading" && <CircularProgress size={24} className={classes.buttonProgress} />}
				</div>
			</CardActions>
		</Card>
	);
};

CustomerDetails.propTypes = {
	className: PropTypes.string,
	language: PropTypes.string.isRequired,
};

export { CustomerDetails as default };
